<template>
	<div id="Statistics">
		<el-card>
			<div class="demo-input-suffix header">
				<el-breadcrumb separator="/">
					<el-breadcrumb-item><i class="el-icon-date" style="margin-right: 5px;"></i>统计报表</el-breadcrumb-item>
					<el-breadcrumb-item>部门数据统计</el-breadcrumb-item>
				</el-breadcrumb>
				<div class="demo-input-suffix">
					<el-cascader :options="jobDeptoptions" :props="jobDeptprops" v-model='Dept' :show-all-levels="false"
						@change="jobDepthandleChange" ref="cascader2" placeholder="请选择部门"
						style="margin-right: 10px;width: 100px;">
					</el-cascader>
					<div style="display: flex;align-items: center;margin-right: 10px;">
						<el-date-picker style="width:190px;" v-model="StartTime" type="date" placeholder="选择开始日期"
							format="yyyy 年 MM 月 dd 日" value-format="yyyy-MM-dd 00:00:00">
						</el-date-picker>
						<span style="display: inline-block;margin: 0 3px;">-</span>
						<el-date-picker style="width:190px;" v-model="EndTime" type="date" placeholder="选择结束日期"
							format="yyyy 年 MM 月 dd 日" value-format="yyyy-MM-dd 23:59:59">
						</el-date-picker>
					</div>
					<el-button size="mini" type="primary" @click='searchange'>
						<i class="el-icon-search"></i>
						搜索
					</el-button>
					<!-- <el-button type="primary" icon="el-icon-printer" @click="handlePrint">打印</el-button> -->
					<el-button type="primary" icon="el-icon-download" @click="downloadExcel">统计表导出</el-button>
				</div>
			</div>
			<div id="printContent" class="print-container">
				<h1 style="text-align: center;margin: 30px 0;">部门数据统计表</h1>
				<el-table id="exporttable" :data="AttendanceList" border show-summary :summary-method="getSummaries"
					style="width: 100%">
					<el-table-column type="index" width="180">
					</el-table-column>
					<el-table-column prop="DeptName" label="部门名称">
					</el-table-column>
					<!-- <el-table-column prop="Salary" label="薪资">
          </el-table-column> -->
					<el-table-column prop="UserCount" label="考勤人数">
					</el-table-column>
					<el-table-column prop="RenCi" label="考勤人次">
					</el-table-column>
					<el-table-column prop="TotalSalary" label="总金额">
					</el-table-column>
					<el-table-column prop="TotalWorkHour" label="总工时">
					</el-table-column>
				</el-table>
				<!-- 图表容器height: 376px; -->
				<div style="display: flex;justify-content: space-between;">
					<div style="width: 60%;height: 300px;margin-top: 40px;" ref="chart">
					</div>
					<div style="width: 50%;height: 300px;margin-top: 40px;" ref="Rencichart">
					</div>
				</div>
				<div style="display: flex;justify-content: space-between;margin-top: 40px;">
					<div style="width: 33.33%;height: 300px;margin-top: 40px;" ref="UserContchart">
					</div>
					<div style="width: 33.33%;height: 300px;margin-top: 40px;" ref="Salarychart">
					</div>
					<div style="width: 33.33%;height: 300px;margin-top: 40px;" ref="WorkHourchart">
					</div>
				</div>
			</div>
		</el-card>
		<!-- <div class="loading" v-if="isLoading"></div> -->
	</div>
</template>
<script>
	//打印
	import print from 'print-js'
	//导出
	import FileSaver from 'file-saver'
	import XLSX from 'xlsx'
	//统计图
	let Echars = require('echarts/lib/echarts')
	require('echarts/lib/component/grid');
	require('echarts/lib/component/dataZoom');
	require('echarts/lib/chart/line');
	require('echarts/lib/chart/bar'); //所需图表（柱状图）
	require('echarts/lib/component/title');
	require('echarts/lib/component/toolbox');
	require('echarts/lib/component/tooltip');
	require('echarts/lib/component/legend');
	require('echarts/lib/chart/pie'); //饼图
	export default {
		name: 'Statistics',
		//组件创建
		created() {
			// // console.log('昨天：', this.getDay(-1, 7200000))
			// // console.log('今天：', this.getDay(0, 3600000))
			// var _date = new Date()
			// var Y = _date.getFullYear()
			// var m = _date.getMonth() + 1
			// m = m < 10 ? '0' + m : m;
			// this.StartTime = Y + '-' + m + '-01 00:00:00'
			// // this.StartTime = this.getDay(-1, 7200000) + ' 00:00:00'
			// this.EndTime = this.getDay(0, 3600000) + ' 23:59:59'
			// this.DeptReport()
			// this.DeptAll()
		},
		data() {
			return {
				AttendanceList: [], //列表
				jobDeptoptions: [], //所有部门
				jobDeptprops: { //树形结构的展示字段
					value: 'id',
					label: 'label',
					children: 'children',
				},
				Dept: '',
				DeptID: 0,
				StartTime: '',
				EndTime: '',
				//图表
				chart: null,
				Rencichart: null,
				Salarychart: null,
				WorkHourchart: null,
				UserContchart: null,

				DeptNum: [], //部门
				UserContNum: [], //用工人数
				UserContSum: 0, //用工总数
				RenCiNum: [], //用工次数
				RenCiSum: 0, //在职总数
				ToSalary: [], //薪资
				ToSalarySum: 0, //总薪资
				ToWorkHour: [], //工时
				ToWorkHourSum: 0, //总工时

				UserContList: [],
				RenCiList: [], //在职人图表数组
				SalaryList: [], //薪资图表数组
				WorkHourList: [], //工时图表数组

				loading: '',
				// isLoading: true
			}
		},
		mounted() {
			this.loading = this.$loading({
				lock: true,
				text: 'Loading',
				spinner: 'el-icon-loading',
				background: 'rgba(0, 0, 0, 0.7)'
			});
			var _date = new Date()
			var Y = _date.getFullYear()
			var m = _date.getMonth() + 1
			m = m < 10 ? '0' + m : m;
			this.StartTime = Y + '-' + m + '-01 00:00:00'
			// this.StartTime = this.getDay(-1, 7200000) + ' 00:00:00'
			this.EndTime = this.getDay(0, 3600000) + ' 23:59:59'
			this.DeptReport()
			this.DeptAll()
			window.addEventListener('resize', () => {
				this.chart.resize()
			})

		},
		methods: {
			//表格合计
			getSummaries(param) {
				const {
					columns,
					data
				} = param;
				const sums = [];
				columns.forEach((column, index) => {
					//合计
					//合计
					if (index === 0) {
						sums[index] = '总计';
						return;
					}
					//部门
					if (index === 1) {
						sums[index] = ' ';
						return;
					}
					// //薪资
					// if (index === 2) {
					//   sums[index] = ' ';
					//   return;
					// }
					// 在职人数
					if (index === 2) {
						const values = data.map(item => Number(item[column.property]));
						if (!values.every(value => isNaN(value))) {
							sums[index] = values.reduce((prev, curr) => {
								const value = Number(curr);
								if (!isNaN(value)) {
									return prev + curr;
								} else {
									return prev;
								}
							}, 0);
							sums[index] += ' 人';
						} else {
							sums[index] = 'N/A';
						}
					}
					//用工人次
					if (index === 3) {
						const values = data.map(item => Number(item[column.property]));
						if (!values.every(value => isNaN(value))) {
							sums[index] = values.reduce((prev, curr) => {
								const value = Number(curr);
								if (!isNaN(value)) {
									return prev + curr;
								} else {
									return prev;
								}
							}, 0);
							sums[index] += ' 次';
						} else {
							sums[index] = 'N/A';
						}
					}

					//总薪资
					if (index === 4) {
						const values = data.map(item => Number(item[column.property]));
						if (!values.every(value => isNaN(value))) {
							sums[index] = values.reduce((prev, curr) => {
								const value = Number(curr);
								if (!isNaN(value)) {
									return prev + curr;
								} else {
									return prev;
								}
							}, 0);
							sums[index] += ' 元';
						} else {
							sums[index] = 'N/A';
						}
					}
					//总工时
					if (index === 5) {
						const values = data.map(item => Number(item[column.property]));
						if (!values.every(value => isNaN(value))) {
							sums[index] = values.reduce((prev, curr) => {
								const value = Number(curr);
								if (!isNaN(value)) {
									return prev + curr;
								} else {
									return prev;
								}
							}, 0);
							sums[index] += ' 时';
						} else {
							sums[index] = 'N/A';
						}
					}
				});
				return sums;
			},
			//获取当前时间
			getDay(day, hours) {
				var today = new Date()
				var targetday = today.getTime() + 1000 * 60 * 60 * 24 * day + hours
				today.setTime(targetday)
				var tYear = today.getFullYear()
				var tMonth = today.getMonth()
				var tDate = today.getDate()
				tMonth = this.doHandleMonth(tMonth + 1)
				tDate = this.doHandleMonth(tDate)
				return tYear + '-' + tMonth + '-' + tDate
			},
			doHandleMonth(month) {
				var m = month
				if (month.toString().length === 1) {
					m = '0' + month
				}
				return m
			},
			//图表
			init() {
				this.chart = Echars.init(this.$refs.chart)
				const data = [this.DeptNum.length, this.RenCiSum, this.ToSalarySum, this.ToWorkHourSum]
				// console.log(data)
				// 指定图表的配置项和数据
				var option = {
					title: {
						text: '部门数据统计图',
						left: 'center'
					},
					tooltip: {},
					xAxis: {
						data: ['部门数', '考勤人数/人', '总薪资/元', '总工时/时']
					},
					yAxis: {},
					series: [{
						type: 'bar',
						data: data,
						label: {
							show: true,
							position: 'top'
						}
					}]
				};

				// 使用刚指定的配置项和数据显示图表。
				this.chart.setOption(option);
			},
			//考勤人数图表
			Renci() {
				this.Rencichart = Echars.init(this.$refs.Rencichart)
				const data = this.UserContList
				// 指定图表的配置项和数据
				var option = {
					title: {
						text: '部门考勤人数分布图',
						left: 'center'
					},
					tooltip: {
						trigger: 'item'
					},
					// color:colors,
					legend: {
						top: 'bottom'
					},
					toolbox: {
						show: true,
						feature: {
							mark: {
								show: true
							},
							dataView: {
								show: true,
								readOnly: false
							},
							restore: {
								show: true
							},
							saveAsImage: {
								show: true
							}
						}
					},
					visualMap: {
						show: false,
						min: 80,
						max: 600,
						inRange: {
							colorLightness: [0, 1]
						}
					},
					label: {
						alignTo: 'edge',
						formatter: '{name|{b}}\n{time|{c}人}',
						minMargin: 5,
						edgeDistance: 10,
						lineHeight: 15,
						rich: {
							time: {
								fontSize: 10,
								color: '#666'
							}
						}
					},
					labelLine: {
						length: 15,
						length2: 0,
						maxSurfaceAngle: 80
					},
					labelLayout: function(params) {
						const isLeft = params.labelRect.x < myChart.getWidth() / 2;
						const points = params.labelLinePoints;
						// Update the end point.
						points[2][0] = isLeft ?
							params.labelRect.x :
							params.labelRect.x + params.labelRect.width;
						return {
							labelLinePoints: points
						};
					},
					series: [{
						name: '分布',
						type: 'pie',
						radius: '55%',
						emphasis: {
							itemStyle: {
								shadowBlur: 10,
								shadowOffsetX: 0,
								shadowColor: 'rgba(0, 0, 0, 0.5)'
							}
						},
						// center: ['50%', '50%'],
						// roseType: 'area',
						// itemStyle: {
						//   borderRadius: 8
						// },
						data: data.sort(function(a, b) {
							return a.value - b.value;
						}),
						// roseType: 'radius',
						// animationType: 'scale',
						// animationEasing: 'elasticOut',
						// animationDelay: function(idx) {
						//   return Math.random() * 200;
						// }
					}]
				};
				// 使用刚指定的配置项和数据显示图表。
				this.Rencichart.setOption(option);
			},
			user() {
				this.UserContchart = Echars.init(this.$refs.UserContchart)
				const data = this.RenCiList
				// 指定图表的配置项和数据
				var option = {
					title: {
						text: '部门考勤次数分布图',
						left: 'center'
					},
					tooltip: {
						trigger: 'item'
					},
					legend: {
						top: 'bottom'
					},
					toolbox: {
						show: true,
						feature: {
							mark: {
								show: true
							},
							dataView: {
								show: true,
								readOnly: false
							},
							restore: {
								show: true
							},
							saveAsImage: {
								show: true
							}
						}
					},
					// visualMap: {
					//   show: false,
					//   min: 80,
					//   max: 600,
					//   inRange: {
					//     colorLightness: [0, 1]
					//   }
					// },
					label: {
						alignTo: 'edge',
						formatter: '{name|{b}}\n{time|{c}次}',
						minMargin: 5,
						edgeDistance: 10,
						lineHeight: 15,
						rich: {
							time: {
								fontSize: 10,
								color: '#666'
							}
						}
					},
					labelLine: {
						length: 15,
						length2: 0,
						maxSurfaceAngle: 80
					},
					labelLayout: function(params) {
						const isLeft = params.labelRect.x < myChart.getWidth() / 2;
						const points = params.labelLinePoints;
						// Update the end point.
						points[2][0] = isLeft ?
							params.labelRect.x :
							params.labelRect.x + params.labelRect.width;
						return {
							labelLinePoints: points
						};
					},
					series: [{
						name: '分布',
						type: 'pie',
						radius: '55%',
						// center: ['50%', '50%'],
						// roseType: 'area',
						// itemStyle: {
						//   borderRadius: 8
						// },
						data: data.sort(function(a, b) {
							return a.value - b.value;
						}),
						emphasis: {
							itemStyle: {
								shadowBlur: 10,
								shadowOffsetX: 0,
								shadowColor: 'rgba(0, 0, 0, 0.5)'
							}
						}
						// roseType: 'radius',
						// animationType: 'scale',
						// animationEasing: 'elasticOut',
						// animationDelay: function(idx) {
						//   return Math.random() * 200;
						// }
					}]
				};
				// 使用刚指定的配置项和数据显示图表。
				this.UserContchart.setOption(option);
			},
			//考勤人数薪资图表
			Salary() {
				this.Salarychart = Echars.init(this.$refs.Salarychart)
				const data = this.SalaryList
				// 指定图表的配置项和数据
				var option = {
					title: {
						text: '部门总工资分布图',
						left: 'center'
					},
					tooltip: {
						trigger: 'item'
					},
					legend: {
						top: 'bottom'
					},
					toolbox: {
						show: true,
						feature: {
							mark: {
								show: true
							},
							dataView: {
								show: true,
								readOnly: false
							},
							restore: {
								show: true
							},
							saveAsImage: {
								show: true
							}
						}
					},
					visualMap: {
						show: false,
						min: 80,
						max: 600,
						inRange: {
							colorLightness: [0, 1]
						}
					},
					label: {
						alignTo: 'edge',
						formatter: '{name|{b}}\n{time|{c}元}',
						minMargin: 5,
						edgeDistance: 10,
						lineHeight: 15,
						rich: {
							time: {
								fontSize: 10,
								color: '#666'
							}
						}
					},
					labelLine: {
						length: 15,
						length2: 0,
						maxSurfaceAngle: 80
					},
					labelLayout: function(params) {
						const isLeft = params.labelRect.x < myChart.getWidth() / 2;
						const points = params.labelLinePoints;
						// Update the end point.
						points[2][0] = isLeft ?
							params.labelRect.x :
							params.labelRect.x + params.labelRect.width;
						return {
							labelLinePoints: points
						};
					},
					series: [{
						name: '分布',
						type: 'pie',
						radius: '55%',
						// center: ['50%', '50%'],
						// roseType: 'area',
						// itemStyle: {
						//   borderRadius: 8
						// },
						data: data.sort(function(a, b) {
							return a.value - b.value;
						}),
						emphasis: {
							itemStyle: {
								shadowBlur: 10,
								shadowOffsetX: 0,
								shadowColor: 'rgba(0, 0, 0, 0.5)'
							}
						}
						// roseType: 'radius',
						// animationType: 'scale',
						// animationEasing: 'elasticOut',
						// animationDelay: function(idx) {
						//   return Math.random() * 200;
						// }
					}]
				};

				// 使用刚指定的配置项和数据显示图表。
				this.Salarychart.setOption(option);
			},
			//考勤人数工时图表
			WorkHour() {
				this.WorkHourchart = Echars.init(this.$refs.WorkHourchart)
				const data = this.WorkHourList
				// const data1 = this.DeptNum
				// 指定图表的配置项和数据
				var option = {
					title: {
						text: '部门总工时分布图',
						left: 'center'
					},
					tooltip: {
						trigger: 'item'
					},
					legend: {
						top: 'bottom'
					},
					toolbox: {
						show: true,
						feature: {
							mark: {
								show: true
							},
							dataView: {
								show: true,
								readOnly: false
							},
							restore: {
								show: true
							},
							saveAsImage: {
								show: true
							}
						}
					},
					visualMap: {
						show: false,
						min: 80,
						max: 600,
						inRange: {
							colorLightness: [0, 1]
						}
					},
					label: {
						alignTo: 'edge',
						formatter: '{name|{b}}\n{time|{c}工时}',
						minMargin: 5,
						edgeDistance: 10,
						lineHeight: 15,
						rich: {
							time: {
								fontSize: 10,
								color: '#666'
							}
						}
					},
					labelLine: {
						length: 15,
						length2: 0,
						maxSurfaceAngle: 80
					},
					labelLayout: function(params) {
						const isLeft = params.labelRect.x < myChart.getWidth() / 2;
						const points = params.labelLinePoints;
						// Update the end point.
						points[2][0] = isLeft ?
							params.labelRect.x :
							params.labelRect.x + params.labelRect.width;
						return {
							labelLinePoints: points
						};
					},
					series: [{
						name: '分布',
						type: 'pie',
						radius: '55%',
						// center: ['50%', '50%'],
						// roseType: 'area',
						// itemStyle: {
						//   borderRadius: 8
						// },
						data: data.sort(function(a, b) {
							return a.value - b.value;
						}),
						emphasis: {
							itemStyle: {
								shadowBlur: 10,
								shadowOffsetX: 0,
								shadowColor: 'rgba(0, 0, 0, 0.5)'
							}
						}
						// roseType: 'radius',
						// animationType: 'scale',
						// animationEasing: 'elasticOut',
						// animationDelay: function(idx) {
						//   return Math.random() * 200;
						// }
					}]
				};

				// 使用刚指定的配置项和数据显示图表。
				this.WorkHourchart.setOption(option);
			},
			// 所有部门
			DeptAll() {
				let that = this
				let Token = localStorage.getItem('Token')
				this.$axios.defaults.headers.common["x-Token"] = Token
				this.$axios.post('/Dept/GetTree', {
					"Data": {
						"Delay": true, //是否延迟加载
						"ParentId": 0, //父级ID
						"IsIncludeSelf": true, //包含自身
						"OnlyLoadPrivilegeOrg": false //是否开启权限过滤
					}
				}).then((res) => {
					if (res.data.Code == 0) {
						console.log(res.data)
						that.jobDeptoptions = res.data.Data
					} else {
						that.$message({
							type: 'info',
							message: res.data.Message
						})
					}
				})
			},
			// 获取部门名
			jobDepthandleChange(value) {
				console.log(value)
				console.log(value[0])
				let nodesInfo = this.$refs['cascader2'].getCheckedNodes()
				console.log(nodesInfo)
				if (value[1] == undefined) {
					this.DeptID = value[0]
				} else {
					this.DeptID = value[1]
				}
			},
			//搜索
			searchange() {
				this.chart = null
				this.Rencichart = null
				this.Salarychart = null
				this.WorkHourchart = null
				this.UserContchart = null
				this.DeptNum = []
				this.RenCiNum = []
				this.RenCiSum = 0
				this.ToSalary = []
				this.ToSalarySum = 0
				this.ToWorkHour = []
				this.ToWorkHourSum = 0
				this.UserContNum = []
				this.UserContSum = 0
				this.UserContList = []
				this.RenCiList = []
				this.SalaryList = []
				this.WorkHourList = []
				this.DeptReport()
			},
			//获取统计数据
			DeptReport() {
				let Token = localStorage.getItem('Token')
				let that = this
				this.$axios.defaults.headers.common["x-Token"] = Token
				this.$axios.post('/Statics/DeptReport', {
					"Data": {
						"DeptID": this.DeptID,
						"StartDT": that.StartTime,
						"EndDT": that.EndTime
					}
				}).then(function(res) {
					console.log(res.data)
					if (res.data.Code == 0) {

						that.AttendanceList = res.data.Data
						console.log(that.AttendanceList.length)
						for (let item of that.AttendanceList) {
							that.DeptNum.push(item.DeptName) //单部门数组
							that.UserContNum.push(item.UserCount) //单用工人数数组
							that.RenCiNum.push(item.RenCi) //单用工次数数组
							that.ToSalary.push(item.TotalSalary) //单总薪资数组
							that.ToWorkHour.push(item.TotalWorkHour) //单总工时数组
						}

						//用工总数
						//创建用工次数图表数组
						let pick4 = []
						for (let item of that.AttendanceList) {
							var ii = (({
								DeptName,
								UserCount
							}) => ({
								DeptName,
								UserCount
							}))(item)
							pick4.push(ii)
						}
						pick4.forEach(item => {
							that.UserContList.push({
								value: item.UserCount,
								name: item.DeptName
							})
						})
						that.UserContNum.forEach((vm) => {
							that.UserContSum += vm
						})

						//创建考勤人数图表数组
						let pick = []
						for (let item of that.AttendanceList) {
							var ii = (({
								DeptName,
								RenCi
							}) => ({
								DeptName,
								RenCi
							}))(item)
							pick.push(ii)
						}
						pick.forEach(item => {
							that.RenCiList.push({
								value: item.RenCi,
								name: item.DeptName
							})
						})
						that.RenCiNum.forEach((vm) => {
							that.RenCiSum += vm
						})

						//创建在职总薪资图表数组
						let pick1 = []
						for (let item of that.AttendanceList) {
							var ii = (({
								DeptName,
								TotalSalary
							}) => ({
								DeptName,
								TotalSalary
							}))(item)
							pick1.push(ii)
						}
						pick1.forEach(item => {
							that.SalaryList.push({
								value: item.TotalSalary,
								name: item.DeptName
							})
						})
						that.ToSalary.forEach((vm) => {
							that.ToSalarySum += vm
						})


						//创建在职总工时图表数组
						let pick2 = []
						for (let item of that.AttendanceList) {
							var ii = (({
								DeptName,
								TotalWorkHour
							}) => ({
								DeptName,
								TotalWorkHour
							}))(item)
							pick2.push(ii)
						}
						pick2.forEach(item => {
							that.WorkHourList.push({
								value: item.TotalWorkHour,
								name: item.DeptName
							})
						})
						that.ToWorkHour.forEach((vm) => {
							that.ToWorkHourSum += vm
						})

						that.init()
						that.Renci()
						that.user()
						that.Salary()
						that.WorkHour()
						
						that.loading.close();
						// that.isLoading = false

					} else {
						that.$message({
							type: 'info',
							message: res.data.Message
						})
					}
				})
			},
			/**
			 * 调用浏览器的打印方法
			 */
			handlePrint() {
				setTimeout(function() {
					print({
						printable: 'printContent',
						type: 'html',
						targetStyles: ['*'],
						maxWidth: 1100
					})
				}, 500)
			},
			//导出Excel
			downloadExcel() {
				let xlsxParam = {
					raw: true
				}; //转换成excel时，使用原始的格式
				// / generate workbook object from table /;
				/* 从表生成工作簿对象 */
				let wb = XLSX.utils.table_to_book(
					document.querySelector("#exporttable"), //elementui 表格的id
					xlsxParam
				);

				/* 获取二进制字符串作为输出 */
				var wbout = XLSX.write(wb, {
					bookType: "xlsx",
					bookSST: true,
					type: "array"
				});
				try {
					FileSaver.saveAs(
						//Blob 对象表示一个不可变、原始数据的类文件对象。
						//Blob 表示的不一定是JavaScript原生格式的数据。
						//File 接口基于Blob，继承了 blob 的功能并将其扩展使其支持用户系统上的文件。
						//返回一个新创建的 Blob 对象，其内容由参数中给定的数组串联组成。
						new Blob([wbout], {
							type: "application/octet-stream"
						}),
						//设置导出文件名称
						"部门数据分析统计表.xlsx"
					);
				} catch (e) {
					if (typeof console !== "undefined") console.log(e, wbout);
				}
				return wbout;
			},
		}
	}
</script>
<style scoped>
	/* 头部标题 */
	.header {
		align-items: center;
		justify-content: space-between;
	}

	.demo-input-suffix {
		display: flex;
	}

	.el-breadcrumb__item {
		display: flex;
	}

	.el-input {
		width: 70%;
	}

	.el-col .el-input {
		width: 100%;
	}

	.line {
		text-align: center;
	}

	#printContent {
		width: 1000px;
		margin: 0 auto;
	}

	.print-button-container {
		display: flex;
		justify-content: flex-end;
		margin-top: 15px;
	}

	/* 字体调黑 */
	>>>.el-table {
		font-size: 16px;
		color: #000000;
	}

	.el-table--border {
		border-right: 1px solid;
		border-bottom: 1px solid;
	}

	>>>.el-table th {
		font-weight: normal;
		font-size: 18px;
	}

	>>>.el-table thead {
		color: #000000;
	}

	/*边框调黑*/
	>>>.el-table::before {
		background-color: #000000;
	}

	>>>.el-table--border::after {
		background-color: #000000;
	}

	>>>.el-table--border th,
	>>>.el-table--border td {
		border-color: #000000;
	}

	>>>.el-table--group,
	>>>.el-table--border {
		border-color: #000000;
	}

	>>>.el-table th.is-leaf,
	>>>.el-table td {
		border-bottom: 1px solid #000000;
	}

	/*减小单元格间距*/
	>>>.el-table th,
	>>>.el-table td {
		padding: 0 0;
		height: 50px;
	}

	/*显示底部边框 估计打印时伪元素把border覆盖了 height设为0隐藏伪元素*/
	>>>.el-table::before {
		height: 0;
	}

	/*显示右边框*/
	>>>.el-table--group::after,
	>>>.el-table--border::after {
		width: 0;
	}

	@media print {
		@page {
			/* 纵向打印 */
			/* size: portrait; */

			/* 横向打印 */
			size: landscape;

			/* 去掉页眉页脚*/
			margin-top: 0;
			margin-bottom: 0;
		}


	}
</style>
