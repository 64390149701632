<template>
  <div id="JobPostEdit">
    <el-card>
      <div class="demo-input-suffix header">
        <el-breadcrumb separator="/">
          <el-breadcrumb-item><i class="el-icon-date" style="margin-right: 5px;"></i>岗位管理</el-breadcrumb-item>
          <el-breadcrumb-item>修改岗位</el-breadcrumb-item>
        </el-breadcrumb>
        <div>
          <a href="javascript://" onclick="window.history.go(-1)" style="color: rgb(255, 88, 0);">[返回岗位列表]</a>
        </div>
      </div>
      <div class="container">
        <el-form label-position="right" label-width="110px" :model="Addjobform" ref="Addjobform"
          style="padding-bottom: 100px;">
          <el-form-item label="岗位名称">
            <el-input type="text" status-icon v-model="Addjobform.jobName" disabled>
            </el-input>
          </el-form-item>
          <div style="display: flex;align-items: center;">
            <el-form-item label="城市">
              <el-input type="text" status-icon v-model="Addjobform.CityTxt" disabled>
              </el-input>
            </el-form-item>
            <el-form-item label="区域">
            <el-input type="text" status-icon v-model="Addjobform.AreaTxt" disabled>
            </el-input>
            </el-form-item>
          </div>
          <div style="display: flex;align-items: center;">
            <el-form-item label="商圈" >
              <el-input style="width: 100%;" type="text" status-icon v-model="Addjobform.ShangQuanTxt" disabled>
              </el-input>
            </el-form-item>
            <el-form-item label="地铁">
            <el-input style="width: 100%;"  type="text" status-icon v-model="Addjobform.StationTxt" disabled>
            </el-input>
            </el-form-item>
          </div>
          <el-form-item label="工作详细地址">
            <el-input type="text" status-icon v-model="Addjobform.jobAddress" disabled>
            </el-input>
          </el-form-item>

          <div style="display: flex;align-items: center;">
            <el-form-item label="招聘人数" prop="jobNumber">
              <div class="number">
                <el-input type="number" status-icon v-model.number="Addjobform.jobNumber" placeholder="请填写招聘人数"
                  style="width: 40%;" autocomplete="off">
                </el-input>人(0表示若干人)
              </div>
            </el-form-item>
            <el-form-item label="元/小时">
              <el-input type="number" status-icon v-model.number="Addjobform.ContractSalary" placeholder="请填写金额"
                autocomplete="off" disabled>
              </el-input>元/小时
            </el-form-item>
          </div>
          <el-form-item label="工作时间" required>
            <el-col :span="9">
              <el-form-item>
                <el-input type="text" status-icon v-model="Addjobform.StartTime" disabled>
            </el-input>
              </el-form-item>
            </el-col>
            <el-col class="line" :span="2">-</el-col>
            <el-col :span="9">
              <el-form-item>
                <el-input type="text" status-icon v-model="Addjobform.EndTime" disabled>
                </el-input>
              </el-form-item>
            </el-col>
          </el-form-item>
          <div style="display: flex;align-items: center;">
            <el-form-item label="所属部门" prop="Dept" :rules="[
              { required: true, message: '请选择所属部门', trigger: 'change' }
            ]">
              <div class="block">
                <el-cascader :options="jobDeptoptions" :props="jobDeptprops" v-model='Addjobform.Dept'
                  :show-all-levels="false" @change="jobDepthandleChange" ref="cascader2" placeholder="请选择所属部门">
                </el-cascader>
              </div>
            </el-form-item>
            <el-form-item label="岗位类别" prop="JobTypeId" :rules="[
              { required: true, message: '请选择岗位类别', trigger: 'change' }
            ]">
              <el-cascader v-model="Addjobform.JobTypeId" :props="jobTypeprops" :options="jobTypeoptions"
                :show-all-levels="false" placeholder="请选择岗位类别" @change="jobTypeChange" ref="cascader3">
              </el-cascader>
            </el-form-item>
          </div>
          <div>
            <el-form-item style="margin-bottom: 22px;" label="性别要求" prop="sex" :rules="[
                { required: true, message: '请选择性别', trigger: 'change' }
              ]">
              <el-radio-group v-model="Addjobform.sex">
                <el-radio label="0">男</el-radio>
                <el-radio label="1">女</el-radio>
                <el-radio label="2">不限</el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item style="margin-bottom: 22px;" label="岗位福利" prop="JobWelfare" :rules="[
                { required: true, message: '请添加岗位福利', trigger: 'blur' }
              ]">
              <el-tag :key="tag" v-for="tag in Addjobform.JobWelfare" closable :disable-transitions="false"
                @close="handleClose(tag)">
                {{tag}}
              </el-tag>
              <el-input class="input-new-tag" v-if="inputVisible" v-model="inputValue" placeholder="最长四个字符"
                ref="saveTagInput" size="small" maxlength="4" @keyup.enter.native="handleInputConfirm"
                @blur="handleInputConfirm">
              </el-input>
              <el-button v-else class="button-new-tag" size="small" @click="showInput">+ 新建福利标签</el-button>
            </el-form-item>
          </div>
          <el-form-item label="岗位描述" prop="jobInfo" :rules="[
            { required: true, message: '请填写岗位描述', trigger: 'blur' }
          ]">
            <div class="editor">
              <quill-editor v-model="Addjobform.jobInfo" ref="myQuillEditor" :options="editorOption"
                @change="onEditorChange($event)" class="editor" style="width: 100%;height: 300px;"></quill-editor>
            </div>
          </el-form-item>
        </el-form>
        <div class="footer">
          <el-button @click="AddjobOut('Addjobform')">取 消</el-button>
          <el-button type="primary" @click="AddjobSubmit('Addjobform')">确 定
          </el-button>
        </div>
      </div>
    </el-card>
  </div>
</template>

<script>
  import {
    quillEditor
  } from "vue-quill-editor"; // 导入quillEditor组件
  import 'quill/dist/quill.js';
  import jobTypeDate from '../static/json/JobType.json';
  import address from '../static/json/address.json';
  // let userInfo = JSON.parse(localStorage.getItem('DateInfo'));
  // 工具栏配置
  const toolbarOptions = [
    ["bold", "italic", "underline", "strike"], // 加粗 斜体 下划线 删除线 -----['bold', 'italic', 'underline', 'strike']
    ["blockquote", "code-block"], // 引用  代码块-----['blockquote', 'code-block']
    [{
      header: 1
    }, {
      header: 2
    }], // 1、2 级标题-----[{ header: 1 }, { header: 2 }]
    [{
      list: "ordered"
    }, {
      list: "bullet"
    }], // 有序、无序列表-----[{ list: 'ordered' }, { list: 'bullet' }]
    [{
      script: "sub"
    }, {
      script: "super"
    }], // 上标/下标-----[{ script: 'sub' }, { script: 'super' }]
    [{
      indent: "-1"
    }, {
      indent: "+1"
    }], // 缩进-----[{ indent: '-1' }, { indent: '+1' }]
    [{
      direction: "rtl"
    }], // 文本方向-----[{'direction': 'rtl'}]
    [{
      size: ["small", false, "large", "huge"]
    }], // 字体大小-----[{ size: ['small', false, 'large', 'huge'] }]
    [{
      header: [1, 2, 3, 4, 5, 6, false]
    }], // 标题-----[{ header: [1, 2, 3, 4, 5, 6, false] }]
    [{
      color: []
    }, {
      background: []
    }], // 字体颜色、字体背景颜色-----[{ color: [] }, { background: [] }]
    [{
      font: []
    }], // 字体种类-----[{ font: [] }]
    [{
      align: []
    }], // 对齐方式-----[{ align: [] }]
    ["clean"], // 清除文本格式-----['clean']
    ["link"] // 链接、图片、视频-----['link', 'image', 'video']
  ];
  export default {
    name: 'JobPostEdit',
    //组件创建
    created() {
      // console.log(window.location.href.split('=')[1])
      this.JobId = window.location.href.split('=')[1]
      // console.log(this.JobId)
      //岗位信息
      this.jobInfo()
      //岗位类别
      this.get_jobType()
      //部门
      this.Dept()
    },
    activated() {
      this.jobInfo()
    },
    components: {
      quillEditor
    },
    data() {
      return {
        JobId: null,
        //岗位发布信息
        Addjobform: {
          jobName: '', //岗位名称
          jobNumber: '0', //招聘人数
          Dept: '',
          DeptID: '', //所属部门
          DeptName: '', //所属部门名
          JobTypeId: '', //岗位类别id
          JobTypeParentId: '', //岗位类别父级id
          JobTypeName: '', //岗位类别
          sex: "2", //性别
          jobInfo: '', //岗位描述   富文本编辑器默认内容
          JobWelfare: [], //岗位福利
          CityTxt: '', //工作城市
          AreaTxt: '', //工作区
          ShangQuanTxt: '', //商圈
          StationTxt: '',
          jobAddress:'',//地址
          Salary: '', //薪资
		  ContractSalary:'',//合同金额
          StartTime:'',//开始时间
          EndTime:'',//结束时间
        },
        jobDeptoptions: [], //所有部门
        jobDeptprops: { //树形结构的展示字段
          value: 'id',
          label: 'label',
          children: 'children',
        },
        jobTypeoptions: [], //岗位类别
        jobTypeprops: {
          value: 'Id',
          label: 'Name',
          children: 'JobList'
        },
        inputVisible: false,
        inputValue: '',
        uniqueId: "uniqueId",
        editorOption: {
          //  富文本编辑器配置
          modules: {
            toolbar: toolbarOptions
          },
          theme: "snow",
          placeholder: "请输入岗位描述（岗位职责）"
        }
      }
    },
    methods: {
      //岗位信息
      jobInfo() {
        var that = this
        let Token = localStorage.getItem('Token')
        this.$axios.defaults.headers.common["x-Token"] = Token
        this.$axios.post('/JobManage/Job/GetByID', {
          "Data": that.JobId
        }).then((res) => {
          console.log(res)
          var Data = res.data.Data
          that.Addjobform.jobName = Data.JobName //岗位名称
          that.Addjobform.jobNumber = Data.JobCount //招聘人数
          that.Addjobform.Dept = Data.DeptID
          that.Addjobform.DeptName = Data.DeptName //部门名称
          that.Addjobform.DeptID = Data.DeptID //部门id
          that.Addjobform.JobTypeId = Data.JobTypeId //岗位id
          that.Addjobform.JobTypeName = Data.JobTypeName //岗位名称
          that.Addjobform.JobTypeParentId = Data.JobTypeParentId //岗位父级id
          that.Addjobform.sex = String(Data.JobSexNeed) //性别
          that.Addjobform.jobInfo = Data.JobNeedDes
          that.Addjobform.JobWelfare = Data.JobWelfare
          //企业地址
          that.Addjobform.jobAddress = Data.Address
          //薪资
          that.Addjobform.Salary = Data.Salary
		  that.Addjobform.ContractSalary = Data.ContractSalary
          //企业城市
          that.Addjobform.CityTxt = Data.CityTxt
          //企业地区
          that.Addjobform.AreaTxt = Data.AreaTxt
          //企业商圈
          that.Addjobform.ShangQuanTxt = Data.ShangQuanTxt
          //企业地铁
          that.Addjobform.StationTxt = Data.StationTxt
          //开始时间
          that.Addjobform.StartTime = Data.StartTime
          //结束时间
          that.Addjobform.EndTime = Data.EndTime


        })
      },
      // 所有部门
      Dept() {
        let that = this
        let Token = localStorage.getItem('Token')
        this.$axios.defaults.headers.common["x-Token"] = Token
        this.$axios.post('/Dept/GetTree', {
          "Data": {
            "Delay": true, //是否延迟加载
            "ParentId": 0, //父级ID
            "IsIncludeSelf": true, //包含自身
            "OnlyLoadPrivilegeOrg": false //是否开启权限过滤
          }
        }).then((res) => {
          if (res.data.Code == 0) {
            console.log(res.data)
            this.jobDeptoptions = res.data.Data
          } else {
            that.$message({
              type: 'info',
              message: res.data.Message
            })
          }
        })
      },
      //岗位类别
      get_jobType() {
        var that = this
        let Token = localStorage.getItem('Token')
        this.$axios.defaults.headers.common["x-Token"] = Token
        this.$axios.post('https://systemapi.61msh.com/City/JobType', {
          "Data": {
            "Type": 1
          }
        }).then((res) => {
          console.log(res)
          this.jobTypeoptions = res.data.Data.JobTypeList
        })
      },
      // 获取部门名
      jobDepthandleChange(value) {
        console.log(value)
        console.log(value[0])
        // console.log(value[1])
        let nodesInfo = this.$refs['cascader2'].getCheckedNodes()
        console.log(nodesInfo)
        if (value[1] == undefined) {
          this.Addjobform.DeptID = value[0]
        } else {
          this.Addjobform.DeptID = value[1]
        }
        this.Addjobform.DeptName = nodesInfo[0].label
        console.log(this.Addjobform.DeptID, this.Addjobform.DeptName)
      },
      //岗位类别
      jobTypeChange(value) {
        console.log(value)
        let nodesInfo = this.$refs['cascader3'].getCheckedNodes()
        console.log(nodesInfo)
        if (value.length == 1) {
          this.Addjobform.JobTypeId = value[0] //岗位类别id
          // this.Addjobform.JobTypeParentId = null //岗位类别父级id
          this.Addjobform.JobTypeName = nodesInfo[0].label //岗位类别
        } else {
          this.Addjobform.JobTypeId = value[1] //岗位类别id
          this.Addjobform.JobTypeParentId = nodesInfo[0].pathNodes[0].level //岗位类别父级id
          this.Addjobform.JobTypeName = nodesInfo[0].label //岗位类别
        }
        console.log(this.Addjobform.JobTypeId, this.Addjobform.JobTypeParentId, this.Addjobform.JobTypeName)
      },
      //岗位福利
      //移除标签
      handleClose(tag) {
        this.Addjobform.JobWelfare.splice(this.Addjobform.JobWelfare.indexOf(tag), 1);
      },
      //添加标签input
      showInput() {
        this.inputVisible = true;
        this.$nextTick(_ => {
          this.$refs.saveTagInput.$refs.input.focus();
        });
      },
      // 添加标签  list
      handleInputConfirm() {
        let inputValue = this.inputValue;
        if (inputValue) {
          if (this.Addjobform.JobWelfare.length > 3) {
            this.$message({
              showClose: true,
              message: '标签最多四个哦',
              type: 'warning'
            })
          } else {
            this.Addjobform.JobWelfare.push(inputValue);
          }
        }
        this.inputVisible = false;
        this.inputValue = '';
      },

      //内容改变事件
      onEditorChange({
        quill,
        html,
        text
      }) {
        this.Addjobform.jobInfo = html
      },
      //取消发布
      AddjobOut(formName) {
        console.log(this.Addjobform)
        this.$refs[formName].resetFields();
        this.$router.push("/Jobposting");
      },
      //提交发布
      AddjobSubmit(formName) {
        console.log(this.Addjobform)
        this.$refs[formName].validate((valid) => {
          if (valid) {
            let that = this
            let Token = localStorage.getItem('Token')
            this.$axios.defaults.headers.common["x-Token"] = Token
            this.$axios.post('/JobManage/Job/Edit', {
              "Data": {
                "Id": this.JobId, //岗位id
                "JobCount": this.Addjobform.jobNumber, //招聘人数
                "DeptID": this.Addjobform.DeptID, //部门ID
                "DeptName": this.Addjobform.DeptName, //部门名称
                "JobTypeId": this.Addjobform.JobTypeId, //岗位类别ID
                "JobTypeParentId": this.Addjobform.JobTypeParentId, //岗位类别父节点ID
                "JobTypeName": this.Addjobform.JobTypeName, //岗位类别名称
                "JobSexNeed": this.Addjobform.sex, // 性别
                "JobNeedDes": this.Addjobform.jobInfo, //岗位描述
                "JobWelfare": this.Addjobform.JobWelfare, //福利
              }
            }).then((res) => {
              if (res.data.Code == 0) {
                console.log(res.data)
                that.$message({
                  type: 'success',
                  message: res.data.Message
                })
                that.$refs[formName].resetFields();
                //跳转到发布岗位页面
                that.$router.push("/Jobposting");
              } else {
                that.$message({
                  type: 'info',
                  message: res.data.Message
                })
              }
            })
          } else {
            // console.log('error submit!!');
            return false;
          }
        })
      }
    }
  }
</script>

<style lang="less" scoped>
  .el-cascader .el-input .el-input__inner:focus,
  .el-cascader .el-input.is-focus .el-input__inner {
    height: 33px; //这里高度根据需求自己设定
  }

  .el-cascader__tags {
    display: inline-flex;
    margin-right: 60px;
    flex-wrap: nowrap;
  }

  //福利标签
  .el-tag+.el-tag {
    margin-left: 10px;
  }

  .button-new-tag {
    margin-left: 10px;
    height: 32px;
    line-height: 30px;
    padding-top: 0;
    padding-bottom: 0;
  }

  .input-new-tag {
    width: 90px;
    margin-left: 10px;
    vertical-align: bottom;
  }

  /* 头部标题 */
  .header {
    align-items: center;
    justify-content: space-between;
  }

  .demo-input-suffix {
    display: flex;
  }

  .el-breadcrumb__item {
    display: flex;
  }

  .el-input {
    width: 70%;
  }

  .el-col .el-input {
    width: 100%;
  }

  .line {
    text-align: center;
  }

  // .number{
  //   display: flex;
  // }
  .container {
    width: 60%;
    height: auto;
    background-color: #fff;
    // position: absolute;
    // left: 0;
    // right: 0;
    // top: 0;
    // bottom: 0;
    margin: auto;
    padding: 30px;
    border-radius: 6px;
  }

  .footer {
    // margin-top: 100px;
    text-align: center;
    // display: flex;
    // justify-content: center;
    // align-content: center;
  }
</style>
