<template>
  <div id="Scheduling">
    <el-card>
      <div class="demo-input-suffix header">
        <el-breadcrumb separator="/">
          <el-breadcrumb-item><i class="el-icon-date"></i> 首页</el-breadcrumb-item>
          <el-breadcrumb-item>用工排班</el-breadcrumb-item>
        </el-breadcrumb>

        <div class="demo-input-suffix">
          <el-input class="seachInpt" placeholder="请输入搜索关键字" v-model="search" clearable @change='NameChange'>
          </el-input>
          <el-button size="mini" type="primary" @click='searchange'>
            <i class="el-icon-search"></i>
            搜索
          </el-button>
          <el-button size="mini" type="danger" @click="addScheduling()">
            <i class="el-icon-plus"></i>
            新增
          </el-button>
          <el-dialog title="新增班次" :visible.sync="dialogTableVisible" center :append-to-body='true' :lock-scroll="false"
            width="40%">
            <el-form label-position="right" label-width="80px" :model="form" status-icon ref="form">
              <el-form-item label="工作时间" required>
                <el-col :span="11">
                  <el-form-item prop="StartTime" :rules="[
                       { type: 'string', required: true, message: '请选择日期', trigger: 'change' }
                    ]">
                    <el-date-picker v-model="form.StartTime" value-format="yyyy-MM-dd HH:mm:00" type="datetime"
                      placeholder="选择开始日期时间">
                    </el-date-picker>
                  </el-form-item>
                </el-col>
                <el-col class="line" :span="2">-</el-col>
                <el-col :span="11">
                  <el-form-item prop="EndTime" :rules="[
                       { type: 'string', required: true, message: '请选择日期', trigger: 'change' }
                    ]">
                    <el-date-picker v-model="form.EndTime" value-format="yyyy-MM-dd HH:mm:00" type="datetime"
                      placeholder="选择结束日期时间">
                    </el-date-picker>
                  </el-form-item>
                </el-col>
              </el-form-item>
              <el-form-item label="部门" prop="DeptId" :rules="[
                {required: true, message: '请选择部门', trigger: 'change' }
              ]">
                <el-cascader :options="Deptoptions" :props="props" v-model='form.DeptId' :show-all-levels="false"
                  @change="handleChange" ref="cascader" placeholder="请选择部门">
                </el-cascader>
              </el-form-item>
              <el-form-item label="班次" prop="Name" :rules="[
                { required: true, message: '请输入班次名称', trigger: 'blur' }
              ]">
                <el-input v-model="form.Name"></el-input>
              </el-form-item>
              <el-form-item label="休息" prop="IsRest" :rules="[
                { required: true, message: '请选择是否有休息', trigger: 'change' }
              ]">
                <el-select v-model="form.IsRest" placeholder="请选择是否有休息" @change="IsRestChange">
                  <el-option label="是" value='true'></el-option>
                  <el-option label="否" value='false'></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="休息时间" prop="RestHour" :rules="[
                { required: true, message: '请输入休息时间', trigger: 'blur' }
              ]">
                <el-input v-model="form.RestHour" type="number" style="width: 100px;" :disabled="Resedisabled"></el-input>/小时
              </el-form-item>
              <el-form-item label="备注" prop="Remark">
                <el-input type="textarea" v-model="form.Remark"> </el-input>
              </el-form-item>

            </el-form>
            <div slot="footer" class="dialog-footer">
              <el-button @click="dialogTableVisible = false">取 消</el-button>
              <el-button type="primary" @click="update('form')">确 定</el-button>
            </div>
          </el-dialog>
        </div>
      </div>
      <el-table :data="tableData" style="width: 100%;margin-bottom: 70px;" ref="multipleTable" tooltip-effect="dark" :border="true">
        <!-- <el-table-column type="selection" width="42">@selection-change="handleSelectionChange"
        </el-table-column> -->
        <el-table-column type="index" width="50"><!-- :index="indexMethod" -->
        </el-table-column>
        <el-table-column label="企业名称" width="100">
          <template slot-scope="scope">
            <span
              style="white-space: nowrap;text-overflow: ellipsis;overflow: hidden;width: 100px;">{{scope.row.CompanyName}}</span>
          </template>
        </el-table-column>
        <el-table-column label="部门" prop="DeptName" width="80">
        </el-table-column>
        <el-table-column label="班次" prop="Name" width="120">
        </el-table-column>
        <el-table-column>
          <template slot="header" slot-scope="scope">
            <label>工作周期</label>
          </template>
          <template slot-scope="scope">
            {{ scope.row.StartTime.replace(' ',':').replace(/\:/g,"-").split('-')[0]+"/"+scope.row.StartTime.replace(' ',':').replace(/\:/g,"-").split('-')[1]+"/"+scope.row.StartTime.replace(' ',':').replace(/\:/g,"-").split('-')[2]}}
            ­­­­－
            {{scope.row.EndTime.replace(' ',':').replace(/\:/g,"-").split('-')[0]+"/"+scope.row.EndTime.replace(' ',':').replace(/\:/g,"-").split('-')[1]+"/"+scope.row.EndTime.replace(' ',':').replace(/\:/g,"-").split('-')[2]}}
          </template>
        </el-table-column>
        <el-table-column>
          <template slot="header" slot-scope="scope">
            <label>工作时间</label>
          </template>
          <template slot-scope="scope">
            <el-tag size="medium">
              {{ scope.row.StartTime.replace(' ',':').replace(/\:/g,"-").split('-')[3]+":"+scope.row.StartTime.replace(' ',':').replace(/\:/g,"-").split('-')[4]}}
              ­－­
              {{scope.row.EndTime.replace(' ',':').replace(/\:/g,"-").split('-')[3]+":"+scope.row.EndTime.replace(' ',':').replace(/\:/g,"-").split('-')[4]}}
            </el-tag>
          </template>
        </el-table-column>
        <el-table-column label="是否休息" prop="IsRestTxt">
        </el-table-column>
        <el-table-column label="休息时间" prop="RestHour">
        </el-table-column>
        <el-table-column label="备注" prop="Remark">
        </el-table-column>
       <!-- <el-table-column label="是否启用" prop="IsEnabled">
          <template slot-scope="scope">
            <el-switch v-model="scope.row.IsEnabled" @change="switchChange(scope.row.IsEnabled, scope.row)">
            </el-switch>
          </template>
        </el-table-column> -->
      </el-table>
      <!-- 分页 -->
      <div style="width: 300px;margin: 0 auto;">
        <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="currentPage"
          :page-sizes="[10, 20, 30, 40]" :page-size="pagesize" layout="total, sizes, prev, pager, next, jumper"
          :total="TotalRecordQty">
        </el-pagination>
      </div>
    </el-card>
  </div>
</template>
<script>
  export default {
    name: 'Scheduling',
    //组件创建
    created() {
      this.WorkClassList()
    },
    mounted() {},
    data() {
      return {
        tableData: [],
        search: '', //关键字
        TotalRecordQty: 10, //总条数
        currentPage: 1, //初始页
        pagesize: 10, //每页的数据
        multipleSelection: [], //选中用户复选框
        disRese: false, //批量删除按钮
        reseId: [], //删除用户id
        //新增
        dialogTableVisible: false, //新增班次弹框
        form: {
          StartTime: '', //班次开始时间
          EndTime: '', //班次结束时间
          DeptId: null, //部门id
          DeptName: '', //部门名称
          Name: '', //班次
          Remark: '', //备注
          IsRest: 'true',
          RestHour: 0
        },

        Deptoptions: [], //所有部门
        props: { //树形结构的展示字段
          value: 'id',
          label: 'label',
          children: 'children',
          emitPath: false,
          multiple: false,
          checkStrictly: true
        },
        Resedisabled:false
        // EmployeeImport: false, //员工导入弹框
        // dynamicTags: [],
      }
    },
    methods: {
      // 获取部门名
      handleChange(value) {
        let nodesInfo = this.$refs['cascader'].getCheckedNodes()
        // console.log(nodesInfo)
        this.form.DeptName = nodesInfo[0].label
        console.log(this.form.DeptName)
      },
      // 所有部门
      Dept() {
        let that = this
        let Token = localStorage.getItem('Token')
        this.$axios.defaults.headers.common["x-Token"] = Token
        this.$axios.post('/Dept/GetTree', {
          "Data": {
            "Delay": true, //是否延迟加载
            "ParentId": 0, //父级ID
            "IsIncludeSelf": true, //包含自身
            "OnlyLoadPrivilegeOrg": false //是否开启权限过滤
          }
        }).then((res) => {
          if (res.data.Code == 0) {
            console.log(res.data)
            this.Deptoptions = res.data.Data
          } else {
            that.$message({
              type: 'info',
              message: res.data.Message
            })
          }
        })
      },
      // 序号
      indexMethod(index) {
        if (this.currentPage == 1) {
          return index * 1 + 1;
        } else {
          return (index + this.currentPage - 1) * 10 + 1
        }
      },
      //获取关键字
      NameChange(e) {
        // console.log(e)
        this.search = e
      },
      //搜索
      searchange() {
        this.Attendance()
      },
      // 初始页currentPage、初始每页数据数pagesize和数据data
      handleSizeChange: function(size) {
        this.pagesize = size;
        // console.log(this.pagesize) //每页下拉显示数据
      },
      handleCurrentChange: function(currentPage, index) {
        this.currentPage = currentPage;
        console.log(this.currentPage, index) //点击第几页
      },
      // 选中复选框
      handleSelectionChange(val) {
        this.multipleSelection = val;
        console.log(this.multipleSelection)
        console.log(this.multipleSelection.length)
        if (val.length > 0) {
          this.disRese = true
          return
        }
      },
      // 新增班次
      addScheduling() {
        this.Dept()
        this.dialogTableVisible = true; //默认页面不显示为false,点击按钮将这个属性变成true
      },
      //日期
      dateChange(e) {
        console.log(e)
      },
      //是否休息
      IsRestChange(e){
        console.log(e)
        if(e=='false'){
          this.Resedisabled = true
        }else{
           this.Resedisabled = false
        }
      },
      // 提交新增
      update(formName) {
         // console.log( Boolean(this.form.IsRest) )
        this.$refs[formName].validate((valid) => {
          if (valid) {
            console.log(this.form)
            let Token = localStorage.getItem('Token')
            let that = this
            this.$axios.defaults.headers.common["x-Token"] = Token
            this.$axios.post('/WorkAttendance/WorkClass/Add', {
              "Data": {
                "Name": this.form.Name,
                "StartTime": this.form.StartTime,
                "EndTime": this.form.EndTime,
                "DeptID": this.form.DeptId,
                "DeptName": this.form.DeptName,
                "IsRest":Boolean(this.form.DeptName),
                "RestHour":this.form.RestHour,
                "Remark": this.form.Remark
              }
            }).then(function(res) {
              console.log(res.data)
              if (res.data.Code == 0) {
                that.$message({
                  type: 'success',
                  message: res.data.Message
                })
                that.dialogTableVisible = false
                that.WorkClassList()
              } else {
                that.$message({
                  type: 'info',
                  message: res.data.Message
                })
              }
            })
            //
          } else {
            console.log('error submit!!');
            return false;
          }
        })
      },
      //是否启用
      switchChange(IsEnabled, item) {
        console.log(IsEnabled, item)
        // let Token = localStorage.getItem('Token')
        // let that = this
        // this.$axios.defaults.headers.common["x-Token"] = Token
        // this.$axios.post('/Role/EnableOrDisable', {
        //   "Data": {
        //     "ID": Id, //角色ID
        //     "Enabled": IsEnabled, // 启用/停用
        //   }
        // }).then(function(res) {
        //   console.log(res.data)
        //   if (res.data.Code == 0) {
        //     that.Jobposting()
        //   }
        // })
      },
      //班次列表
      WorkClassList() {
        let Token = localStorage.getItem('Token')
        let that = this
        this.$axios.defaults.headers.common["x-Token"] = Token
        this.$axios.post('/WorkAttendance/WorkClass/List', {
          "Data": {
            "QST": this.search,
            "SortDirection": 1,
            "PageNo": this.currentPage,
            "PageSize": this.pagesize
          }
        }).then(function(res) {
          console.log(res.data)
          if (res.data.Code == 0) {
            that.tableData = res.data.Data.Items
            that.TotalRecordQty = res.data.Data.TotalRecordQty
          } else {
            that.$message({
              type: 'info',
              message: res.data.Message
            })
          }
        })
      }
    }
  }
</script>
<style lang="less" scoped>
  /* 头部标题 */
  .header {
    align-items: center;
    justify-content: space-between;
  }

  .demo-input-suffix {
    display: flex;
  }

  .el-breadcrumb__item {
    display: flex;
  }

  /* 搜索框 */
  .seachInpt {
    margin-right: 10px;
  }

  .el-tag+.el-tag {
    margin-left: 10px;
  }

  .button-new-tag {
    margin-left: 10px;
    height: 32px;
    line-height: 30px;
    padding-top: 0;
    padding-bottom: 0;
  }
</style>
