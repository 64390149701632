<template>
	<div class="LookAuditItems">
		<el-card>
			<div class="demo-input-suffix header">
				<el-breadcrumb separator="/">
					<el-breadcrumb-item><i class="el-icon-date"></i>考勤管理 </el-breadcrumb-item>
					<el-breadcrumb-item>
						<span style="color: #333;font-weight: bolder;"  @click="backPush()">考勤对账单</span>
					</el-breadcrumb-item>
					<el-breadcrumb-item>对账单考勤明细</el-breadcrumb-item>
				</el-breadcrumb>
			</div>
			<div class="container">
				<div style="text-align: center;font-size: 20px;margin-top: 2%;font-weight: bolder;">
					<p>{{EnterpriseName}} -- {{FinanceName}}
						({{StartTime.split(' ')[0]}}~{{EndTime.split(' ')[0]}})</p>
				</div>
				<el-table id="out-table" :data="LookgridData" :summary-method="getSummaries" show-summary
					style="margin-top: 2%;">
					<el-table-column type="index" width="80">
					</el-table-column>
					<el-table-column prop="WorkTime" label="上班时间" width="200">
						<template slot-scope="scope" v-if="scope.row.WorkTime!=null">
							{{ scope.row.WorkTime}}
						</template>
					</el-table-column>
					<el-table-column label="下班时间" width="200">
						<template slot-scope="scope" v-if="scope.row.OffWorkTime!=null">
							{{ scope.row.OffWorkTime}}
						</template>
					</el-table-column>
					<el-table-column label="职位" prop="JobName" width="240">
						<template slot-scope="scope">
							<span
								style="white-space: nowrap;text-overflow: ellipsis;overflow: hidden;">{{scope.row.JobName}}</span>
						</template>
					</el-table-column>
					<el-table-column label="职位所属部门" prop="DeptName" width="240">
						<template slot-scope="scope">
							<span
								style="white-space: nowrap;text-overflow: ellipsis;overflow: hidden;">{{scope.row.DeptName}}</span>
						</template>
					</el-table-column>
					<el-table-column label="职位金额(元/小时)" prop="ContractSalary" width="160"></el-table-column>
					<el-table-column label="用户" width="100">
						<template slot-scope="scope">
							<el-tag>
								{{scope.row.UserName}}
							</el-tag>
						</template>
					</el-table-column>
					<el-table-column label="当前状态" prop="StatusTxt" width="120"></el-table-column>
					<el-table-column label="系统工时(/小时)" prop="WorkHour" width="140"></el-table-column>
					<el-table-column label="确认工时(/小时)" prop="ConfirmWorkHour" width="140"></el-table-column>
					<el-table-column label="是否早退" prop="IsZhaoTuiTxt" width="140"></el-table-column>
					<el-table-column label="是否迟到" prop="IsLateTxt" width="140"></el-table-column>
					<el-table-column label="是否加班" prop="IsOverTimeTxt" width="140"></el-table-column>
					<el-table-column label="加班时长" prop="OverTimeHour" width="140"></el-table-column>
					<el-table-column label="审核状态" prop="WorkHourAuditTxt" width="120"></el-table-column>
					<el-table-column label="审核人" prop="Auditor" width="120"></el-table-column>
					<el-table-column label="审核时间" prop="AuditTime" width="200"></el-table-column>
					<el-table-column label="审核备注" prop="Remark" width="200"></el-table-column>
				</el-table>
				<div style="text-align: center;margin: 2% 0;">
					<el-button style="margin-bottom: 5px;margin-top: 5px;" size="small" type="danger"
						plain @click="backPush()">返回上一页</el-button>
				</div>
			</div>
		</el-card>
	</div>
</template>
<script>
	export default {
		name: "LookAuditItems",
		data() {
			return {
				StartTime: this.$route.params.StartTime,
				EndTime: this.$route.params.EndTime,
				EnterpriseID: this.$route.params.EnterpriseID,
				EnterpriseName: this.$route.params.EnterpriseName,
				FinanceNo: this.$route.params.FinanceNo,
				FinanceName: this.$route.params.FinanceName,
				LookgridData: [],
			};
		},
		mounted() {
			if (this.StartTime == undefined) {
				this.backPush()
			} else {
				// console.log(this.StartTime)
				this.LookgridData = []
				this.LookAuditBill()
			}
		},
		methods: {

			backPush() {
				this.$router.push({
					path: '/AttendanceRecord'
				})
				// 加入keepalive缓存
				this.$store.commit('addKeepAliveCache', 'AttendanceRecord')
				var submenu = {
					Url: '/AttendanceRecord',
					Code: 'AttendanceRecord',
					Name: '考勤核对'
				}
				//修改选中菜单
				this.$store.commit('selectMenu', submenu)
			},
			// 对账单明细列表
			LookAuditBill() {
				let Token = localStorage.getItem('Token')
				let that = this
				this.$axios.defaults.headers.common["x-Token"] = Token
				this.$axios.post('/WorkAttendanceFinance/ListComFina', {
					"Data": {
						"WorkHourhudit": 2,
						"SortDirection": 1,
						"StartTime": this.StartTime,
						"EndTime": this.EndTime,
						"PageNo": 1, //当前页数
						"PageSize": 99, //每页条数
					}
				}).then(function(res) {
					console.log(res)
					that.LookIndex = null
					if (res.data.Code == 0) {
						that.LookgridData = res.data.Data.Items
						that.LookTableVisible = true

					} else {
						if (res.data.Code == 202) {
							localStorage.clear()
							that.$router.push({
								path: '/'
							})
						} else {
							that.$message({
								type: 'info',
								message: res.data.Message
							})
						}
					}
				})
			},
			// 总计
			getSummaries(param) {
				const {
					columns,
					data
				} = param;
				const sums = [];
				columns.forEach((column, index) => {
					if (index === 0) {
						sums[index] = '合计';
						return;
					}
					const values = data.map(item => Number(item[column.property]));
					// console.log(values)
					if (!values.every(value => isNaN(value))) {
						sums[index] = values.reduce((prev, curr) => {
							const value = Number(curr);
							if (!isNaN(value)) {
								return prev + curr;
							} else {
								return prev;
							}
						}, 0);
						switch (index) {
							case 8:
								sums[index] += ' 时';
								break;
							case 9:
								sums[index] += ' 时';
								break;
							case 14:
								sums[index] += ' 元';
								break;
							default:
								sums[index] = ' ';
								break;
						}
					} else {
						sums[index] = '';
					}
				});

				return sums;
			},

		},

	};
</script>
<style scoped>
	/* .LookAuditItems {
		padding: 20px;
	} */

	.crumbs {
		text-align: center;
		margin: 0 auto;
	}

	/* 头部标题 */
	.header {
		align-items: center;
		justify-content: space-between;
		margin-bottom: 20px;
	}

	.demo-input-suffix {
		display: flex;
	}

	.el-breadcrumb__item {
		display: flex;
	}

	/* 搜索框 */
	.seachInpt {
		margin-right: 10px;
	}

	/* 分页 */
	.center {
		display: fixed;
		bottom: 4%;
		width: 100%;
		left: 8%;
	}

	.el-table td,
	.el-table th {
		text-align: center;
	}

	.el-button {
		border: none;
	}

	.el-button--text {
		background-color: #FFF;
		background-image: none;
		border-radius: 4px;
		border: 1px solid #DCDFE6;
		box-sizing: border-box;
		display: inline-block;
		font-size: inherit;
		height: 40px;
		line-height: 40px;
		outline: 0;
		padding: 0 15px;
		transition: border-color .2s cubic-bezier(.645, .045, .355, 1);
		width: 100%;
		text-align: left;
	}

	.el-button--text:hover {
		border: 1px solid #c3c6cc;
	}

	.choose-dialog-main {
		display: flex;
		justify-content: space-around;
	}

	.el-checkbox {
		display: block;
	}

	.el-radio {
		display: inline-flex;
	}


	.demo-table-expand {
		font-size: 0;
	}

	.demo-table-expand label {
		width: 90px;
		color: #99a9bf;
	}

	.demo-table-expand .el-form-item {
		margin-right: 0;
		margin-bottom: 0;
		width: 50%;
	}
</style>