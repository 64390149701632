<template>
	<div id="Blacklist">
		<el-card>
			<div class="demo-input-suffix header">
				<el-breadcrumb separator="/">
					<el-breadcrumb-item><i class="el-icon-date"></i>系统管理</el-breadcrumb-item>
					<el-breadcrumb-item>黑名单</el-breadcrumb-item>
				</el-breadcrumb>
				<div class="demo-input-suffix">
					<el-input class="seachInpt" placeholder="请输入关键字" v-model="QST" clearable @change='NameChange'>
					</el-input>
					<el-button size="mini" type="primary" @click='searchange'>
						<i class="el-icon-search"></i>
						搜索
					</el-button>
				</div>
			</div>
			<div class="container">
				<el-table style="width: 100%;margin-bottom:70px;" :data="List" ref="multipleTable" tooltip-effect="dark"
					:border="true">
					<el-table-column type="index">
					</el-table-column>
					<el-table-column label="企业名称" prop="CompanyName">
					</el-table-column>
					<el-table-column label="用户名称" prop="UserName">
					</el-table-column>
					<el-table-column label="操作时间" prop="CreatedTime">
					</el-table-column>
					<el-table-column label="备注" prop="Remark">
					</el-table-column>
					<el-table-column align="right" width="180">
						<template slot-scope="scope">
							<el-button size="mini" @click="RemoveList(scope.row.Id)" style="margin-right: 5px;">移除黑名单
							</el-button>
						</template>
					</el-table-column>
				</el-table>
				<!-- 分页 -->
				<div style="width: 300px;margin: 0 auto;">
					<el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
						:current-page="currentPage" :page-sizes="[10, 20, 30, 40]" :page-size="pagesize"
						layout="total, sizes, prev, pager, next, jumper" :total="TotalRecordQty">
					</el-pagination>
				</div>
			</div>
		</el-card>
	</div>
</template>

<script>
	export default {
		name: 'Blacklist',
		created() {
			this.BlackListHandle()
		},
		data() {
			return {
				List: [],
				QST: '',
				TotalRecordQty: 10, //总条数
				currentPage: 1, //初始页
				pagesize: 10, //每页的数据
			}
		},
		methods: {
			//获取角色
			NameChange(e) {
				// console.log(e)
				this.QST = e
			},
			//搜索
			searchange() {
				this.BlackListHandle()
			},
			// 初始页currentPage、初始每页数据数pagesize和数据data
			handleSizeChange: function(size) {
				this.pagesize = size;
				// console.log(this.pagesize) //每页下拉显示数据
				this.BlackListHandle()
			},
			handleCurrentChange: function(currentPage, index) {
				this.currentPage = currentPage;
				// console.log(this.currentPage, index) //点击第几页
				this.BlackListHandle()
			},
			//获取黑名单列表
			BlackListHandle() {
				let Token = localStorage.getItem('Token')
				let that = this
				this.$axios.defaults.headers.common["x-Token"] = Token
				this.$axios.post('/BlackListHandle/List', {
					"Data": {
						"PageNO": that.currentPage, //页索引
						"PageSize": that.pagesize, //每页数量
						"SortDirection": 1, //排序，1倒叙，0升序
						"QST": that.QST //按角色名模糊查询
					}
				}).then(function(res) {
					console.log(res)
					console.log(res.data)
					if (res.data.Code == 0) {
						that.List = res.data.Data.Items
						that.TotalRecordQty = res.data.Data.TotalRecordQty
					} else {
						if (res.data.Code == 202) {
							that.$router.push("/login");
						}
						// else {
						// 	that.$message({
						// 		type: 'info',
						// 		message: res.data.Message
						// 	})
						// }
					}
				})
			},
			RemoveList(Id) {
				let that = this
				this.$confirm('此操作会将此用户移出入黑名单列表, 是否继续?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					let Token = localStorage.getItem('Token')
					this.$axios.defaults.headers.common["x-Token"] = Token
					this.$axios.post('/BlackListHandle/Remove', {
						"Data": {
							"Id": Id
						}
					}).then((res) => {
						if (res.data.Code == 0) {
							// console.log(res.data)
							that.$message({
								type: 'success',
								message: res.data.Message
							})
						} else {
							if (res.data.Code == 202) {
								that.$router.push("/login");
							} else {
								that.$message({
									type: 'info',
									message: res.data.Message
								})
							}
						}
						that.List = []
						that.BlackListHandle()
					})
				}).catch(() => {
					this.$message({
						type: 'info',
						message: '已取消'
					});
				});
			}
		}
	}
</script>

<style lang="less" scoped>
	/* 头部标题 */
	.header {
		align-items: center;
		justify-content: space-between;
	}

	.demo-input-suffix {
		display: flex;
	}

	.el-breadcrumb__item {
		display: flex;
	}

	/* 搜索框 */
	.seachInpt {
		margin-right: 10px;
	}
</style>