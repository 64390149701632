<template>
  <div id="Logging">
    <el-card>
      <div class="demo-input-suffix header">
        <el-breadcrumb separator="/">
          <el-breadcrumb-item><i class="el-icon-date"></i>系统管理</el-breadcrumb-item>
          <el-breadcrumb-item>日志记录</el-breadcrumb-item>
        </el-breadcrumb>
        <div class="demo-input-suffix">
          <div style="display: flex;align-items: center;margin-right: 10px;">
            <el-date-picker v-model="StartDT" type="date" placeholder="选择开始日期" format="yyyy 年 MM 月 dd 日"
              value-format="yyyy-MM-dd 00:00:00">
            </el-date-picker>
            <span style="display: inline-block;margin: 0 3px;">-</span>
            <el-date-picker v-model="EndDt" type="date" placeholder="选择结束日期" format="yyyy 年 MM 月 dd 日"
              value-format="yyyy-MM-dd 23:59:59">
            </el-date-picker>
          </div>
          <el-input class="seachInpt" placeholder="请输入关键字" v-model="QST" clearable @change='NameChange'>
          </el-input>
          <el-button size="mini" type="primary" @click='searchange'>
            <i class="el-icon-search"></i>
            搜索
          </el-button>
        </div>
      </div>
      <div class="container">
        <el-table style="width: 100%;margin-bottom: 70px;" :data="LoggingList" ref="multipleTable" tooltip-effect="dark" :border="true">
          <el-table-column type="index" ><!-- :index="indexMethod" -->
          </el-table-column>
          <el-table-column label="操作人" prop="Operator">
          </el-table-column>
          <el-table-column label="操作方法" prop="Title">
          </el-table-column>
          <el-table-column label="操作时间" prop="CreatedTime">
          </el-table-column>
        </el-table>
        <!-- 分页 -->
        <div style="width: 300px;margin: 0 auto;">
          <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
            :current-page="currentPage" :page-sizes="[10, 20, 30, 40]" :page-size="pagesize"
            layout="total, sizes, prev, pager, next, jumper" :total="TotalRecordQty">
          </el-pagination>
        </div>
      </div>
    </el-card>

  </div>
</template>

<script>
  export default {
    name: 'Logging',
    //组件创建
    created() {
      var _date = new Date()
      var Y = _date.getFullYear()
      var m = _date.getMonth() + 1
      var d = _date.getDate()
      m = m < 10 ? '0' + m : m;
      d = d < 10 ? '0' + d : d;
      this.StartDT = Y + '-' + m + '-' + d + ' 00:00:00'
      this.EndDt = Y + '-' + m + '-' + d + ' 23:59:59'
      this.Logging()
    },
    mounted() {},
    data() {
      return {
        StartDT: '', //开始日期
        EndDt: '', //结束日期
        QST: '', //关键字
        LoggingList: [], //用户列表
        TotalRecordQty: 10, //总条数
        currentPage: 1, //初始页
        pagesize: 10, //每页的数据
      }
    },
    methods: {
      // 条数
      indexMethod(index) {
        if (this.currentPage == 1) {
          return index * 1 + 1;
        } else {
          return (index + this.currentPage - 1) * 10 + 1
        }
      },
      //获取关键字
      NameChange(e) {
        this.QST = e
      },
      //搜索
      searchange() {
        this.Logging()
      },
      // 初始页currentPage、初始每页数据数pagesize和数据data
      handleSizeChange: function(size) {
        this.pagesize = size;
        // console.log(this.pagesize) //每页下拉显示数据
        this.Logging()
      },
      handleCurrentChange: function(currentPage, index) {
        this.currentPage = currentPage;
        // console.log(this.currentPage, index) //点击第几页
        this.Logging()
      },
      //获取日志记录
      Logging() {
        let Token = localStorage.getItem('Token')
        let that = this
        this.$axios.defaults.headers.common["x-Token"] = Token
        this.$axios.post('/Log', {
          "Data": {
            "StartDT": that.StartDT, //开始日期
            "EndDt": that.EndDt, //结束日期
            "SortDirection": 1, //排序，1倒叙，0升序
            "QST": that.QST //模糊查询
          }
        }).then(function(res) {
          console.log(res.data)
          if (res.data.Code == 0) {
            that.LoggingList = res.data.Data.Items
            that.TotalRecordQty = res.data.Data.TotalRecordQty
          }
        })
      }
    }
  }
</script>
<style lang="less" scoped>
  /* 头部标题 */
  .header {
    align-items: center;
    justify-content: space-between;
  }

  .demo-input-suffix {
    display: flex;
  }

  .el-breadcrumb__item {
    display: flex;
  }

  /* 搜索框 */
  .seachInpt {
    margin-right: 10px;
  }
</style>
