<template>
	<div id="Abnormal">
		<el-card>
			<div class="demo-input-suffix header">
				<el-breadcrumb separator="/">
					<el-breadcrumb-item><i class="el-icon-date"></i> 首页</el-breadcrumb-item>
					<el-breadcrumb-item>考勤异常</el-breadcrumb-item>
				</el-breadcrumb>
				<div class="demo-input-suffix">
					<el-input style="width:255px;" class="seachInpt" placeholder="请输入搜索关键字(用户名,岗位)"
						v-model="AttendanceName" clearable @change='NameChange'>
					</el-input>
					<el-button size="mini" type="primary" @click='searchange'>
						<i class="el-icon-search"></i>
						搜索
					</el-button>
				</div>
			</div>
			<div class="container">
				<el-table style="width: 100%;margin-bottom:70px;" :data="AttendanceList" ref="multipleTable"
					tooltip-effect="dark" :border="true">
					<el-table-column type="index">
					</el-table-column>
					<el-table-column label="岗位" prop="JobName" width="200">
						<template slot-scope="scope">
							<span class="jobname">{{scope.row.JobName}}</span>
						</template>
					</el-table-column>
					<el-table-column label="用户" prop="UserName" width="80"></el-table-column>
					<el-table-column label="当前状态" prop="AttendanceStatusTxt" width="100"></el-table-column>
					<el-table-column label="备注" prop="Remark" width="150"></el-table-column>
					<el-table-column label="更新时间" prop="CreatedTime" width="200"></el-table-column>
				</el-table>
				<!-- 分页 -->
				<div style="width: 300px;margin: 0 auto;">
					<el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
						:current-page="currentPage" :page-sizes="[10, 20, 30, 40]" :page-size="pagesize"
						layout="total, sizes, prev, pager, next, jumper" :total="TotalRecordQty">
					</el-pagination>
				</div>
			</div>
		</el-card>
	</div>
</template>

<script>
	export default {
		name: 'Abnormal',
		created() {
			this.Attendance()
		},
		data() {
			return {
				AttendanceName: '', //关键字
				AttendanceList: [], //列表
				TotalRecordQty: 0, //总条数
				currentPage: 1, //初始页
				pagesize: 10, //每页的数据
			}
		},
		mounted() {

		},
		methods: {
			//获取关键字
			NameChange(e) {
				// console.log(e)
				this.AttendanceName = e
			},
			//搜索
			searchange() {
				this.Attendance()
			},
			// 初始页currentPage、初始每页数据数pagesize和数据data
			handleSizeChange: function(size) {
				this.pagesize = size;
				// console.log(this.pagesize) //每页下拉显示数据
				this.Attendance()
			},
			handleCurrentChange: function(currentPage, index) {
				this.currentPage = currentPage;
				// console.log(this.currentPage, index) //点击第几页
				this.Attendance()
			},
			Attendance() {
				let Token = localStorage.getItem('Token')
				let that = this
				this.$axios.defaults.headers.common["x-Token"] = Token
				this.$axios.post('/WorkAttendance/ForgetAttendance/List', {
					"Data": {
						"QST": this.AttendanceName,
						"SortDirection": 1,
						"PageNo": this.currentPage,
						"PageSize": this.pagesize
					}
				}).then(function(res) {
					// console.log(res.data)
					if (res.data.Code == 0) {
						that.AttendanceList = res.data.Data.Items
						that.TotalRecordQty = res.data.Data.TotalRecordQty
						if (that.TotalRecordQty == 0) {
							that.$message({
								type: 'info',
								message: res.data.Data.Msg
							})
						}
					} else {
						if (res.data.Code == 202) {
							that.$router.push("/login");
						} else {
							that.$message({
								type: 'info',
								message: res.data.Message
							})
						}
					}
				})
			},
		}
	}
</script>

<style lang="less" scoped>
	/* 头部标题 */
	.header {
		align-items: center;
		justify-content: space-between;
	}

	.demo-input-suffix {
		display: flex;
	}

	.el-breadcrumb__item {
		display: flex;
	}

	/* 搜索框 */
	.seachInpt {
		margin-right: 10px;
	}

	.jobname {
		width: 200px;
		white-space: nowrap;
		text-overflow: ellipsis;
		overflow: hidden;
	}
</style>