var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"JobPostAdd"}},[_c('el-card',[_c('div',{staticClass:"demo-input-suffix header"},[_c('el-breadcrumb',{attrs:{"separator":"/"}},[_c('el-breadcrumb-item',[_c('i',{staticClass:"el-icon-date",staticStyle:{"margin-right":"5px"}}),_vm._v("岗位管理")]),_c('el-breadcrumb-item',[_vm._v("更新岗位")])],1),_c('div',[_c('a',{staticStyle:{"color":"rgb(255, 88, 0)"},attrs:{"href":"javascript://","onclick":"window.history.go(-1)"}},[_vm._v("[返回岗位列表]")])])],1),_c('div',{staticClass:"container"},[_c('el-form',{ref:"Addjobform",staticStyle:{"padding-bottom":"100px"},attrs:{"label-position":"right","label-width":"110px","model":_vm.Addjobform}},[_c('el-form-item',{attrs:{"label":"公司名称"}},[_c('span',[_vm._v(_vm._s(_vm.Addjobform.CompanyName))])]),_c('el-form-item',{attrs:{"label":"岗位名称","prop":"jobName","rules":[
          	{ required: true, message: '请填写岗位名称', trigger: 'blur' }
          ]}},[_c('el-input',{attrs:{"type":"text","status-icon":"","placeholder":"请填写岗位名称"},model:{value:(_vm.Addjobform.jobName),callback:function ($$v) {_vm.$set(_vm.Addjobform, "jobName", $$v)},expression:"Addjobform.jobName"}})],1),_c('div',{staticStyle:{"display":"flex","align-items":"center"}},[_c('el-form-item',{attrs:{"label":"城市 ","prop":"City","rules":[
            	{ required: true, message: '请选择城市', trigger: 'change' }
            ]}},[_c('el-select',{attrs:{"placeholder":"请选择城市"},on:{"change":_vm.CityChange},model:{value:(_vm.Addjobform.City),callback:function ($$v) {_vm.$set(_vm.Addjobform, "City", $$v)},expression:"Addjobform.City"}},_vm._l((_vm.CitychildArr),function(item){return _c('el-option',{key:item.Id,attrs:{"label":item.CityName,"value":item.Id}})}),1)],1),_c('el-form-item',{attrs:{"label":"区域 ","prop":"Area","rules":[
            	{ required: true, message: '请选择区域', trigger: 'change' }
            ]}},[_c('el-select',{attrs:{"placeholder":"请选择区域"},on:{"change":_vm.AreaChange},model:{value:(_vm.Addjobform.Area),callback:function ($$v) {_vm.$set(_vm.Addjobform, "Area", $$v)},expression:"Addjobform.Area"}},_vm._l((_vm.Addressoptions),function(item){return _c('el-option',{key:item.Id,attrs:{"label":item.AreaName,"value":item.Id}})}),1)],1)],1),_c('div',{staticStyle:{"display":"flex","align-items":"center"}},[_c('el-form-item',{attrs:{"label":"商圈","prop":"ShangQuan","rules":[
             	{ required: true, message: '请选择商圈', trigger: 'change' }
             ]}},[_c('el-select',{attrs:{"multiple":"","collapse-tags":"","placeholder":"请选择商圈"},on:{"change":_vm.ShangQuanChange},model:{value:(_vm.Addjobform.ShangQuan),callback:function ($$v) {_vm.$set(_vm.Addjobform, "ShangQuan", $$v)},expression:"Addjobform.ShangQuan"}},_vm._l((_vm.sqoptions),function(item){return _c('el-option',{key:item.Id,attrs:{"label":item.SQName,"value":item.Id}})}),1)],1),_c('el-form-item',{attrs:{"label":"地铁","prop":"Sub","rules":[
             	{ required: true, message: '请选择地铁', trigger: 'change' }
             ]}},[_c('el-cascader',{ref:"SubwayRef",attrs:{"options":_vm.Subwayoptions,"props":_vm.Subwayprops,"show-all-levels":false,"placeholder":"请选择地铁","collapse-tags":""},on:{"change":_vm.SubwayChange},model:{value:(_vm.Addjobform.Sub),callback:function ($$v) {_vm.$set(_vm.Addjobform, "Sub", $$v)},expression:"Addjobform.Sub"}})],1)],1),_c('el-form-item',{attrs:{"label":"工作详细地址","prop":"jobAddress","rules":[
          	{ required: true, message: '请填写工作详细地址', trigger: 'blur' }
          ]}},[_c('el-input',{attrs:{"type":"text","status-icon":"","placeholder":"请填写工作详细地址"},model:{value:(_vm.Addjobform.jobAddress),callback:function ($$v) {_vm.$set(_vm.Addjobform, "jobAddress", $$v)},expression:"Addjobform.jobAddress"}})],1),_c('div',{staticStyle:{"display":"flex","align-items":"center"}},[_c('el-form-item',{attrs:{"label":"招聘人数","prop":"jobNumber"}},[_c('div',{staticClass:"number"},[_c('el-input',{staticStyle:{"width":"40%"},attrs:{"type":"number","status-icon":"","placeholder":"请填写招聘人数","autocomplete":"off"},model:{value:(_vm.Addjobform.jobNumber),callback:function ($$v) {_vm.$set(_vm.Addjobform, "jobNumber", _vm._n($$v))},expression:"Addjobform.jobNumber"}}),_vm._v("人(0表示若干人) ")],1)]),_c('el-form-item',{attrs:{"label":"元/小时","prop":"Salary","rules":[
            	{ required: true, message: '请填写金额', trigger: 'blur' }
            ]}},[_c('el-input',{attrs:{"type":"number","status-icon":"","placeholder":"请填写金额","autocomplete":"off"},model:{value:(_vm.Addjobform.Salary),callback:function ($$v) {_vm.$set(_vm.Addjobform, "Salary", _vm._n($$v))},expression:"Addjobform.Salary"}}),_vm._v("元/小时 ")],1)],1),_c('el-form-item',{attrs:{"label":"工作周期","required":""}},[_c('el-col',{attrs:{"span":7}},[_c('el-form-item',{attrs:{"prop":"StartTimeDate","rules":[
                   { type: 'string', required: true, message: '请选择开始日期', trigger: 'change' }
                ]}},[_c('el-date-picker',{attrs:{"value-format":"yyyy-MM-dd","type":"date","placeholder":"选择开始日期"},model:{value:(_vm.Addjobform.StartTimeDate),callback:function ($$v) {_vm.$set(_vm.Addjobform, "StartTimeDate", $$v)},expression:"Addjobform.StartTimeDate"}})],1)],1),_c('el-col',{staticClass:"line",attrs:{"span":2}},[_vm._v("-")]),_c('el-col',{attrs:{"span":7}},[_c('el-form-item',{attrs:{"prop":"EndTimeDate","rules":[
                   { type: 'string', required: true, message: '请选择结束日期', trigger: 'change' }
                ]}},[_c('el-date-picker',{attrs:{"value-format":"yyyy-MM-dd","type":"date","placeholder":"选择结束日期"},model:{value:(_vm.Addjobform.EndTimeDate),callback:function ($$v) {_vm.$set(_vm.Addjobform, "EndTimeDate", $$v)},expression:"Addjobform.EndTimeDate"}})],1)],1)],1),_c('el-form-item',{attrs:{"label":"工作时间","required":""}},[_c('el-col',{attrs:{"span":7}},[_c('el-form-item',{attrs:{"prop":"StartTimeT","rules":[
                   { type: 'string', required: true, message: '请选择开始时间', trigger: 'change' }
                ]}},[_c('el-time-picker',{attrs:{"value-format":"HH:mm:00","picker-options":{
                  format:'HH:mm'
                },"type":"time","placeholder":"选择开始时间"},model:{value:(_vm.Addjobform.StartTimeT),callback:function ($$v) {_vm.$set(_vm.Addjobform, "StartTimeT", $$v)},expression:"Addjobform.StartTimeT"}})],1)],1),_c('el-col',{staticClass:"line",attrs:{"span":2}},[_vm._v("-")]),_c('el-col',{attrs:{"span":7}},[_c('el-form-item',{attrs:{"prop":"EndTimeT","rules":[
                   { type: 'string', required: true, message: '请选择结束时间', trigger: 'change' }
                ]}},[_c('el-time-picker',{attrs:{"value-format":"HH:mm:00","picker-options":{
                  format:'HH:mm'
                },"type":"time","placeholder":"选择结束时间"},model:{value:(_vm.Addjobform.EndTimeT),callback:function ($$v) {_vm.$set(_vm.Addjobform, "EndTimeT", $$v)},expression:"Addjobform.EndTimeT"}})],1)],1)],1),_c('div',{staticStyle:{"display":"flex","align-items":"center"}},[_c('el-form-item',{staticStyle:{"margin-bottom":"22px"},attrs:{"label":"是否休息","prop":"Rest","rules":[
                { required: true, message: '请选择是否休息', trigger: 'change' }
              ]}},[_c('el-radio-group',{model:{value:(_vm.Addjobform.Rest),callback:function ($$v) {_vm.$set(_vm.Addjobform, "Rest", $$v)},expression:"Addjobform.Rest"}},[_c('el-radio',{attrs:{"label":"0"}},[_vm._v("否")]),_c('el-radio',{attrs:{"label":"1"}},[_vm._v("是")])],1)],1),_c('el-form-item',{directives:[{name:"show",rawName:"v-show",value:(_vm.Addjobform.Rest==1),expression:"Addjobform.Rest==1"}],attrs:{"label":"休息时间"}},[_c('el-input',{attrs:{"type":"number","status-icon":"","placeholder":"请填写休息时间"},model:{value:(_vm.Addjobform.RestTime),callback:function ($$v) {_vm.$set(_vm.Addjobform, "RestTime", _vm._n($$v))},expression:"Addjobform.RestTime"}}),_vm._v("/小时 ")],1)],1),_c('el-form-item',{directives:[{name:"show",rawName:"v-show",value:(_vm.Addjobform.Rest==1),expression:"Addjobform.Rest==1"}],attrs:{"label":"休息备注","prop":"Remark"}},[_c('el-input',{attrs:{"type":"textarea"},model:{value:(_vm.Addjobform.Remark),callback:function ($$v) {_vm.$set(_vm.Addjobform, "Remark", $$v)},expression:"Addjobform.Remark"}})],1),_c('div',{staticStyle:{"display":"flex","align-items":"center"}},[_c('el-form-item',{attrs:{"label":"所属部门","prop":"Dept","rules":[
              { required: true, message: '请选择所属部门', trigger: 'change' }
            ]}},[_c('div',{staticClass:"block"},[_c('el-cascader',{ref:"cascader2",attrs:{"options":_vm.jobDeptoptions,"props":_vm.jobDeptprops,"show-all-levels":false,"placeholder":"请选择所属部门"},on:{"change":_vm.jobDepthandleChange},model:{value:(_vm.Addjobform.Dept),callback:function ($$v) {_vm.$set(_vm.Addjobform, "Dept", $$v)},expression:"Addjobform.Dept"}})],1)]),_c('el-form-item',{attrs:{"label":"岗位类别","prop":"JobTypeId","rules":[
              { required: true, message: '请选择岗位类别', trigger: 'change' }
            ]}},[_c('el-cascader',{ref:"cascader3",attrs:{"props":_vm.jobTypeprops,"options":_vm.jobTypeoptions,"show-all-levels":false,"placeholder":"请选择岗位类别"},on:{"change":_vm.jobTypeChange},model:{value:(_vm.Addjobform.JobTypeId),callback:function ($$v) {_vm.$set(_vm.Addjobform, "JobTypeId", $$v)},expression:"Addjobform.JobTypeId"}})],1)],1),_c('div',[_c('el-form-item',{staticStyle:{"margin-bottom":"22px"},attrs:{"label":"性别要求","prop":"sex","rules":[
                { required: true, message: '请选择性别', trigger: 'change' }
              ]}},[_c('el-radio-group',{model:{value:(_vm.Addjobform.sex),callback:function ($$v) {_vm.$set(_vm.Addjobform, "sex", $$v)},expression:"Addjobform.sex"}},[_c('el-radio',{attrs:{"label":"0"}},[_vm._v("男")]),_c('el-radio',{attrs:{"label":"1"}},[_vm._v("女")]),_c('el-radio',{attrs:{"label":"2"}},[_vm._v("不限")])],1)],1),_c('el-form-item',{staticStyle:{"margin-bottom":"22px"},attrs:{"label":"岗位福利","prop":"JobWelfare","rules":[
                { required: true, message: '请添加岗位福利', trigger: 'blur' }
              ]}},[_vm._l((_vm.Addjobform.JobWelfare),function(tag){return _c('el-tag',{key:tag,attrs:{"closable":"","disable-transitions":false},on:{"close":function($event){return _vm.handleClose(tag)}}},[_vm._v(" "+_vm._s(tag)+" ")])}),(_vm.inputVisible)?_c('el-input',{ref:"saveTagInput",staticClass:"input-new-tag",attrs:{"placeholder":"最长四个字符","size":"small","maxlength":"4"},on:{"blur":_vm.handleInputConfirm},nativeOn:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.handleInputConfirm($event)}},model:{value:(_vm.inputValue),callback:function ($$v) {_vm.inputValue=$$v},expression:"inputValue"}}):_c('el-button',{staticClass:"button-new-tag",attrs:{"size":"small"},on:{"click":_vm.showInput}},[_vm._v("+ 新建福利标签")])],2)],1),_c('el-form-item',{attrs:{"label":"岗位描述","prop":"jobInfo","rules":[
            { required: true, message: '请填写岗位描述', trigger: 'blur' }
          ]}},[_c('div',{staticClass:"editor"},[_c('quill-editor',{ref:"myQuillEditor",staticClass:"editor",staticStyle:{"width":"100%","height":"300px"},attrs:{"options":_vm.editorOption},on:{"change":function($event){return _vm.onEditorChange($event)}},model:{value:(_vm.Addjobform.jobInfo),callback:function ($$v) {_vm.$set(_vm.Addjobform, "jobInfo", $$v)},expression:"Addjobform.jobInfo"}})],1)])],1),_c('div',{staticClass:"footer"},[_c('el-button',{on:{"click":function($event){return _vm.AddjobOut('Addjobform')}}},[_vm._v("取 消")]),_c('el-button',{attrs:{"type":"primary"},on:{"click":function($event){return _vm.AddjobSubmit('Addjobform')}}},[_vm._v("确 定 ")])],1)],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }