import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from '../login/login.vue'
import Forget from '../login/forget.vue'
// import NotFound from '../login/404.vue';

import Home from '@/components/home.vue'
import Wellcome from '@/components/wellcome.vue'
import todolist from '../view/todolist.vue'
import Oddjobinformation from '@/view/Oddjobinformation.vue'
import Scheduling from '@/view/Scheduling.vue'
import AttendanceRecord from '../view/AttendanceRecord.vue'
import Jobposting from '../view/Jobposting.vue';
import Jobapplication from '../view/Jobapplication.vue'
import Salaryapplication from '../view/Salaryapplication.vue'
import Salarypayment from '../view/Salarypayment.vue'
import Mybusiness from '../view/Mybusiness.vue'
import CorporateSector from '../view/CorporateSector.vue'
import BusinessUsers from '../view/BusinessUsers.vue'
import Authority from '../view/Authority.vue'
import Logging from '../view/Logging.vue'
import JobPostAdd from '../view/JobPostAdd.vue';
import JobPostEdit from '../view/JobPostEdit.vue';
import ResumePreview from '../view/ResumePreview.vue'
import AttendanceList from '../view/AttendanceList.vue'
import Abnormal from '../view/Abnormal.vue'
import CuseAttendance from '../view/CuseAttendance.vue'
import ActedEditJob from '../view/ActedEditJob.vue'
import Bill from '../view/Bill.vue'
import Statistics from '../view/Statistics.vue'
import JobStatistics from '../view/JobStatistics.vue'
import UserStatistics from '../view/UserStatistics.vue'
import Blacklist from '../view/Blacklist.vue'
import LookAudit from '../view/LookAudit.vue'

// 引入加载条
import NProgress from 'nprogress'
import 'nprogress/nprogress.css'
// 配置
NProgress.inc()
NProgress.configure({
  easing: 'ease', // 动画方式
  speed: 800, // 递增进度条的速度
  showSpinner: false, // 是否显示加载ico
  trickleSpeed: 200, // 自动递增间隔
  minimum: 0.1 // 初始化时的最小百分比
})

Vue.use(VueRouter)

// 所有人都可以访问
const routes = [{
    path: '/',
    redirect: '/login'
  },
  {
    path: '/login',
    name: 'login',
    component: Login
  },
  {
    path: '/home',
    name: 'home',
    component: Home,
    redirect: '/wellcome',
    meta: {
      title: '首页'
    },
    children: [
      {
        path: '/wellcome',
        name: 'wellcome',
        component: Wellcome,
        meta: {
          title: '首页'
        }
      },
      {
        path:'/todolist',
        name:'todolist',
        component:todolist,
        meta:{
          title:'工作台'
        }
      },
      {
        path: '/Oddjobinformation',
        name: 'Oddjobinformation',
        component: Oddjobinformation,
        meta: {
          title: 'C端用户信息(零工信息)'
        }
      },
      {
        path: '/Scheduling',
        name: 'Scheduling',
        component: Scheduling,
        meta: {
          title: '用工排班'
        }
      },
      {
        path: '/AttendanceRecord',
        name: 'AttendanceRecord',
        component: AttendanceRecord,
        meta: {
          title: '考勤核对'
        }
      },
      {
        path: '/AttendanceList',
        name: 'AttendanceList',
        component: AttendanceList,
        meta: {
          title: '考勤记录'
        }
      },
	  {
	    path: '/LookAudit',
	    name: 'LookAudit',
	    component: LookAudit,
	    meta: {
	      title: '对账单明细'
	    }
	  },
      {
        path: '/Statistics',
        name: 'Statistics',
        component: Statistics,
        meta: {
          title: '部门数据统计'
        }
      },
      {
        path: '/JobStatistics',
        name: 'JobStatistics',
        component: JobStatistics,
        meta: {
          title: '职位数据统计'
        }
      },
      {
        path: '/UserStatistics',
        name: 'UserStatistics',
        component: UserStatistics,
        meta: {
          title: '考勤数据统计'
        }
      },
      {
        path: '/CuseAttendance',
        name: 'CuseAttendance',
        component: CuseAttendance,
        meta: {
          title: '个人考勤记录'
        }
      },
      {
        path: '/Abnormal',
        name: 'Abnormal',
        component: Abnormal,
        meta: {
          title: '考勤异常'
        }
      },
      {
        path: '/Jobposting',
        name: 'Jobposting',
        component: Jobposting,
        meta: {
          title: '职位发布'
        }
      },
      {
        path: '/JobPostAdd',
        name: 'JobPostAdd',
        component: JobPostAdd,
        meta: {
          title: '新增职位'
        }
      },
      {
        path: '/Blacklist',
        name: 'Blacklist',
        component: Blacklist,
        meta: {
          title: '黑名单'
        }
      },
      {
        path: '/JobPostEdit',
        name: 'JobPostEdit',
        component: JobPostEdit,
        meta: {
          title: '修改职位'
        }
      },
      {
        path: '/Bill',
        name: 'Bill',
        component: Bill,
        meta: {
          title: '发票管理'
        }
      },
      {
        path: '/ActedEditJob',
        name: 'ActedEditJob',
        component: ActedEditJob,
        meta: {
          title: '重新发布'
        }
      },
      {
        path: '/Jobapplication',
        name: 'Jobapplication',
        component: Jobapplication,
        meta: {
          title: '职位应聘'
        }
      },
      {
        path: '/Salaryapplication',
        name: 'Salaryapplication',
        component: Salaryapplication,
        meta: {
          title: '薪资申请'
        }
      },
      {
        path:'/Salarypayment',
        name:'Salarypayment',
        component:Salarypayment,
        meta:{
          title:'薪资支付'
        }
      },
      {
        path:'/Mybusiness',
        name:'Mybusiness',
        component:Mybusiness,
        meta:{
          title:'我的企业'
        }
      },
      {
        path:'/CorporateSector',
        name:'CorporateSector',
        component:CorporateSector,
        meta:{
          title:'企业部门'
        }
      },
      {
        path:'/BusinessUsers',
        name:'BusinessUsers',
        component:BusinessUsers,
        meta:{
          title:'企业用户'
        }
      },
      {
        path:'/Authority',
        name:'Authority',
        component:Authority,
        meta:{
          title:'权限'
        }
      },
      {
        path:'/Logging',
        name:'Logging',
        component:Logging,
        meta:{
          title:'日志记录'
        }
      }
    ]
  },
  {
    path: '/forget',
    name: 'Forget',
    component: Forget
  },
  {
    path:'/ResumePreview',
    name:'ResumePreview',
    component:ResumePreview
  }
  // {
  //   path: '/404',
  //   component: NotFound,
  //   name: '',
  // },
  // {
  //   path: '*',
  //   redirect: '/404'
  // }
]

const router = new VueRouter({
  routes
})

/**
 * 挂载路由守卫
 * to 代表将要访问的路径
 * from 代表从哪个路径跳转而来
 * next 是一个函数，next() 放行。   next('/login') 强制跳转
 */
router.beforeEach((to, from, next) => {
  // 加载条
  NProgress.start()

  //放行
  next()
})


// 挂载路由守卫 - 访问结束
router.afterEach(() => {
  // 关闭加载条
  NProgress.done()
})

export default router
