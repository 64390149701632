<template>
	<div class="container">
		<el-container>
			<el-header>
				<div class="warp-header clearfix">
					<a class="logo" href="https://www.61msh.com/">
						<img src="../assets/msh22.png" width="140px" height="45px" />
					</a>
					<div class="fr clearfix lgbox">
						<a href="https://www.61msh.com" class="lgboxa">返回官网</a>
					</div>
				</div>
			</el-header>
			<el-main>
				<div class="lg">
					<div class="lgb clearfix wow fadeInDown">
						<div class="fl lgbl">
							<i class="lgbli"></i>
							<div class="distab">
								<div class="tab_cell tab_cell_right"
									style="display: flex; align-items: center; justify-content: center; flex-direction: column;">
									<img src="../assets/msh22.png" alt="" class="qlogo">
									<p
										style="margin-top: 20px !important;margin-bottom: 20px;width: 128px;text-align: center;">
										还没谋生活帐号?</p>
									<a href="javascript:;"
										style="margin-bottom: 0px;color: #FF8500;text-decoration: none;" @click="Open">
										去创建
										<i class="el-icon-arrow-right el-icon--right"
											style="border-radius: 50%;border: 1px solid #ff8500;"></i>
									</a>
								</div>
							</div>
						</div>
						<div class="fl lgbr">
							<div class="lg-login-body">
								<el-tabs v-model="activeName" @tab-click="handleClick">
									<el-tab-pane label="密码登录" name="first">
										<div class="login-tab-content">
											<div style="margin-top: 30px;">
												<el-form :model="formdata" :rules="rules" ref="formdata"
													label-width="100px" class="demo-ruleForm">
													<div style="margin-bottom: 30px;">
														<el-form-item prop="username">
															<el-input v-model="formdata.username" autocomplete="off"
																placeholder="请输入手机号" maxlength="11" minlength="11">
															</el-input>
														</el-form-item>
													</div>
													<div style="margin-bottom: 30px;">
														<el-form-item prop="password">
															<el-input placeholder="请输入密码" autocomplete="off"
																v-model="formdata.password" show-password></el-input>
														</el-form-item>
													</div>
												</el-form>
											</div>
											<div class="login-text-tip loginTextTip">
												<div class="login-forgot_pwd" @click="Forget">忘记密码？</div>
											</div>
										</div>
										<el-button :loading="loading" class="login-btn" @click="handleLodin('formdata')">登录
										</el-button>
									</el-tab-pane>
									<el-tab-pane label="验证码登录" name="second">
										<div class="login-tab-content">
											<div style="margin-top: 30px;">
												<el-form :model="form" :rules="formRules" ref="form" label-width="100px"
													class="demo-ruleForm">
													<div style="margin-bottom: 30px;">
														<el-form-item prop="phone">
															<el-input v-model="form.phone" autocomplete="off"
																placeholder="请输入手机号" maxlength="11" minlength="11">
															</el-input>
															<!-- <i v-show='PhoneChange'
																style="color: #F56C6C;border-top: 1px solid #F56C6C;display: block;padding-top: 10px;font-size: 12px;"
																class="el-icon-info">{{PhoneMassag}}</i> -->
														</el-form-item>
													</div>
													<div style="margin-bottom: 30px;">
														<el-form-item prop="Code">
															<el-input placeholder="请输入验证码" autocomplete="off"
																v-model="form.Code" maxlength="6" minlength="11">
																<el-button :loading="Codeloading" :disabled="sending"
																	slot="append" @click="GetMsgCode('form')">
																	{{ver_text}}
																</el-button>
															</el-input>
															<!-- <i v-show='CodeChange'
																style="color: #F56C6C;border-top: 1px solid #F56C6C;display: block;padding-top: 10px;font-size: 12px;"
																class="el-icon-info">{{CodeMassag}}</i> -->
														</el-form-item>
													</div>
												</el-form>
											</div>
										</div>
										<el-button :loading="Mmloading" class="login-btn" @click="Mmload('form')">登录</el-button>
									</el-tab-pane>
								</el-tabs>
							</div>
						</div>
					</div>
				</div>
			</el-main>
			<el-footer>
				<div class="wrap">
					<span>© 谋生活™ 上海谋事成数字科技（集团）有限公司 All Rights Reserved 丨</span>
					<a href="https://beian.miit.gov.cn/"> 沪ICP备20021244号-2</a>
				</div>
			</el-footer>
		</el-container>
	</div>
</template>
<script>
	export default {
		name: "login",
		data() {
			return {
				activeName: 'second',
				formdata: {
					username: "",
					password: "",
				},
				rules: {
					username: [{
						required: true,
						message: '手机号不能为空',
						trigger: 'blur'
					}, {
						required: true,
						pattern: /^1[3456789]\d{9}$/,
						message: '手机号格式不正确',
						trigger: 'blur'
					}],
					password: [{
						required: true,
						message: '密码不能为空',
						trigger: 'blur'
					}]
				},
				//验证码
				form: {
					phone: "",
					Code: "",
				},
				formRules: {
					phone: [{
						required: true,
						message: '手机号不能为空',
						trigger: 'blur'
					}, {
						required: true,
						pattern: /^1[3456789]\d{9}$/,
						message: '手机号格式不正确',
						trigger: 'blur'
					}],
					Code: [{
							required: true,
							message: '验证码不能为空',
							trigger: 'blur'
						},
						{
							min: 6,
							max: 6,
							message: '长度为6位',
							trigger: 'blur'
						}
					]
				},
				//验证码
				ver_text: '获取验证码',
				loading: false, //账号登录
				Codeloading: false, //验证码
				// PhoneMassag: '', //提示文字
				// PhoneChange: false, //验证码错误
				sending: false, //获取验证码按钮禁用
				Mmloading: false, //免密登录
				// CodeMassag: '', //提示文字
				// CodeChange: false, //验证码错误
			};
		},
		created() {
			// 防止直接使用的登录页面存在缓存
			window.localStorage.clear()
		},
		methods: {
			// 切换tabs
			handleClick(tab, event) {
				console.log(tab, event);
			},
			//创建账号
			Open() {
				this.$alert('<strong>暂无账号时，请联系我们预约体验<br>客服热线：400-700-9161,021-31770068</strong>', '温馨提示', {
					dangerouslyUseHTMLString: true
				});
			},
			//忘记密码
			Forget() {
				this.$alert('<strong>忘记密码时，请联系我们找回密码<br>客服热线：400-700-9161,021-31770068</strong>', '温馨提示', {
					dangerouslyUseHTMLString: true
				});
			},
			//账号登录
			handleLodin(formName) {
				this.$refs[formName].validate((valid) => {
					if (valid) {
						this.loading = true
						var that = this
						this.$axios.post('/User/Login', {
							"Data": {
								"Account": that.formdata.username,
								"Password": that.formdata.password
							}
						}).then((res) => {
							// this.loading = false
							// const loading = this.$loading({
							// 	lock: true,
							// 	text: 'Loading',
							// 	spinner: 'el-icon-loading',
							// 	background: 'rgba(0, 0, 0, 0.7)'
							// });
							console.log(res)
							if (res.data.Code == 0) {
								that.$message({
									showClose: true,
									message: res.data.Message,
									type: 'success'
								});
								// localStorage.setItem('adminName', res.data.Data.Token)
								localStorage.setItem('Token', res.data.Data.Token)
								localStorage.setItem('DateInfo', JSON.stringify(res.data.Data))
								console.log(JSON.stringify(res.data.Data))
								that.$refs[formName].resetFields()
								that.$router.push({
									path: '/home'
								})
							} else {
								that.$message({
									showClose: true,
									message: res.data.Message,
									type: 'error'
								});

							}
							// loading.close();
							that.loading = false
						})
					} else {
						// this.$refs[formName].resetFields()
						// this.$message({
						// 	showClose: true,
						// 	message: '手机号和密码不能为空',
						// 	type: 'error'
						// });
						return false;
					}
				})
			},
			//获取验证码
			GetMsgCode(formName) {
				console.log(formName)
				this.$refs[formName].validateField(['phone'], (valid) => {
					console.log(valid)
					if (!valid) {
						//发送验证码
						this.Codeloading = true;
						this.sending = true;
						var that = this
						this.$axios.post('/User/MsgLogin/GetMsgCode', {
							"Data": {
								"Phone": that.form.phone
							}
						}).then(res => {
							that.Codeloading = false;
							console.log(res)
							if (res.data.Code == 0) {
								let time = 60;
								let timer = null;
								that.$message({
									showClose: true,
									message: '验证码已发送,请您注意查收',
									type: 'success'
								});
								that.ver_text = time + "s后重新获取";
								timer = setInterval(e => {
									time--;
									if (time <= 0) {
										that.sending = false;
										that.ver_text = "重新获取";
										time = 60;
										clearInterval(timer);
										timer = null;
									} else {
										that.ver_text = time + "s后重新获取";
									}
								}, 1000)
							} else {
								that.$message({
									showClose: true,
									message: `${res.data.Message}`,
									type: 'error'
								});
							}
						}).catch(err => {
							let time = 60;
							let timer = null;
							that.$message({
								showClose: true,
								message: `${err.data.Message}`,
								type: 'error'
							});
							that.ver_text = time + "s后重新获取";
							timer = setInterval(e => {
								time--;
								if (time <= 0) {
									that.sending = false;
									that.ver_text = "重新获取";
									time = 60;
									clearInterval(timer);
									timer = null;
								} else {
									that.ver_text = time + "s后重新获取";
								}
							}, 1000)
							that.sending = false
							that.Codeloading = false
						})
					} else {
						// this.$message({
						// 	showClose: true,
						// 	message: '请输入正确的手机号',
						// 	type: 'error'
						// });
						return false;
					}
				})
			},
			//免密登录
			Mmload(formName) {
				this.$refs[formName].validate((valid) => {
					if (valid) {
						this.Mmloading = true
						var that = this
						this.$axios.post('/User/MsgLogin/Login', {
							"Data": {
								"Phone": that.form.phone,
								"Code": that.form.Code
							}
						}).then((res) => {
							
							console.log(res)
							if (res.data.Code == 0) {
								that.$message({
									showClose: true,
									message: res.data.Message,
									type: 'success'
								});
								
								localStorage.setItem('Token', res.data.Data.Token)
								localStorage.setItem('DateInfo', JSON.stringify(res.data.Data))
								console.log(JSON.stringify(res.data.Data))
								that.$router.push({
									path: '/home'
								})
								that.$refs[formName].resetFields()
							} else {
								that.$message({
									showClose: true,
									message: res.data.Message,
									type: 'error'
								});
								// if (res.data.Code == '-301') {
								// 	that.PhoneChange = true
								// 	that.PhoneMassag = res.data.Message
								// } else if (res.data.Code == '-304') {
								// 	that.CodeChange = true
								// 	that.CodeMassag = res.data.Message
								// } else {
								// 	that.$message({
								// 		showClose: true,
								// 		message: res.data.Message,
								// 		type: 'error'
								// 	});
								// }
								// that.$refs[formName].resetFields()
							}
							that.Mmloading = false
							// loading.close();
						})
					} else {
						return false;
					}
				})
			}
		}
	};
</script>
<style lang="less" scoped>
	body>.el-container {
		margin-bottom: 40px;
	}

	.container {
		width: 100%;
		height: 100%;
	}

	.el-header {
		background-color: #fff;
		height: 60px;
		position: fixed;
		top: 0;
		width: 100%;
		line-height: 60px;
		box-shadow: 0 4px 6px 0 #eff2f8;
	}

	.warp-header {
		width: 1200px;
		margin: 0 auto;
		height: 60px;
	}

	.warp-header .logo {
		display: inline-block;
		margin-top: 9px;
		float: left;
	}

	.fr {
		float: right;
	}

	.warp-header a {
		text-decoration: none;
		color: #444;
		transition: all .25s;
	}

	.el-aside {
		background-color: #D3DCE6;
		color: #333;
		text-align: center;
		line-height: 200px;
	}

	//登录
	.el-main {
		padding-top: 60px;
	}

	.lg {
		background-color: #E9EEF3;
		padding-bottom: 80px;
		position: relative;
		z-index: 99;
		display: flex;
		justify-content: center;
	}

	.lgb {
		display: flex;
		width: auto;
		padding: 0;
		box-shadow: 0 0 14px 2px rgba(0, 0, 0, .06);
		border-radius: 3px;
		background: #fff;
		margin-top: 60px;
	}

	.fadeInDown {
		animation-name: fadeInDown;
	}

	@keyframes fadeInDown {
		0% {
			opacity: 0;
			-webkit-transform: translateY(-20px);
			transform: translateY(-20px);
		}

		to {
			opacity: 1;
			-webkit-transform: translateY(0);
			transform: translateY(0);
		}
	}

	//左侧
	.lgbl {
		width: 300px;
		height: 360px;
		position: relative;
		padding-top: 100px;
	}

	.lgbli {
		float: right;
		height: 360px;
		width: 1px;
		border-right: 2px dashed #ccc;
		position: relative;
		bottom: 50px;
	}

	.lgbl .distab {
		width: calc(100% - 14px);
		height: 50%;
		display: flex;
		align-items: center;
		justify-content: center;
		margin-top: 50px;
	}

	.tab_cell p.name {
		font-size: 20px !important;
		color: #222426 !important;
		margin-top: 20px !important;
		margin-bottom: 0;
		width: 128px;
		text-align: center;
	}

	.lgbl p {
		font-size: 16px;
		color: #666;
		margin-top: 30px;
		margin-bottom: 10px;
	}

	.fl {
		float: left;
	}

	.tab_cell .qlogo {
		padding: 0;
		width: 107px;
		height: 34px;
	}

	//右侧
	.lgbr {
		width: 482px;
		padding-left: 82px;
		width: auto;
		padding: 0;
	}

	.lg-login-body {
		position: relative;
		transform: translate(0);
		top: 0;
		left: 0;
	}

	.lg-login-body {
		width: 600px;
		padding: 60px 50px 60px 40px;
		box-shadow: 0 0 0 transparent;
	}

	.login-tab-content {
		padding-bottom: 111px;
	}

	//忘记密码
	.login-text-tip {
		height: 20px;
		padding: 20px 0 0;
		// margin-top: -30px;
		overflow: hidden;
	}

	.login-forgot_pwd {
		float: right;
		line-height: 22px;
		font-size: 16px;
		color: #ff8500;
		cursor: pointer;
	}

	//登录
	.login-btn {
		bottom: 60px;
		width: 510px;
	}

	.login-btn {
		position: relative;
		// pointer-events: none;
		// position: absolute;
		// width: 400px;
		// height: 44px;
		background-color: #ff8500;
		border-radius: 28px;
		border: none;
		// font-size: 20px;
		color: #fff;
		// line-height: 44px;
		// text-align: center;
		margin: 0 auto;
		// left: 40px;
		// bottom: 40px;
		cursor: pointer;
	}

	//版权
	.el-footer {
		height: 60px;
		position: fixed;
		bottom: 0;
		width: 100%;
		text-align: center;
		line-height: 60px;
		background-color: #B3C0D1;
	}

	.el-footer.wrap {
		padding: 18px 0;
		display: flex;
		align-items: center;
		justify-content: center;
		flex-wrap: wrap;
		color: #6d758a;
		line-height: 17px;
		/* width: 1200px; */
		/* margin: 0 auto; */
		font-size: 12px;
		/* font-family: PingFangSC-Regular, PingFang SC; */
		font-weight: 400;
		/* color: #ffffff; */
	}

	.el-footer .wrap span {
		line-height: 17px;
		color: #6d758a;
	}

	.el-footer .wrap img {
		margin-right: 5px;
		width: 14px;
	}

	.el-footer .wrap a {
		line-height: 17px;
		color: #6d758a;
		text-decoration: none;
	}

	.clearfix::after,
	.clearfix::before {
		content: "";
		display: table;
	}

	.clearfix::after {
		clear: both;
	}

	.lgbox a.lgboxa {
		display: inline-block;
		width: 80px;
		height: 28px;
		border: 1px solid #ff8500;
		color: #fff;
		font-size: 14px;
		background: #ff8500;
		border-radius: 28px;
		text-align: center;
		line-height: 26px;
		margin-top: 16px;
		margin-left: 10px;
	}

	//tabs
	// /deep/.el-tabs__item:hover {
	// 	color: #222426;
	// }

	/deep/.el-tabs__item {
		font-size: 20px;
		color: #999;
	}

	/deep/.el-tabs__item.is-active {
		color: #ff8500;
	}

	/deep/.el-tabs__active-bar {
		background-color: #ff8500;
	}

	//验证
	.drag {
		position: relative;
		// background-color: #e8e8e8;
		width: 70%;
		height: 37px;
		line-height: 37px;
		text-align: center;
		margin: 0 auto;
		border: 1px solid #c4c4c4;
		border-radius: 37px;
	}

	.handler {
		width: 37px;
		height: 37px;
		// border: 1px solid #c4c4c4;
		cursor: move;
		border-radius: 50%;
	}

	.handler_bg {
		border: 1px solid #c4c4c4;
		background: #fff url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAA3hpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuNS1jMDIxIDc5LjE1NTc3MiwgMjAxNC8wMS8xMy0xOTo0NDowMCAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wTU09Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9tbS8iIHhtbG5zOnN0UmVmPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvc1R5cGUvUmVzb3VyY2VSZWYjIiB4bWxuczp4bXA9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC8iIHhtcE1NOk9yaWdpbmFsRG9jdW1lbnRJRD0ieG1wLmRpZDo0ZDhlNWY5My05NmI0LTRlNWQtOGFjYi03ZTY4OGYyMTU2ZTYiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6NTEyNTVEMURGMkVFMTFFNEI5NDBCMjQ2M0ExMDQ1OUYiIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6NTEyNTVEMUNGMkVFMTFFNEI5NDBCMjQ2M0ExMDQ1OUYiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENDIDIwMTQgKE1hY2ludG9zaCkiPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDo2MTc5NzNmZS02OTQxLTQyOTYtYTIwNi02NDI2YTNkOWU5YmUiIHN0UmVmOmRvY3VtZW50SUQ9InhtcC5kaWQ6NGQ4ZTVmOTMtOTZiNC00ZTVkLThhY2ItN2U2ODhmMjE1NmU2Ii8+IDwvcmRmOkRlc2NyaXB0aW9uPiA8L3JkZjpSREY+IDwveDp4bXBtZXRhPiA8P3hwYWNrZXQgZW5kPSJyIj8+YiRG4AAAALFJREFUeNpi/P//PwMlgImBQkA9A+bOnfsIiBOxKcInh+yCaCDuByoswaIOpxwjciACFegBqZ1AvBSIS5OTk/8TkmNEjwWgQiUgtQuIjwAxUF3yX3xyGIEIFLwHpKyAWB+I1xGSwxULIGf9A7mQkBwTlhBXAFLHgPgqEAcTkmNCU6AL9d8WII4HOvk3ITkWJAXWUMlOoGQHmsE45ViQ2KuBuASoYC4Wf+OUYxz6mQkgwAAN9mIrUReCXgAAAABJRU5ErkJggg==') no-repeat center;
	}

	.handler_ok_bg {
		background: #fff url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAA3hpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuNS1jMDIxIDc5LjE1NTc3MiwgMjAxNC8wMS8xMy0xOTo0NDowMCAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wTU09Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9tbS8iIHhtbG5zOnN0UmVmPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvc1R5cGUvUmVzb3VyY2VSZWYjIiB4bWxuczp4bXA9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC8iIHhtcE1NOk9yaWdpbmFsRG9jdW1lbnRJRD0ieG1wLmRpZDo0ZDhlNWY5My05NmI0LTRlNWQtOGFjYi03ZTY4OGYyMTU2ZTYiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6NDlBRDI3NjVGMkQ2MTFFNEI5NDBCMjQ2M0ExMDQ1OUYiIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6NDlBRDI3NjRGMkQ2MTFFNEI5NDBCMjQ2M0ExMDQ1OUYiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENDIDIwMTQgKE1hY2ludG9zaCkiPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDphNWEzMWNhMC1hYmViLTQxNWEtYTEwZS04Y2U5NzRlN2Q4YTEiIHN0UmVmOmRvY3VtZW50SUQ9InhtcC5kaWQ6NGQ4ZTVmOTMtOTZiNC00ZTVkLThhY2ItN2U2ODhmMjE1NmU2Ii8+IDwvcmRmOkRlc2NyaXB0aW9uPiA8L3JkZjpSREY+IDwveDp4bXBtZXRhPiA8P3hwYWNrZXQgZW5kPSJyIj8+k+sHwwAAASZJREFUeNpi/P//PwMyKD8uZw+kUoDYEYgloMIvgHg/EM/ptHx0EFk9I8wAoEZ+IDUPiIMY8IN1QJwENOgj3ACo5gNAbMBAHLgAxA4gQ5igAnNJ0MwAVTsX7IKyY7L2UNuJAf+AmAmJ78AEDTBiwGYg5gbifCSxFCZoaBMCy4A4GOjnH0D6DpK4IxNSVIHAfSDOAeLraJrjgJp/AwPbHMhejiQnwYRmUzNQ4VQgDQqXK0ia/0I17wJiPmQNTNBEAgMlQIWiQA2vgWw7QppBekGxsAjIiEUSBNnsBDWEAY9mEFgMMgBk00E0iZtA7AHEctDQ58MRuA6wlLgGFMoMpIG1QFeGwAIxGZo8GUhIysmwQGSAZgwHaEZhICIzOaBkJkqyM0CAAQDGx279Jf50AAAAAABJRU5ErkJggg==') no-repeat center;
	}

	.drag_bg {
		background-color: #7ac23c;
		height: 37px;
		width: 0;
		border-radius: 37px;
	}

	.drag_text {
		position: absolute;
		top: 0px;
		width: 100%;
		color: #c4c4c4;
		text-align: center;
		-moz-user-select: none;
		-webkit-user-select: none;
		user-select: none;
		-o-user-select: none;
		-ms-user-select: none;
	}

	//输入框
	/deep/.el-input__inner {
		height: 44px;
		line-height: 44px;
		border: 0;
		background-color: #fff !important;
		padding: 0;
		font-size: 16px;
		color: #222426 !important;
		border-bottom: 1px solid #ececec;
		border-radius: 0;
	}

	/deep/.el-form-item__content {
		margin-left: 0 !important;
	}

	/deep/.el-input-group__append,
	.el-input-group__prepend {
		border: none;
		border-bottom: 1px solid #ececec;
		background-color: #fff;
		color: #FF8500;
	}
</style>
