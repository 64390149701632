<template>
	<div id="UserStatistics">
		<el-card>
			<div class="demo-input-suffix header">
				<el-breadcrumb separator="/">
					<el-breadcrumb-item><i class="el-icon-date" style="margin-right: 5px;"></i>统计报表</el-breadcrumb-item>
					<el-breadcrumb-item>考勤数据统计</el-breadcrumb-item>
				</el-breadcrumb>
				<div class="demo-input-suffix">
					<!-- <div style="margin-right: 10px;">
            <el-date-picker v-model="taskDate" style="width:100%" type="daterange" :picker-options="pickerOptions1"
              range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" value-format="yyyy-MM-dd"
              @change="DateChange">
            </el-date-picker>
          </div> -->
					<div style="display: flex;align-items: center;margin-right: 10px;">
						<el-date-picker style="width:190px;" v-model="StartTime" type="date" placeholder="选择开始日期"
							format="yyyy 年 MM 月 dd 日" value-format="yyyy-MM-dd 00:00:00">
						</el-date-picker>
						<span style="display: inline-block;margin: 0 3px;">-</span>
						<el-date-picker style="width:190px;" v-model="EndTime" type="date" placeholder="选择结束日期"
							format="yyyy 年 MM 月 dd 日" value-format="yyyy-MM-dd 23:59:59">
						</el-date-picker>
					</div>
					<el-button size="mini" type="primary" @click='searchange'>
						<i class="el-icon-search"></i>
						搜索
					</el-button>
				</div>
			</div>
			<div class="container">
				<div style="width: 100%;height: 400px;margin-top: 40px;" ref="chart">
				</div>
			</div>
		</el-card>
		<!-- <div class="loading" v-if="isLoading"></div> -->
	</div>
</template>

<script>
	// import { Loading } from 'element-ui';
	const echarts = require('echarts/lib/echarts');
	require('echarts/lib/component/title');
	require('echarts/lib/component/toolbox');
	require('echarts/lib/component/tooltip');
	require('echarts/lib/component/grid');
	require('echarts/lib/component/legend');
	require('echarts/lib/chart/line');
	export default {
		name: 'UserStatistics',
		created() {},
		data() {
			return {
				// taskDate: [],
				// pickerMinDate: null,
				// pickerMaxDate: null,
				// day30: 30 * 24 * 3600 * 1000, //以30天为例
				// // 日期使用
				// pickerOptions1: {
				//   onPick: ({
				//     maxDate,
				//     minDate
				//   }) => {
				//     if (minDate && this.pickerMinDate) {
				//       this.pickerMinDate = null;
				//     } else if (minDate) {
				//       this.pickerMinDate = minDate.getTime();
				//     }
				//   },
				//   disabledDate: (time) => {
				//     if (this.pickerMinDate) {
				//       return (time.getTime() > (this.pickerMinDate + this.day30)) || (time.getTime() < (this.pickerMinDate -
				//         this.day30));
				//     }
				//     return false;
				//   },

				// },
				AttendanceList: [], //数据
				StartTime: '',
				EndTime: '',
				//图表
				chart: null,
				xAxisDate: [], //x轴日期
				RenciList: [], //人次列表
				CountList: [], //人数列表
				HoursList: [], //总工时列表
				loading:''
				// isLoading:true
			}
		},
		mounted() {
			this.loading = this.$loading({
				lock: true,
				text: 'Loading',
				spinner: 'el-icon-loading',
				background: 'rgba(0, 0, 0, 0.7)'
			});
			var _date = new Date()
			var Y = _date.getFullYear()
			var m = _date.getMonth() + 1
			m = m < 10 ? '0' + m : m;
			this.StartTime = Y + '-' + m + '-01 00:00:00'
			// this.StartTime = this.getDay(-1, 7200000) + ' 00:00:00'
			this.EndTime = this.getDay(0, 3600000) + ' 23:59:59'
			// this.StartTime = Y + '-' + m + '-01 00:00:00'
			// this.EndTime = this.getDay(0, 3600000) + ' 23:59:59'
			// this.taskDate = [Y + '-' + m + '-01', this.getDay(0, 3600000)]
			this.AttendanceReport()
			window.addEventListener('resize', () => {
				this.chart.resize()
			})
		},
		methods: {
			// DateChange(e) {
			//   console.log(e)
			//   this.StartTime = e[0] + ' 00:00:00'
			//   this.EndTime = e[1] + ' 23:59:59'
			// },
			getDay(day, hours) {
				var today = new Date()
				var targetday = today.getTime() + 1000 * 60 * 60 * 24 * day + hours
				today.setTime(targetday)
				var tYear = today.getFullYear()
				var tMonth = today.getMonth()
				var tDate = today.getDate()
				tMonth = this.doHandleMonth(tMonth + 1)
				tDate = this.doHandleMonth(tDate)
				return tYear + '-' + tMonth + '-' + tDate
			},
			doHandleMonth(month) {
				var m = month
				if (month.toString().length === 1) {
					m = '0' + month
				}
				return m
			},
			//搜索
			searchange() {
				this.xAxisDate = []
				this.CountList = []
				this.RenciList = []
				this.HoursList = []
				this.AttendanceReport()
			},
			init() {
				this.chart = echarts.init(this.$refs.chart)
				var xisDate = this.xAxisDate
				var CountData = this.CountList
				var RenciData = this.RenciList
				var HoursData = this.HoursList

				var option = {
					title: {
						text: '考勤数据统计图'
					},
					tooltip: {
						trigger: 'axis'
					},
					legend: {
						data: ['用工人数', '用工次数', '用工总工时']
					},
					grid: {
						left: '3%',
						right: '4%',
						bottom: '3%',
						containLabel: true
					},
					toolbox: {
						feature: {
							saveAsImage: {}
						}
					},
					xAxis: {
						type: 'category',
						boundaryGap: false,
						data: xisDate
					},
					yAxis: {
						type: 'value'
					},
					series: [{
							name: '用工人数',
							type: 'line',
							stack: 'Total',
							data: CountData
						},
						{
							name: '用工次数',
							type: 'line',
							stack: 'Total',
							data: RenciData
						},
						{
							name: '用工总工时',
							type: 'line',
							stack: 'Total',
							data: HoursData
						}
					]
				};
				// 使用刚指定的配置项和数据显示图表。
				this.chart.setOption(option);
			},
			AttendanceReport() {
				let that = this
				let Token = localStorage.getItem('Token')
				this.$axios.defaults.headers.common["x-Token"] = Token
				this.$axios.post('/Statics/AttendanceReport', {
					"Data": {
						"StartDT": this.StartTime,
						"EndDT": this.EndTime
					}
				}).then((res) => {
					console.log(res)
					if (res.data.Code == 0) {

						that.AttendanceList = res.data.Data
						for (let item of that.AttendanceList) {
							that.xAxisDate.push(item.XDate) //日期
							that.CountList.push(item.UserCount) //用工人数数组
							that.RenciList.push(item.RenCi) //用工次数数组
							that.HoursList.push(item.TotalWorkHour) //总工时数组
						}
						// console.log(that.xAxisDate,that.CountList,that.RenciList,that.HoursList)

						that.init()
						// that.isLoading = false
						that.loading.close();

					} else {
						that.$message({
							type: 'info',
							message: res.data.Message
						})
					}
				})
			}
		}
	}
</script>

<style scoped>
	/* 头部标题 */
	.header {
		align-items: center;
		justify-content: space-between;
	}

	.demo-input-suffix {
		display: flex;
	}

	.el-breadcrumb__item {
		display: flex;
	}

	.el-input {
		width: 70%;
	}

	.el-col .el-input {
		width: 100%;
	}

	.line {
		text-align: center;
	}
</style>
