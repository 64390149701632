<template>
	<div id="wellcome">
		<el-card>
			<div class="header">
				<div class="tit" style="display: flex;align-items: center;">
					<div style="margin-right: 20px;">
						<el-image style="width: 180px;height: 95px;border-radius: 8px;" :src="LogoPic" fit="scale-down">
						</el-image>
					</div>
					<div style="line-height: 3em;">
						<h2>{{companyName}}</h2>
						<i style="margin-right: 20px;">电话：{{ContactTel}}</i>
						<span style="margin-right: 20px;">邮箱：{{Email}}</span>
						<a :href='Url'>网址：{{Url}}</a>
					</div>
				</div>
			</div>
			<div class="main">
				<div class="nav">
					<ul>
						<li @click="JobCountUrl()">
							<a href="#">
								<h2>{{JobCount}}</h2>
								<p>岗位数量</p>
							</a>
						</li>
						<!-- <li  @click="PersonsUrl()">
              <a>
                <h2>{{PersonsCount}}</h2>
                <p>零工数量</p>
              </a>
            </li> -->
						<li @click="JobappUrl()">
							<a href="#">
								<h2>{{ApplyedCount}}</h2>
								<p>岗位申请总人数</p>
							</a>
						</li>
						<li @click="PersonsUrl()">
							<a href="#">
								<h2>{{AttendanceCount}}</h2>
								<p>考勤人数</p>
							</a>
						</li>
						<!-- <li>
              <a>
                <h2>{{WaitImportCount}}</h2>
                <p>待签入零工数量</p>
              </a>
            </li> -->
						<li @click="PersonsUrl()">
							<a href="#">
								<h2>{{OnWorkCount}}</h2>
								<p>上班人数</p>
							</a>
						</li>
						<li @click="PersonsUrl()">
							<a href="#">
								<h2>{{OffWorkCount}}</h2>
								<p>下班人数</p>
							</a>
						</li>
						<li @click="AuditCountUrl()">
							<a href="#">
								<h2>{{AttendanceWaitedAuditCount}}</h2>
								<p>待核对考勤账单</p>
							</a>
						</li>
					</ul>
				</div>
			</div>
		</el-card>
	</div>
</template>
<script>
	export default {
		name: 'wellcome',
		//组件创建
		created() {

			this.console()
		},
		mounted() {},
		data() {
			return {
				LogoPic: '', //企业logo
				companyName: '', //企业名称
				ContactTel: '', //企业联系电话
				Email: '', //email
				Url: '', //网址
				ApplyedCount: 0, //职位申请数量
				AttendanceCount: 0, //考勤数量
				JobCount: 0, //职位数
				OffWorkCount: 0, //下班人数
				OnWorkCount: 0, //上班人数
				// PersonsCount: 0,//零工数量
				// WaitImportCount: 0,//待签入零工数量
				AttendanceWaitedAuditCount: 0, //待核对考勤数量
				JobListShow: false, //是否跳转岗位列表
				JobApplyShow: false, //是否跳转岗位申请列表
				PersonListShow: false, //是否跳转考勤记录列表
				AuditCountShow: false, //是否跳转考勤核对列表
			}
		},
		methods: {
			// 跳转权限
			menu() {
				let menuList = JSON.parse(localStorage.getItem('menuList'))
				console.log(menuList)
				for (let item in menuList) {
					// console.log(item)
					// console.log(menuList[item])
					switch (true) {
						case (menuList[item].ID == 100300):
							// console.log('考勤管理')
							for (let i of menuList[item].Children) {
								console.log(i)
								if (i.ID == 100302) {
									this.PersonListShow = true
								}
								if (i.ID == 100301) {
									this.AuditCountShow = true
								}

							}
							break;
						case (menuList[item].ID == 200100):
							// console.log('招聘管理')
							for (let i of menuList[item].Children) {
								console.log(i)
								if (i.ID == 200101) {
									this.JobListShow = true
									// return
								}
								if (i.ID == 200102) {
									this.JobApplyShow = true
									// return
								}
							}
							break;
						default:
							// console.log('default')
							break;
					}


				}
			},
			// 企业信息
			console() {
				let Token = localStorage.getItem('Token')
				let that = this
				this.$axios.defaults.headers.common["x-Token"] = Token
				this.$axios.post('/console', {
					"Data": {

					}
				}).then(function(res) {
					console.log(res.data)
					if (res.data.Code == 0) {
						that.menu()
						that.LogoPic = 'https://system.61msh.com/file' + res.data.Data.LogoPic
						that.companyName = res.data.Data.CompanyName //企业名称
						that.ContactTel = res.data.Data.ContactTel //企业联系电话
						that.Email = res.data.Data.Email //邮箱
						that.Url = res.data.Data.Url //网址
						that.ApplyedCount = res.data.Data.ApplyedCount //岗位申请人数
						that.AttendanceCount = res.data.Data.AttendanceCount //考勤人数
						that.JobCount = res.data.Data.JobCount //岗位数量
						that.OffWorkCount = res.data.Data.OffWorkCount //下班人数
						that.OnWorkCount = res.data.Data.OnWorkCount //正在上班人数
						// that.PersonsCount = res.data.Data.PersonsCount //零工数量
						// that.WaitImportCount = res.data.Data.WaitImportCount //待签入零工数量
						that.AttendanceWaitedAuditCount = res.data.Data.AttendanceWaitedAuditCount //待核对考勤数量
					} else {
						if (res.data.Code == 202) {
							that.$router.push("/login");
						} else {
							that.$message({
								type: 'info',
								message: res.data.Message
							})
						}
					}
				})
			},
			//岗位数量
			JobCountUrl() {
				if (this.JobListShow == true) {
					//跳转到岗位发布页面
					setTimeout(() => {
						this.$router.push("/Jobposting");
					}, 1000)
					// 加入keepalive缓存
					this.$store.commit('addKeepAliveCache', 'Jobposting')
					var submenu = {
						Url: '/Jobposting',
						Code: 'Jobposting ',
						Name: '岗位列表'
					}
					//修改选中菜单
					this.$store.commit('selectMenu', submenu)
				} else {
					// alert('您当前还没有权限查看岗位列表页面哦！')
				}
			},
			//申请人数
			JobappUrl() {
				if (this.JobApplyShow == true) {
					//跳转到申请记录页面
					setTimeout(() => {
						this.$router.push("/Jobapplication");
					}, 1000)
					// // 加入keepalive缓存
					this.$store.commit('addKeepAliveCache', 'Jobapplication')
					var submenu = {
						Url: '/Jobapplication',
						Code: 'Jobapplication ',
						Name: '申请记录'
					}
					//修改选中菜单
					this.$store.commit('selectMenu', submenu)
				} else {
					// alert('您当前还没有权限查看岗位申请页面哦！')
				}

			},
			// 考勤记录
			PersonsUrl() {
				if (this.PersonListShow == true) {
					//跳转到考勤记录页面
					setTimeout(() => {
						this.$router.push("/AttendanceList");
					}, 1000)
					// // 加入keepalive缓存
					this.$store.commit('addKeepAliveCache', 'AttendanceList')
					var submenu = {
						Url: '/AttendanceList',
						Code: '/AttendanceList',
						Name: '考勤记录'
					}
					//修改选中菜单
					this.$store.commit('selectMenu', submenu)
				} else {
					// alert('您当前还没有权限查看考勤记录页面哦！')
				}
			},
			//考勤核对
			AuditCountUrl() {
				if (this.AuditCountShow == true) {
					//跳转到考勤记录页面
					setTimeout(() => {
						this.$router.push("/AttendanceRecord");
					}, 1000)
					// // 加入keepalive缓存
					this.$store.commit('addKeepAliveCache', 'AttendanceRecord')
					var submenu = {
						Url: '/AttendanceRecord',
						Code: '/AttendanceRecord',
						Name: '考勤核对'
					}
					//修改选中菜单
					this.$store.commit('selectMenu', submenu)
				} else {
					// alert('您当前还没有权限查看考勤记录页面哦！')
				}
			},
		}
	}
</script>
<style lang="less" scoped>
	ul li {
		list-style: none;
	}

	.header {
		margin-top: 2%;

		.tit {
			// display: flex;
			// align-items: center;
			// justify-content: space-between;

			i {
				font-style: normal;
			}

			a {
				text-decoration: none;
				color: #333;
			}
		}
	}

	.main {
		overflow: hidden;
		position: relative;
		margin: 4% 1%;
		text-align: center;

		.nav {
			ul {
				li {
					float: left;
					width: 30%;
					text-align: center;
					background: lightblue;
					margin: 5px;
					padding: 20px;
				}

				a {
					color: #fff;
					text-decoration: none;
				}
			}
		}
	}
</style>