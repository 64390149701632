<template>
	<div id="BusinessUsers">
		<el-card>
			<div class="demo-input-suffix header">
				<el-breadcrumb separator="/">
					<el-breadcrumb-item><i class="el-icon-date"></i>系统管理</el-breadcrumb-item>
					<el-breadcrumb-item>企业用户</el-breadcrumb-item>
				</el-breadcrumb>
				<div class="demo-input-suffix">
					<el-input class="seachInpt" placeholder="请输入关键字(用户名,手机号,部门)" v-model="UserName"
						@change='NameChange'>
					</el-input>
					<el-button size="mini" type="primary" @click='searchange'>
						<i class="el-icon-search"></i>
						搜索
					</el-button>
					<el-button size="mini" type="danger" @click="addUser()">
						<!--  -->
						<i class="el-icon-plus"></i>
						新增
					</el-button>
					<el-dialog title="新增用户" :visible.sync="dialogTableVisible" center :append-to-body='true'
						:lock-scroll="false" width="50%">
						<span
							style="color: rgb(245, 108, 108);width: 100%;display: inline-block;margin-bottom: 20px;">注：新注册用户的账号密码默认为000000</span>
						<el-form :model="ruleForm" ref="ruleForm" label-width="100px" class="demo-ruleForm Forget-form">
							<el-form-item label="账号/手机号" prop="Account" :rules="[
                {required: true,message: '请输入手机号',trigger: 'blur'}
              ]">
								<el-input v-model="ruleForm.Account" placeholder="请输入手机号"></el-input>
							</el-form-item>

							<el-form-item label="昵称/姓名" prop="UserName" :rules="[
                {required: true,message: '请输入昵称',trigger: 'blur'}
              ]">
								<el-input type="text" v-model="ruleForm.UserName" placeholder="请输入昵称"></el-input>
							</el-form-item>

							<el-form-item label="性别" prop="Gender">
								<el-radio-group v-model="ruleForm.Gender">
									<el-radio label='0'>男</el-radio>
									<el-radio label='1'>女</el-radio>
								</el-radio-group>
							</el-form-item>

							<el-form-item label="邮箱" prop="Email" :rules="[{
              	required: true,
              	message: '请输入邮箱地址',
              	trigger: 'blur'
              },
              {
              	type: 'email',
              	message: '请输入正确的邮箱地址',
              	trigger: ['blur', 'change']
              }]">
								<el-input v-model="ruleForm.Email" placeholder="请输入邮箱"></el-input>
							</el-form-item>

							<el-form-item label="电话" prop="Tel">
								<el-input v-model="ruleForm.Tel" placeholder="请输入电话"></el-input>
							</el-form-item>

							<el-form-item label="所属角色" prop="RoleID" :rules="[
              	 { required: true, message: '请选择所属角色', trigger: 'change' }
              ]">
								<el-select v-model="ruleForm.RoleID" placeholder="请选择所属角色">
									<el-option v-for="item in options" :key="item.Value" :label="item.Name"
										:value="item.Value">
									</el-option>
								</el-select>
							</el-form-item>

							<el-form-item label="部门" prop="Dept">
								<div class="block">
									<el-cascader :options="Deptoptions" :props="props" v-model='ruleForm.Dept'
										:show-all-levels="false" @change="handleChange" ref="cascader"
										placeholder="请选择部门">
									</el-cascader>
								</div>
							</el-form-item>
						</el-form>
						<div slot="footer" class="dialog-footer">
							<el-button @click="dialogTableVisible = false">取 消</el-button>
							<el-button type="primary" @click='addUserSubmit("ruleForm")'>确 定</el-button>
						</div>
					</el-dialog>
				</div>
			</div>
			<div class="container">
				<el-table style="width: 100%;margin-bottom: 70px;" :data="UsersList" ref="multipleTable"
					tooltip-effect="dark" @selection-change="handleSelectionChange" :border="true">
					<el-table-column type="selection" width="55">
					</el-table-column>
					<el-table-column type="index"><!-- :index="indexMethod" -->
					</el-table-column>
					<el-table-column label="账号" prop="Account"><!-- width="100" -->
					</el-table-column>
					<el-table-column label="用户名" prop="UserName"><!-- width="100" -->
					</el-table-column>
					<el-table-column label="类型" prop="UserTypeTxt"><!-- width="100" -->
					</el-table-column>
					<el-table-column label="角色名" prop="RoleNames"><!-- width="100" -->
					</el-table-column>
					<el-table-column label="部门" prop="DeptName" width="220"><!-- -->
					</el-table-column>
					<el-table-column label="电话" prop="Tel"><!-- width="120" -->
					</el-table-column>
					<el-table-column label="邮箱" prop="Email"><!-- width="200" -->
						<template slot-scope="scope">
							<span style="white-space: nowrap;">{{scope.row.Email}}</span>
						</template>
					</el-table-column>
					<el-table-column label="性别" prop="GenderTxt"><!-- width="100" -->
					</el-table-column>
					<el-table-column align="right" width="180" fixed="right">
						<template slot="header" slot-scope="scope">
							<label>操作</label>
						</template>
						<template slot-scope="scope">
							<el-button size="mini" @click="handleEdit(scope.$index, scope.row.ID)"
								style="margin-right: 5px;">编辑
							</el-button>
							<el-dialog title="修改用户信息" :visible.sync="updateTableVisible" center :append-to-body='true'
								:lock-scroll="false" width="50%">
								<el-form :model="UserForm" ref="UserForm" label-width="100px"
									class="demo-ruleForm Forget-form">

									<el-form-item label="昵称/姓名" prop="UserName" :rules="[
                    {required: true,message: '请输入昵称',trigger: 'blur'}
                  ]">
										<el-input type="text" v-model="UserForm.UserName"
											placeholder="请输入昵称"></el-input>
									</el-form-item>

									<el-form-item label="性别" prop="Gender">
										<el-radio-group v-model="UserForm.Gender">
											<el-radio label='0'>男</el-radio>
											<el-radio label='1'>女</el-radio>
										</el-radio-group>
									</el-form-item>

									<el-form-item label="邮箱" prop="Email" :rules="[{
                  	required: true,
                  	message: '请输入邮箱地址',
                  	trigger: 'blur'
                  },
                  {
                  	type: 'email',
                  	message: '请输入正确的邮箱地址',
                  	trigger: ['blur', 'change']
                  }]">
										<el-input v-model="UserForm.Email" placeholder="请输入邮箱"></el-input>
									</el-form-item>

									<el-form-item label="电话" prop="Tel">
										<el-input v-model="UserForm.Tel" placeholder="请输入电话"></el-input>
									</el-form-item>
									<div v-show="isAdmin">
										<el-form-item label="所属角色" prop="RoleID" :rules="[
                    	 { required: true, message: '请选择所属角色', trigger: 'change' }
                    ]">
											<el-select v-model="UserForm.RoleID" placeholder="请选择所属角色">
												<el-option v-for="item in options" :key="item.Value" :label="item.Name"
													:value="item.Value">
												</el-option>
											</el-select>
										</el-form-item>

										<el-form-item label="部门" prop="Dept">
											<div class="block">
												<el-cascader :options="Deptoptions" :props="props"
													v-model='UserForm.Dept' :show-all-levels="false"
													@change="handleChange" ref="cascader" placeholder="请选择部门">
												</el-cascader>
											</div>
										</el-form-item>
									</div>

								</el-form>
								<div slot="footer" class="dialog-footer">
									<el-button @click="dialogTableVisible = false">取 消</el-button>
									<el-button type="primary" @click='UpUserSubmit("UserForm")'>确 定</el-button>
								</div>
							</el-dialog>
							<el-button size="mini" type="danger" plain
								@click="handleDelete(scope.$index, scope.row.ID)">删除</el-button>
						</template>
					</el-table-column>
				</el-table>
				<div style="margin-top: 20px;float: left;margin-bottom: 10px;" v-show="disRese">
					<el-button type="primary" @click="toggleSelection()">批量密码重置</el-button>
				</div>
				<!-- 分页 -->
				<div style="width: 300px;margin: 0 auto;">
					<el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
						:current-page="currentPage" :page-sizes="[10, 20, 30, 40]" :page-size="pagesize"
						layout="total, sizes, prev, pager, next, jumper" :total="TotalRecordQty">
					</el-pagination>
				</div>
			</div>
		</el-card>
	</div>
</template>

<script>
	export default {
		name: 'BusinessUsers',
		//组件创建
		created() {
			this.BusinessUsers()
		},
		mounted() {},
		data() {
			return {
				UserName: '', //搜索关键字
				UsersList: [], //用户列表
				TotalRecordQty: 10, //总条数
				currentPage: 1, //初始页
				pagesize: 10, //每页的数据
				dialogTableVisible: false, //新增
				// 表单
				ruleForm: {
					Account: '', //手机号/账号
					UserType: '1', //用户类型
					UserName: '', //昵称
					Email: '', //邮箱
					Tel: '', //电话
					RoleID: '', //所属角色
					Dept: '',
					DeptId: '', //所属部门
					Gender: '1', //性别
				},
				options: [], //所有角色
				DeptName: '', //部门名
				Deptoptions: [], //所有部门
				props: { //树形结构的展示字段
					value: 'id',
					label: 'label',
					children: 'children',
					// emitPath: false,
					// multiple: false,
					// checkStrictly: true
				},
				updateTableVisible: false, //修改
				UserForm: {
					ID: '', //用户id
					UserName: '', //昵称
					Email: '', //邮箱
					Tel: '', //电话
					RoleID: '', //所属角色
					Dept: '',
					DeptId: '', //所属部门
					DeptName: '', //部门名
					Gender: '1', //性别
				},
				isAdmin: true, //是否为管理员账号   是为true不显示所属角色 否则为false
				multipleSelection: [], //选中用户复选框
				disRese: false, //批量重置按钮
				reseId: [], //重置用户id
			}
		},
		methods: {
			// 条数
			indexMethod(index) {
				if (this.currentPage == 1) {
					return index * 1 + 1;
				} else {
					return (index + this.currentPage - 1) * 10 + 1
				}
			},
			//获取角色
			NameChange(e) {
				// console.log(e)
				this.UserName = e
			},
			//搜索
			searchange() {
				this.BusinessUsers()
			},
			// 初始页currentPage、初始每页数据数pagesize和数据data
			handleSizeChange: function(size) {
				this.pagesize = size;
				// console.log(this.pagesize) //每页下拉显示数据
				this.BusinessUsers()
			},
			handleCurrentChange: function(currentPage, index) {
				this.currentPage = currentPage;
				// console.log(this.currentPage, index) //点击第几页
				this.BusinessUsers()
			},
			// 获取用户列表
			BusinessUsers() {
				let Token = localStorage.getItem('Token')
				let that = this
				this.$axios.defaults.headers.common["x-Token"] = Token
				this.$axios.post('/User/List', {
					"Data": {
						"PageNO": that.currentPage, //页索引
						"PageSize": that.pagesize, //每页数量
						"SortDirection": 1, //排序，1倒叙，0升序
						"QST": that.UserName //模糊查询
					}
				}).then(function(res) {
					// console.log(res.data)
					if (res.data.Code == 0) {
						that.UsersList = res.data.Data.Items
						that.TotalRecordQty = res.data.Data.TotalRecordQty
					}
				})
			},
			//编辑
			handleEdit(index, row) {
				// console.log(index, row);
				let Token = localStorage.getItem('Token')
				let that = this
				this.$axios.defaults.headers.common["x-Token"] = Token
				this.$axios.post('/User/GetByID', {
					'Data': row
				}).then((res) => {
					if (res.data.Code == 0) {
						// console.log(res.data)
						that.UserForm.ID = res.data.Data.Id
						that.UserForm.Dept = [1, res.data.Data.DeptId]
						that.UserForm.DeptId = res.data.Data.DeptId
						that.UserForm.DeptName = res.data.Data.DeptName
						that.UserForm.Email = res.data.Data.EMail
						that.UserForm.Gender = '' + res.data.Data.Gender + ''
						that.UserForm.Tel = res.data.Data.Tel
						that.UserForm.RoleID = res.data.Data.RoleId
						that.UserForm.UserName = res.data.Data.UserName
						if (res.data.Data.UserType == 0) {
							this.isAdmin = false
						} else {
							this.isAdmin = true
						}
					} else {
						if (res.data.Code == 202) {
							that.$router.push("/login");
						}
						// else {
						// 	that.$message({
						// 		type: 'info',
						// 		message: res.data.Message
						// 	})
						// }
					}
					that.Role()
					that.Dept()
					that.updateTableVisible = true
				})
			},
			// 提交修改
			UpUserSubmit(formName) {
				this.$refs[formName].validate((valid) => {
					if (valid) {
						// console.log(this.UserForm)
						let that = this
						let Token = localStorage.getItem('Token')
						this.$axios.defaults.headers.common["x-Token"] = Token
						this.$axios.post('/User/Edit', {
							"Data": {
								"ID": that.UserForm.ID,
								"Tel": that.UserForm.Tel,
								"UserName": that.UserForm.UserName,
								"Email": that.UserForm.Email,
								"RoleID": that.UserForm.RoleID,
								"Gender": that.UserForm.Gender,
								"DeptId": that.UserForm.DeptId,
								"DeptName": that.UserForm.DeptName,
							}
						}).then((res) => {
							if (res.data.Code == 0) {
								that.$message({
									type: 'success',
									message: res.data.Message
								})
							} else {
								if (res.data.Code == 202) {
									that.$router.push("/login");
								} else {
									that.$message({
										type: 'info',
										message: res.data.Message
									})
								}
							}
							that.updateTableVisible = false;
							that.$refs[formName].resetFields();
							that.BusinessUsers()
						})
					} else {
						// console.log('error submit!!');
						return false;
					}
				});
			},
			// 删除
			handleDelete(index, row) {
				// console.log(index, row);
				this.$confirm('此操作将永久删除该文件, 是否继续?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning',
					center: true
				}).then(() => {
					let that = this
					let Token = localStorage.getItem('Token')
					this.$axios.defaults.headers.common["x-Token"] = Token
					this.$axios.post('/User/Delete', {
						"Data": row
					}).then((res) => {
						if (res.data.Code == 0) {
							that.$message({
								type: 'success',
								message: res.data.Message
							})
						} else {
							if (res.data.Code == 202) {
								that.$router.push("/login");
							} else {
								that.$message({
									type: 'info',
									message: res.data.Message
								})
							}
						}
						that.BusinessUsers()
					})
				})
			},
			//新增弹框
			addUser() {
				this.Role()
				this.Dept()
				this.dialogTableVisible = true; //默认页面不显示为false,点击按钮将这个属性变成true
			},
			//所有角色
			Role() {
				let that = this
				let Token = localStorage.getItem('Token')
				this.$axios.defaults.headers.common["x-Token"] = Token
				this.$axios.post('/Role/AllRoles', {
					"Data": ''
				}).then((res) => {
					if (res.data.Code == 0) {
						// console.log(res.data)
						this.options = res.data.Data
					} else {
						if (res.data.Code == 202) {
							that.$router.push("/login");
						} else {
							that.$message({
								type: 'info',
								message: res.data.Message
							})
						}
					}
				})
			},
			// 所有部门
			Dept() {
				let that = this
				let Token = localStorage.getItem('Token')
				this.$axios.defaults.headers.common["x-Token"] = Token
				this.$axios.post('/Dept/GetTree', {
					"Data": {
						"Delay": true, //是否延迟加载
						"ParentId": 0, //父级ID
						"IsIncludeSelf": true, //包含自身
						"OnlyLoadPrivilegeOrg": false //是否开启权限过滤
					}
				}).then((res) => {
					if (res.data.Code == 0) {
						// console.log(res.data)
						this.Deptoptions = res.data.Data
					} else {
						if (res.data.Code == 202) {
							that.$router.push("/login");
						} else {
							that.$message({
								type: 'info',
								message: res.data.Message
							})
						}
					}
				})
			},
			// 获取部门名
			handleChange(value) {
				let nodesInfo = this.$refs['cascader'].getCheckedNodes()
				if (value[1] == undefined) {
					this.ruleForm.DeptId = value[0]
					this.UserForm.DeptId = value[0]
				} else {
					this.ruleForm.DeptId = value[1]
					this.UserForm.DeptId = value[1]
				}
				this.DeptName = nodesInfo[0].label
				this.UserForm.DeptName = nodesInfo[0].label
				// console.log(this.DeptName, this.ruleForm.DeptId)
				// this.ruleForm.DeptId =value[1]
				// this.UserForm.DeptId = value[1]
				// this.DeptName = nodesInfo[0].label
				// this.UserForm.DeptName = nodesInfo[0].label
				// console.log(this.DeptName,this.ruleForm.DeptId)
			},
			// 提交新增
			addUserSubmit(formName) {
				// console.log(this.ruleForm)
				this.$refs[formName].validate((valid) => {
					if (valid) {
						// console.log(this.ruleForm)
						let that = this
						let Token = localStorage.getItem('Token')
						this.$axios.defaults.headers.common["x-Token"] = Token
						this.$axios.post('/User/Add', {
							"Data": {
								"Account": that.ruleForm.Account,
								"UserType": that.ruleForm.UserType,
								"UserName": that.ruleForm.UserName,
								"Tel": that.ruleForm.Tel,
								"Email": that.ruleForm.Email,
								"RoleID": that.ruleForm.RoleID,
								"Gender": that.ruleForm.Gender,
								"DeptId": that.ruleForm.DeptId,
								"DeptName": that.DeptName,
							}
						}).then((res) => {
							if (res.data.Code == 0) {
								that.$message({
									type: 'success',
									message: res.data.Message
								})
							} else {
								if (res.data.Code == 202) {
									that.$router.push("/login");
								} else {
									that.$message({
										type: 'info',
										message: res.data.Message
									})
								}
							}
							this.dialogTableVisible = false;
							this.$refs[formName].resetFields();
							this.BusinessUsers()
						})
					} else {
						// console.log('error submit!!');
						return false;
					}
				});
			},
			// 点击重置
			toggleSelection(rows) {
				if (this.multipleSelection.length < 1) {
					alert('请选择至少一个用户')
				} else {
					let province = this.multipleSelection
						.map(item => {
							return item.ID
						})
					// console.log(province)
					this.reseId = province
					// console.log(this.reseId)
					this.resePwd()
				}
				// console.log(this.multipleSelection)
			},
			// 选中复选框
			handleSelectionChange(val) {
				this.multipleSelection = val;
				if (this.multipleSelection.length > 0) {
					this.disRese = true

				} else {
					this.disRese = false
				}
				// console.log(this.multipleSelection)
			},
			// 重置
			resePwd() {
				// console.log(this.reseId)
				let that = this
				let Token = localStorage.getItem('Token')
				this.$axios.defaults.headers.common["x-Token"] = Token
				this.$axios.post('/User/ResetPwd', {
					"Data": {
						"IDs": this.reseId
					}
				}).then((res) => {
					if (res.data.Code == 0) {
						that.$message({
							type: 'success',
							message: res.data.Message
						})
					} else {
						if (res.data.Code == 202) {
							that.$router.push("/login");
						} else {
							that.$message({
								type: 'info',
								message: res.data.Message
							})
						}
					}
					that.BusinessUsers()
				})
			}
		}
	}
</script>
<style lang="less" scoped>
	/* 头部标题 */
	.header {
		align-items: center;
		justify-content: space-between;
	}

	.demo-input-suffix {
		display: flex;
	}

	.el-breadcrumb__item {
		display: flex;
	}

	/* 搜索框 */
	.seachInpt {
		margin-right: 10px;
		width: 260px;
	}
</style>