<template>
	<div id="JobPostAdd">
		<el-card>
			<div class="demo-input-suffix header">
				<el-breadcrumb separator="/">
					<el-breadcrumb-item><i class="el-icon-date" style="margin-right: 5px;"></i>岗位管理</el-breadcrumb-item>
					<el-breadcrumb-item>新增岗位</el-breadcrumb-item>
				</el-breadcrumb>
			</div>
			<div class="container">
				<el-form label-position="right" label-width="110px" :model="Addjobform" ref="Addjobform"
					style="padding-bottom: 100px;">
					<el-form-item label="公司名称">
						<span>{{CompanyName}}</span>
					</el-form-item>
					<el-form-item label="岗位名称" prop="jobName" :rules="[
          	{ required: true, message: '请填写岗位名称', trigger: 'blur' }
          ]">
						<el-input type="text"  status-icon v-model="Addjobform.jobName" placeholder="请填写岗位名称">
						</el-input>
					</el-form-item>
					<div style="display: flex;align-items: center;">
						<el-form-item label="城市 " prop="City" :rules="[
            	{ required: true, message: '请选择城市', trigger: 'change' }
            ]">
							<el-select v-model="Addjobform.City" placeholder="请选择城市" @change='CityChange'>
								<el-option v-for="item in CitychildArr" :key="item.Id" :label="item.CityName"
									:value="item.Id">
								</el-option>
							</el-select>
						</el-form-item>
						<el-form-item label="区域 " prop="Area" :rules="[
            	{ required: true, message: '请选择区域', trigger: 'change' }
            ]">
							<el-select v-model="Addjobform.Area" placeholder="请选择区域" @change='AreaChange'>
								<el-option v-for="item in Addressoptions" :key="item.Id" :label="item.AreaName"
									:value="item.Id">
								</el-option>
							</el-select>
						</el-form-item>
					</div>
					<div style="display: flex;align-items: center;">
						<el-form-item label="商圈" prop="ShangQuan" :rules="[
             	{ required: true, message: '请选择商圈', trigger: 'change' }
             ]">
							<el-select multiple collapse-tags v-model="Addjobform.ShangQuan" placeholder="请选择商圈"
								@change='ShangQuanChange'>
								<el-option v-for="item in sqoptions" :key="item.Id" :label="item.SQName"
									:value="item.Id">
								</el-option>
							</el-select>
						</el-form-item>
						<el-form-item label="地铁" prop="Sub" :rules="[
             	{ required: true, message: '请选择地铁', trigger: 'change' }
             ]">
							<el-cascader :options="Subwayoptions" :props="Subwayprops" v-model='Addjobform.Sub'
								:show-all-levels="false" @change="SubwayChange" ref="SubwayRef" placeholder="请选择地铁"
								collapse-tags>
							</el-cascader>
						</el-form-item>
					</div>
					<el-form-item label="工作详细地址" prop="jobAddress" :rules="[
          	{ required: true, message: '请填写工作详细地址', trigger: 'blur' }
          ]">
						<el-input type="text" status-icon v-model="Addjobform.jobAddress" placeholder="请填写工作详细地址">
						</el-input>
					</el-form-item>
					<div style="display: flex;align-items: center;">
						<el-form-item label="招聘人数" prop="jobNumber">
							<div class="number">
								<el-input type="number" status-icon v-model.number="Addjobform.jobNumber"
									placeholder="请填写招聘人数" min="0" style="width: 40%;" autocomplete="off">
								</el-input>人(0表示若干人)
							</div>
						</el-form-item>
						<el-form-item label="元/小时" prop="ContractSalary" :rules="[
            	{ required: true, message: '请填写金额', trigger: 'blur' }
            ]">
							<el-input type="number" status-icon v-model.number="Addjobform.ContractSalary"
								placeholder="请填写金额" min="0" autocomplete="off" disabled>
							</el-input>元/小时
						</el-form-item>
					</div>
					<el-form-item label="工作周期" required>
						<el-col :span="7">
							<el-form-item prop="StartTimeDate"
								:rules="[{required: true,message: '请选择开始日期', trigger: 'blur' }]">
								<el-date-picker v-model="Addjobform.StartTimeDate" value-format="yyyy-MM-dd" type="date"
									placeholder="选择开始日期" :picker-options="StartDateOpt">
								</el-date-picker>
							</el-form-item>
						</el-col>
						<el-col class="line" :span="2">-</el-col>
						<el-col :span="7">
							<el-form-item prop="EndTimeDate"
								:rules="[{required: true,message: '请选择结束日期', trigger: 'blur' },{ validator: checkReleaseTime, trigger: 'blur' }]">
								<el-date-picker v-model="Addjobform.EndTimeDate" value-format="yyyy-MM-dd" type="date"
									placeholder="选择结束日期"
									:picker-options="endDateOpt"><!-- @change="checkReleaseTime" -->
								</el-date-picker>
							</el-form-item>
						</el-col>
					</el-form-item>
					<el-form-item label="工作时间" required>
						<el-col :span="7">
							<el-form-item prop="StartTimeT" :rules="[
                   { type: 'string', required: true, message: '请选择开始时间', trigger: 'change' }]">
								<el-time-picker v-model="Addjobform.StartTimeT" value-format="HH:mm:00" :picker-options="{
                  format:'HH:mm'
                }" type="time" placeholder="选择开始时间">
								</el-time-picker>
							</el-form-item>
						</el-col>
						<el-col class="line" :span="2">-</el-col>
						<el-col :span="7">
							<el-form-item prop="EndTimeT" :rules="[
                   { type: 'string', required: true, message: '请选择结束时间', trigger: 'change' }]">
								<el-time-picker v-model="Addjobform.EndTimeT" value-format="HH:mm:00" :picker-options="{
                  format:'HH:mm'
                }" type="time" placeholder="选择结束时间">
								</el-time-picker>
							</el-form-item>
						</el-col>
					</el-form-item>
					<div style="display: flex;align-items: center;">
						<el-form-item style="margin-bottom: 22px;" label="是否休息" prop="Rest" :rules="[
                { required: true, message: '请选择是否休息', trigger: 'change' }
              ]">
							<el-radio-group v-model="Addjobform.Rest">
								<el-radio label='0'>否</el-radio>
								<el-radio label='1'>是</el-radio>
							</el-radio-group>
						</el-form-item>
						<el-form-item v-show="Addjobform.Rest==1" label="休息时间">
							<el-input type="number" min="0" status-icon v-model.number="Addjobform.RestTime"
								placeholder="请填写休息时间">
							</el-input>/小时
						</el-form-item>
					</div>
					<el-form-item v-show="Addjobform.Rest==1" label="休息备注" prop="Remark">
						<el-input type="textarea" v-model="Addjobform.Remark"></el-input>
					</el-form-item>
					<div style="display: flex;align-items: center;">
						<el-form-item label="所属部门" prop="Dept" :rules="[
              { required: true, message: '请选择所属部门', trigger: 'change' }
            ]">
							<div class="block">
								<el-cascader :options="jobDeptoptions" :props="jobDeptprops" v-model='Addjobform.Dept'
									:show-all-levels="false" @change="jobDepthandleChange" ref="cascader2"
									placeholder="请选择所属部门">
								</el-cascader>
							</div>
						</el-form-item>
						<el-form-item label="岗位类别" prop="JobTypeId" :rules="[
              { required: true, message: '请选择岗位类别', trigger: 'change' }
            ]">
							<el-cascader v-model="Addjobform.JobTypeId" :props="jobTypeprops" :options="jobTypeoptions"
								:show-all-levels="false" placeholder="请选择岗位类别" @change="jobTypeChange" ref="cascader3">
							</el-cascader>
						</el-form-item>
					</div>
					<div>
						<el-form-item style="margin-bottom: 22px;" label="性别要求" prop="sex" :rules="[
                { required: true, message: '请选择性别', trigger: 'change' }
              ]">
							<el-radio-group v-model="Addjobform.sex">
								<el-radio label="0">男</el-radio>
								<el-radio label="1">女</el-radio>
								<el-radio label="2">不限</el-radio>
							</el-radio-group>
						</el-form-item>
						<el-form-item style="margin-bottom: 22px;" label="岗位福利" prop="JobWelfare" :rules="[
                { required: true, message: '请添加岗位福利', trigger: 'blur' }
              ]">
							<el-tag :key="tag" v-for="tag in Addjobform.JobWelfare" closable
								:disable-transitions="false" @close="handleClose(tag)">
								{{tag}}
							</el-tag>
							<el-input class="input-new-tag" v-if="inputVisible" v-model="inputValue"
								placeholder="最长四个字符" ref="saveTagInput" size="small" maxlength="4"
								@keyup.enter.native="handleInputConfirm" @blur="handleInputConfirm">
							</el-input>
							<el-button v-else class="button-new-tag" size="small" @click="showInput">+
								新建福利标签</el-button>
						</el-form-item>
					</div>
					<el-form-item label="岗位描述" prop="jobInfo" :rules="[
            { required: true, message: '请填写岗位描述', trigger: 'blur' }
          ]">
						<div class="editor">
							<quill-editor v-model="Addjobform.jobInfo" ref="myQuillEditor" :options="editorOption"
								@change="onEditorChange($event)" class="editor"
								style="width: 100%;height: 300px;"></quill-editor>
						</div>
					</el-form-item>
				</el-form>
				<div class="footer">
					<el-button @click="AddjobOut('Addjobform')">取 消</el-button>
					<el-button type="primary" @click="AddjobSubmit('Addjobform')">确 定
					</el-button>
				</div>
			</div>
		</el-card>
	</div>
</template>

<script>
	import {
		quillEditor
	} from "vue-quill-editor"; // 导入quillEditor组件
	import 'quill/dist/quill.js';
	import jobTypeDate from '../static/json/JobType.json';
	import address from '../static/json/address.json';
	// let userInfo = JSON.parse(localStorage.getItem('DateInfo'));
	// 工具栏配置
	const toolbarOptions = [
		["bold", "italic", "underline", "strike"], // 加粗 斜体 下划线 删除线 -----['bold', 'italic', 'underline', 'strike']
		["blockquote", "code-block"], // 引用  代码块-----['blockquote', 'code-block']
		[{
			header: 1
		}, {
			header: 2
		}], // 1、2 级标题-----[{ header: 1 }, { header: 2 }]
		[{
			list: "ordered"
		}, {
			list: "bullet"
		}], // 有序、无序列表-----[{ list: 'ordered' }, { list: 'bullet' }]
		[{
			script: "sub"
		}, {
			script: "super"
		}], // 上标/下标-----[{ script: 'sub' }, { script: 'super' }]
		[{
			indent: "-1"
		}, {
			indent: "+1"
		}], // 缩进-----[{ indent: '-1' }, { indent: '+1' }]
		[{
			direction: "rtl"
		}], // 文本方向-----[{'direction': 'rtl'}]
		[{
			size: ["small", false, "large", "huge"]
		}], // 字体大小-----[{ size: ['small', false, 'large', 'huge'] }]
		[{
			header: [1, 2, 3, 4, 5, 6, false]
		}], // 标题-----[{ header: [1, 2, 3, 4, 5, 6, false] }]
		[{
			color: []
		}, {
			background: []
		}], // 字体颜色、字体背景颜色-----[{ color: [] }, { background: [] }]
		[{
			font: []
		}], // 字体种类-----[{ font: [] }]
		[{
			align: []
		}], // 对齐方式-----[{ align: [] }]
		["clean"], // 清除文本格式-----['clean']
		["link"] // 链接、图片、视频-----['link', 'image', 'video']
	];
	export default {
		name: 'JobPostAdd',
		destroyed() {
			//console.log('离开')
		},
		//组件创建
		created() {
			// 企业信息
			this.Company()
			//岗位类别
			this.get_jobType()
			//部门
			this.Dept()

		},
		activated() {
			this.Company()
		},
		components: {
			quillEditor
		},
		data() {
			return {
				CompanyName: '',
				//岗位发布信息
				Addjobform: {
					Rest: '0',
					IsRest: false, //是否休息
					RestTime: null, //休息时间
					Remark: '', //休息备注
					jobName: '', //岗位名称
					jobNumber: '0', //招聘人数
					City: null, //工作城市id
					CityTxt: '', //工作城市
					Area: null, //工作区ID
					AreaTxt: '', //工作区
					ShangQuan: '', //商圈id
					ShangQuanTxt: '', //商圈
					Sub: [],
					Subway: [], //地铁线
					SubwayTxt: [],
					Station: [], //地铁站
					StationTxt: [],
					jobAddress: '', //详细地址
					EndTimeDate: '', //
					StartTimeDate: '', //
					EndTimeT: '', //
					StartTimeT: '', //
					StartTime: '', //开始时间
					EndTime: '', //结束时间
					ContractSalary: '', //合同金额
					Salary: '', //薪资
					Dept: '',
					DeptID: '', //所属部门
					DeptName: '', //所属部门名
					JobTypeId: '', //岗位类别id
					JobTypeParentId: '', //岗位类别父级id
					JobTypeName: '', //岗位类别
					sex: '2', //性别
					jobInfo: '', //岗位描述   富文本编辑器默认内容
					JobWelfare: [], //岗位福利
				},
				// 开始时间
				StartDateOpt: {
					disabledDate: time => {
						// if (this.Addjobform.EndTimeDate) {
						// 	return (time.getTime() < Date.now() - 8.64e7 || time.getTime() < new Date(this.Addjobform
						// 		.EndTimeDate).getTime() - 8.64e7)
						// }
						return time.getTime() < Date.now() - 8.64e7
					}
				},
				//结束时间
				endDateOpt: {
					disabledDate: time => {
						if (this.Addjobform.StartTimeDate) {
							return (time.getTime() < Date.now() - 8.64e7 || time.getTime() < new Date(this.Addjobform
								.StartTimeDate).getTime() - 8.64e7)
							// return time.getTime() < Date.now() - 8.64e7;
						}
						return time.getTime() < Date.now() - 8.64e7;
						// return time.getTime() < Date.now() - 8.64e7;
					}
				},
				CitychildArr: [], //城市
				Addressoptions: [], //地区
				sqoptions: [], //商圈
				// SubwayTxtoptions:[],//地铁
				Subwayoptions: [], //地铁
				Subwayprops: {
					value: 'Id',
					label: 'SubWayName',
					children: 'SubList',
					multiple: true,
					emitPath: false,
					checkStrictly: true
				},
				jobDeptoptions: [], //所有部门
				jobDeptprops: { //树形结构的展示字段
					value: 'id',
					label: 'label',
					children: 'children',
				},
				jobTypeoptions: [], //岗位类别
				jobTypeprops: {
					value: 'Id',
					label: 'Name',
					children: 'JobList'
				},
				inputVisible: false,
				inputValue: '',
				uniqueId: "uniqueId",
				editorOption: {
					//  富文本编辑器配置
					modules: {
						toolbar: toolbarOptions
					},
					theme: "snow",
					placeholder: "请输入岗位描述（岗位职责）"
				}
			}
		},

		methods: {
			checkReleaseTime(rule, value, callback) {
				if (this.Addjobform.StartTimeDate == '') {
					// value = ''
					// this.Addjobform.EndTimeDate = ''
					this.$message.error('开始日期不能为空')
					return
					// return callback(new Error('请先选择开始日期'))
				} 
				// else if (value < this.Addjobform.StartTimeDate) {
				// 	return callback(new Error('结束日期不能在开始日期之前'))
				// } 
				else {
					callback()
				}
			},
			// 企业信息
			Company() {
				var that = this
				let Token = localStorage.getItem('Token')
				this.$axios.defaults.headers.common["x-Token"] = Token
				this.$axios.post('/User/Company').then((res) => {
					// console.log(res)
					// 金额
					this.Addjobform.ContractSalary = res.data.Data.ContractSalary
					this.Addjobform.Salary = res.data.Data.Salary
					// console.log(this.Addjobform.ContractSalary)



					//企业名称
					this.CompanyName = res.data.Data.CompanyName
					//企业城市
					this.Addjobform.City = res.data.Data.City
					this.Addjobform.CityTxt = res.data.Data.CityTxt
					//企业地区
					this.Addjobform.Area = parseInt(res.data.Data.Area)
					this.Addjobform.AreaTxt = res.data.Data.AreaTxt
					//企业商圈
					this.Addjobform.ShangQuan = res.data.Data.SQId
					this.Addjobform.ShangQuanTxt = res.data.Data.SQTxt
					//企业地铁
					this.Addjobform.Subway = res.data.Data.SubWayId
					this.Addjobform.Station = res.data.Data.StationId
					this.Addjobform.SubwayTxt = res.data.Data.SubWayTxt
					this.Addjobform.StationTxt = res.data.Data.StationTxt
					this.Addjobform.Sub = this.Addjobform.Subway.concat(this.Addjobform.Station)
					//企业地址
					this.Addjobform.jobAddress = res.data.Data.Address
					//console.log(this.Addjobform.Sub)
					//console.log(this.Addjobform.Subway,this.Addjobform.Station)



					//获取总城市
					this.Address()
					//获取总地区
					this.change_item(this.Addjobform.City)
					//获取总商圈
					this.sqchange_item(this.Addjobform.City, this.Addjobform.Area)
					//获取总地铁
					this.SubwayList(this.Addjobform.City)


				})
			},
			// 所有部门
			Dept() {
				let that = this
				let Token = localStorage.getItem('Token')
				this.$axios.defaults.headers.common["x-Token"] = Token
				this.$axios.post('/Dept/GetTree', {
					"Data": {
						"Delay": true, //是否延迟加载
						"ParentId": 0, //父级ID
						"IsIncludeSelf": true, //包含自身
						"OnlyLoadPrivilegeOrg": false //是否开启权限过滤
					}
				}).then((res) => {
					if (res.data.Code == 0) {
						// //console.log(res.data)
						this.jobDeptoptions = res.data.Data
					} else {
						if (res.data.Code == 202) {
							that.$router.push("/login");
						} else {
							that.$message({
								type: 'info',
								message: res.data.Message
							})
						}
					}
				})
			},
			//岗位类别
			get_jobType() {
				var that = this
				let Token = localStorage.getItem('Token')
				this.$axios.defaults.headers.common["x-Token"] = Token
				this.$axios.post('https://systemapi.61msh.com/City/JobType', {
					"Data": {
						"Type": 1
					}
				}).then((res) => {
					// //console.log(res)
					this.jobTypeoptions = res.data.Data.JobTypeList
				})
			},
			//城市
			Address() {
				var that = this
				let Token = localStorage.getItem('Token')
				this.$axios.defaults.headers.common["x-Token"] = Token
				this.$axios.post('https://systemapi.61msh.com/City/City', {
					"Data": {
						"Type": 1
					}
				}).then((res) => {
					// //console.log(res)
					// 一级分类的数据源
					this.CitychildArr = res.data.Data.CityList
				})
			},
			CityChange(e) {
				// //console.log(e)
				let r = []
				r = this.CitychildArr.find((item) => {
					return item.Id === e
				})
				// //console.log(r)
				this.Addjobform.City = e
				this.Addjobform.CityTxt = r.CityName
				// //console.log(this.Addjobform.City, this.Addjobform.CityTxt)
				// this.change_item(this.Addjobform.City)
			},
			//区域
			change_item(Id) {
				// //console.log(Id)
				var that = this
				let Token = localStorage.getItem('Token')
				this.$axios.defaults.headers.common["x-Token"] = Token
				this.$axios.post('https://systemapi.61msh.com/City/Area', {
					"Data": {
						"CityID": Id, //城市ID
						"Type": 1
					}
				}).then((res) => {
					// //console.log(res)
					that.Addressoptions = res.data.Data.AreaList
				})
			},
			AreaChange(e) {
				// //console.log(e)
				let r = []
				r = this.Addressoptions.find((item) => {
					return item.Id === e
				})
				this.Addjobform.AreaTxt = r.AreaName
				// //console.log(this.Addjobform.Area, this.Addjobform.AreaTxt)
				this.sqchange_item(this.Addjobform.City, this.Addjobform.Area)

			},
			//商圈
			sqchange_item(CityID, Id) {
				var that = this
				let Token = localStorage.getItem('Token')
				this.$axios.defaults.headers.common["x-Token"] = Token
				this.$axios.post('https://systemapi.61msh.com/City/SQ', {
					"Data": {
						"CityID": CityID, //城市ID
						"AreaID": Id, //区域ID
						"Type": 1
					}
				}).then(res => {
					// //console.log(res)
					that.sqoptions = res.data.Data.SQList
				})
			},
			ShangQuanChange(e) {
				// //console.log(e)
				let r = []
				for (let i = 0; i <= e.length - 1; i++) {
					this.sqoptions.find((item) => {
						if (item.Id == e[i]) {
							r.push(item.SQName)
						}
					})
				}
				// //console.log(r)
				this.Addjobform.ShangQuan = e
				this.Addjobform.ShangQuanTxt = r
				// //console.log(this.Addjobform.ShangQuan, this.Addjobform.ShangQuanTxt)
			},
			//地铁
			SubwayList(Id) {
				// //console.log(Id)
				var that = this
				let Token = localStorage.getItem('Token')
				this.$axios.defaults.headers.common["x-Token"] = Token
				this.$axios.post('https://systemapi.61msh.com/City/All', {
					"Data": {
						"CityID": Id, //城市ID
						"Type": 1
					}
				}).then((res) => {
					// //console.log(res)
					that.Subwayoptions = res.data.Data.SubList
				})
			},
			SubwayChange(e) {
				// //console.log(e)
				var Nodes = this.$refs['SubwayRef'].getCheckedNodes()
				// //console.log(Nodes)
				var arr = []
				var arrlist = []
				var t = []
				var tli = []
				for (let i in Nodes) {
					if (Nodes[i].path[1] == undefined) {
						arr.push(Nodes[i].path[0])
						t.push(Nodes[i].pathLabels[0])
					} else {
						arr.push(Nodes[i].path[0]) //总地铁线id
						arrlist.push(Nodes[i].path[1]) //总地铁站id
						t.push(Nodes[i].pathLabels[0]) //总地铁线
						tli.push(Nodes[i].pathLabels[1]) //总地铁站
					}

				}
				this.Addjobform.Subway = [...new Set(arr)] //去重后的地铁线id
				this.Addjobform.Station = [...new Set(arrlist)] //去重后的地铁站id
				this.Addjobform.SubwayTxt = [...new Set(t)] //去重后的地铁线
				this.Addjobform.StationTxt = [...new Set(tli)] //去重后的地铁站
				// //console.log(this.Addjobform.Subway, this.Addjobform.Station, this.Addjobform.SubwayTxt, this.Addjobform
				//   .StationTxt)
				//   //console.log(this.Addjobform.Sub)
			},
			// 获取部门名
			jobDepthandleChange(value) {
				//console.log(value)
				//console.log(value[0])
				// //console.log(value[1])
				let nodesInfo = this.$refs['cascader2'].getCheckedNodes()
				//console.log(nodesInfo)
				if (value[1] == undefined) {
					this.Addjobform.DeptID = value[0]
				} else {
					this.Addjobform.DeptID = value[1]
				}
				this.Addjobform.DeptName = nodesInfo[0].label
				//console.log(this.Addjobform.DeptID, this.Addjobform.DeptName)

				// let nodesInfo = this.$refs['cascader2'].getCheckedNodes()
				// //console.log(nodesInfo)
				// this.Addjobform.DeptName = nodesInfo[0].label
				// this.Addjobform.DeptID = value[1]
				// //console.log(this.Addjobform.DeptID,this.Addjobform.DeptName)
			},
			//岗位类别
			jobTypeChange(value) {
				//console.log(value)
				let nodesInfo = this.$refs['cascader3'].getCheckedNodes()
				//console.log(nodesInfo)
				if (value.length == 1) {
					this.Addjobform.JobTypeId = value[0] //岗位类别id
					// this.Addjobform.JobTypeParentId = null //岗位类别父级id
					this.Addjobform.JobTypeName = nodesInfo[0].label //岗位类别
				} else {
					this.Addjobform.JobTypeId = value[1] //岗位类别id
					this.Addjobform.JobTypeParentId = nodesInfo[0].pathNodes[0].level //岗位类别父级id
					this.Addjobform.JobTypeName = nodesInfo[0].label //岗位类别
				}
				//console.log(this.Addjobform.JobTypeId, this.Addjobform.JobTypeParentId, this.Addjobform.JobTypeName)
			},
			//岗位福利
			//移除标签
			handleClose(tag) {
				this.Addjobform.JobWelfare.splice(this.Addjobform.JobWelfare.indexOf(tag), 1);
			},
			//添加标签input
			showInput() {
				this.inputVisible = true;
				this.$nextTick(_ => {
					this.$refs.saveTagInput.$refs.input.focus();
				});
			},
			// 添加标签  list
			handleInputConfirm() {
				let inputValue = this.inputValue;
				if (inputValue) {
					if (this.Addjobform.JobWelfare.length > 3) {
						this.$message({
							showClose: true,
							message: '标签最多四个哦',
							type: 'warning'
						})
					} else {
						this.Addjobform.JobWelfare.push(inputValue);
					}
				}
				this.inputVisible = false;
				this.inputValue = '';
			},

			//内容改变事件
			onEditorChange({
				quill,
				html,
				text
			}) {
				// //console.log('editor change!', quill, html, text)
				this.Addjobform.jobInfo = html
			},
			//取消发布
			AddjobOut(formName) {
				//console.log(this.Addjobform)
				this.$refs[formName].resetFields();
				this.Addjobform.RestTime = null
				//console.log(this.Addjobform)
				this.$router.push("/Jobposting");
				// this.
				// this.$refs[formName].validate((valid) => {
				//   if (valid) {
				//     this.$refs[formName].resetFields();
				//     //跳转到发布岗位页面
				//     setTimeout(() => {
				//       this.$router.push("/Jobposting");
				//     }, 1000)
				//     // alert('submit!');
				//     // //console.log(this.Addjobform)
				//   } else {
				//     //console.log('error submit!!');
				//     return false;
				//   }
				// })
			},
			//是否休息
			Restchange(e) {
				//console.log(e)
			},
			//提交发布
			AddjobSubmit(formName) {
				this.Addjobform.StartTime = this.Addjobform.StartTimeDate + ' ' + this.Addjobform.StartTimeT
				this.Addjobform.EndTime = this.Addjobform.EndTimeDate + ' ' + this.Addjobform.EndTimeT
				if (this.Addjobform.Rest == '1') {
					this.Addjobform.IsRest = true
				} else {
					this.Addjobform.IsRest = false
					this.Addjobform.RestTime = null
				}
				//console.log(this.Addjobform)

				// if (this.Addjobform.StartTimeDate > this.Addjobform.EndTimeDate) {
				// 	this.$message.error('结束日期不能在开始日期之前')
				// 	this.Addjobform.EndTimeDate = ''
				// 	return false
				// }


				this.$refs[formName].validate((valid) => {
					if (valid) {
						// alert('submit!');
						// //console.log(this.Addjobform)
						let that = this
						let Token = localStorage.getItem('Token')
						this.$axios.defaults.headers.common["x-Token"] = Token
						this.$axios.post('/JobManage/Job/Add', {
							"Data": {
								"Subway": this.Addjobform.Subway,
								"SubwayTxt": this.Addjobform.SubwayTxt,
								"Station": this.Addjobform.Station,
								"StationTxt": this.Addjobform.StationTxt,
								"CompanyName": this.CompanyName,
								"JobName": this.Addjobform.jobName,
								"JobCount": this.Addjobform.jobNumber,
								"DeptID": this.Addjobform.DeptID,
								"DeptName": this.Addjobform.DeptName,
								"JobTypeId": this.Addjobform.JobTypeId,
								"JobTypeParentId": this.Addjobform.JobTypeParentId,
								"JobTypeName": this.Addjobform.JobTypeName,
								"JobSexNeed": this.Addjobform.sex,
								"JobNeedDes": this.Addjobform.jobInfo,
								"JobWelfare": this.Addjobform.JobWelfare,
								"City": this.Addjobform.City,
								"CityTxt": this.Addjobform.CityTxt,
								"Area": this.Addjobform.Area,
								"AreaTxt": this.Addjobform.AreaTxt,
								"SQId": this.Addjobform.ShangQuan,
								"SQTxt": this.Addjobform.ShangQuanTxt,
								"StartTime": this.Addjobform.StartTime,
								"EndTime": this.Addjobform.EndTime,
								"Salary": this.Addjobform.Salary,
								"Address": this.Addjobform.jobAddress,
								"IsRest": this.Addjobform.IsRest,
								"RestTime": this.Addjobform.RestTime,
								"Remark": this.Addjobform.Remark,
								// "Education":this.Addjobform.Education,
								"ContractSalary": this.Addjobform.ContractSalary,
								"Salary": this.Addjobform.Salary
							}
						}).then((res) => {
							if (res.data.Code == 0) {
								//console.log(res.data)
								that.$message({
									type: 'success',
									message: res.data.Message
								})

								that.$refs[formName].resetFields();
								that.Addjobform.RestTime = null
								//跳转到发布岗位页面
								that.$router.push("/Jobposting");
								// setTimeout(() => {

								// }, 1000)
							} else {
								if (res.data.Code == 202) {
									that.$router.push("/login");
								} else {
									that.$message({
										type: 'info',
										message: res.data.Message
									})
								}

							}
						})
					} else {
						// //console.log('error submit!!');
						return false;
					}
				})
			}
		}
	}
</script>

<style lang="less" scoped>
	.el-cascader .el-input .el-input__inner:focus,
	.el-cascader .el-input.is-focus .el-input__inner {
		height: 33px; //这里高度根据需求自己设定
	}

	.el-cascader__tags {
		display: inline-flex;
		margin-right: 60px;
		flex-wrap: nowrap;
	}

	//福利标签
	.el-tag+.el-tag {
		margin-left: 10px;
	}

	.button-new-tag {
		margin-left: 10px;
		height: 32px;
		line-height: 30px;
		padding-top: 0;
		padding-bottom: 0;
	}

	.input-new-tag {
		width: 90px;
		margin-left: 10px;
		vertical-align: bottom;
	}

	/* 头部标题 */
	.header {
		align-items: center;
		justify-content: space-between;
	}

	.demo-input-suffix {
		display: flex;
	}

	.el-breadcrumb__item {
		display: flex;
	}

	.el-input {
		width: 70%;
	}

	.el-col .el-input {
		width: 100%;
	}

	.line {
		text-align: center;
	}

	// .number{
	//   display: flex;
	// }
	.container {
		width: 60%;
		height: auto;
		background-color: #fff;
		// position: absolute;
		// left: 0;
		// right: 0;
		// top: 0;
		// bottom: 0;
		margin: auto;
		padding: 30px;
		border-radius: 6px;
	}

	.footer {
		// margin-top: 100px;
		text-align: center;
		// display: flex;
		// justify-content: center;
		// align-content: center;
	}
</style>