<template>
	<div class="Forget-wrap">
		<el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm Forget-form">
			<h2 style="width: 100%;margin-bottom: 22px;text-align: center;">修改密码</h2>
			<el-form-item label="旧密码" prop="pas">
				<el-input type="password" v-model="ruleForm.pas" show-password></el-input>
			</el-form-item>
			<el-form-item label="密码" prop="pass">
				<el-input type="password" v-model="ruleForm.pass" autocomplete="off" show-password></el-input>
			</el-form-item>
			<el-form-item label="确认密码" prop="checkPass">
				<el-input type="password" v-model="ruleForm.checkPass" autocomplete="off" show-password></el-input>
			</el-form-item>
			<el-form-item>
				<el-button type="primary" @click="submitForm('ruleForm')">提交</el-button>
				<el-button @click="resetForm()">返回</el-button>
			</el-form-item>
		</el-form>
	</div>
</template>
<script>
	export default {
		name: "Forget",
		data() {
			var checkpas = (rule, value, callback) => {
				if (value === '') {
					callback(new Error('请输入旧密码'));
				} else if (value.length < 6) {
					callback(new Error('密码不能少于6位数'));
				} else {
					callback();
				}
			};
			var validatePass = (rule, value, callback) => {
				if (value === '') {
					callback(new Error('请输入密码'));
				} else if (value.length < 6) {
					callback(new Error('密码不能少于6位数'));
				} else {
					if (this.ruleForm.checkPass !== '') {
						this.$refs.ruleForm.validateField('checkPass');
					}
					callback();
				}
			};
			var validatePass2 = (rule, value, callback) => {
				if (value === '') {
					callback(new Error('请再次输入密码'));
				} else if (value !== this.ruleForm.pass) {
					callback(new Error('两次输入密码不一致!'));
				} else {
					callback();
				}
			};
			return {
				ruleForm: {
					pass: '',
					checkPass: '',
					pas: ''
				},
				rules: {
					pass: [{
						validator: validatePass,
						trigger: 'blur'
					}],
					checkPass: [{
						validator: validatePass2,
						trigger: 'blur'
					}],
					pas: [{
						validator: checkpas,
						trigger: 'blur'
					}]
				}
			};
		},
		methods: {
			// 修改密码
			submitForm(formName) {
				this.$refs[formName].validate((valid) => {
					if (valid) {
						// this.$router.push('/login')
						let User = JSON.parse(localStorage.getItem('DateInfo')).User.Id
						console.log(User)
						let Token = localStorage.getItem('Token')
						var that = this
						this.$axios.defaults.headers.common["x-Token"] = Token
						this.$axios.post('/User/ModifyPwd', {
							"Data": {
								"ID": User,
								"OldPassword": that.ruleForm.pas,
								"NewPassword": that.ruleForm.pass,
								"ReNewPassword": that.ruleForm.checkPass,
							}
						}).then(function(res) {
							if (res.data.Code == 0) {
								console.log(res.data)
								that.$message({
									showClose: true,
									message: res.data.Message,
									type: 'success'
								});
								// 删除保存的token以及user
								localStorage.clear()
								setTimeout(() => {
									that.$router.push('/login')
								}, 1000)
							}else{
								that.$message({
									showClose: true,
									message: res.data.Message,
									type: 'error'
								});
							}
						})
					} else {
						console.log('error submit!!');
						return false;
					}
				});
			},
			// 返回上一页
			resetForm() {
				this.$router.back()
			}
		}
	};
</script>
<style scoped>
	.Forget-wrap {
		height: 100%;
		background: #324152;
		display: flex;
		justify-content: center;
		align-items: center;
	}

	.Forget-form {
		width: 400px;
		background: #fff;
		border-radius: 5px;
		padding: 30px;
	}

	.el-input__suffix {
		display: flex;
	}
</style>
