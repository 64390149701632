import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'

import './plugins/element.js'
// 引入全局样式
import './assets/css/global.css'
// 引入字体图标
import 'font-awesome/css/font-awesome.min.css'

import axios from 'axios';


// 引入富文本编辑器
import VueQuillEditor from 'vue-quill-editor'
// require styles 引入样式
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
Vue.use(VueQuillEditor)



// axios.defaults.baseURL = 'http://192.168.1.22:8001/'
// http://123.60.25.216:8082/file
axios.defaults.baseURL = 'https://companyapi.61msh.com'
Vue.prototype.$axios = axios;

Vue.use(ElementUI)

import SlideVerify from 'vue-monoplasty-slide-verify';

Vue.use(SlideVerify);




Vue.config.productionTip = false

new Vue({
  router,
  store, //store:store 和router一样，将创建的Vuex实例挂载到这个vue实例中
  render: h => h(App)
}).$mount('#app')
