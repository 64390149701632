<template>
  <div id="Oddjobinformation">
    <el-card>
      <div class="demo-input-suffix header">
        <el-breadcrumb separator="/">
          <el-breadcrumb-item><i class="el-icon-date"></i> 首页</el-breadcrumb-item>
          <el-breadcrumb-item>零工信息</el-breadcrumb-item>
        </el-breadcrumb>
        <div class="demo-input-suffix">
          <el-select v-model="Oddjob" placeholder="请选择" style="margin-right: 10px;" @change="StatusValueChange">
            <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
          <el-input class="seachInpt" placeholder="请输入搜索关键字" v-model="search" clearable @change='NameChange'>
          </el-input>
          <el-button size="mini" type="primary" @click='searchange'>
            <i class="el-icon-search"></i>
            搜索
          </el-button>
        </div>
      </div>
      <el-table :data="tableData" style="width: 100%;margin-bottom: 70px;" ref="multipleTable" tooltip-effect="dark" :border="true">
        <el-table-column type="index" width="50"><!-- :index="indexMethod" -->
        </el-table-column>
        <el-table-column label="头像" prop="Avatar" width="110">
          <template slot-scope="scope">
            <el-image style="width: 90px;height: 90px;" :src="scope.row.Avatar" fit="scale-down">
              <div slot="error" class="image-slot">
                <i class="el-icon-picture-outline"></i>
              </div>
            </el-image>
          </template>
        </el-table-column>
        <el-table-column label="部门" prop="DeptName" width="100">
          <template slot-scope="scope">
            <span
              style="width: 100px;white-space: nowrap;text-overflow: ellipsis;overflow: hidden;">{{scope.row.DeptName}}</span>
          </template>
        </el-table-column>
        <el-table-column label="岗位" prop="JobName" width="100">
          <template slot-scope="scope">
            <span
              style="width: 100px;white-space: nowrap;text-overflow: ellipsis;overflow: hidden;">{{scope.row.JobName}}</span>
          </template>
        </el-table-column>
        <el-table-column label="元/小时" prop="JobScalary" width="100">
        </el-table-column>
        <el-table-column label="用户名" prop="CUserName" width="100">
          <template slot-scope="scope">
            <el-tag size="medium" @click="CUserInfo(scope.row.CUserID)">{{ scope.row.CUserName }}</el-tag>
          </template>
          <el-dialog title="个人简历" :visible.sync="CUserDialogin" center :append-to-body='true' :lock-scroll="false"
            width="40%">
            <div class="Information">
              <div class="warp">
                <div class="img">
                  <!-- <img src="../assets/images/index/user1.png" /> -->
                  <img :src="Cuserform.HeadPic" />
                </div>
                <div class="icon">
                  <p class="tit">基本信息</p>
                </div>
                <div class="Basic_info">
                  <div><label>姓名 ：</label>{{Cuserform.UserName||''}}</div>
                  <div><label>性别 ：</label>{{Cuserform.Gender||''}}</div>
                  <div><label>学历 ：</label>{{Cuserform.EducationTxt||''}}</div>
                  <div><label>工作年限 ：</label>{{Cuserform.WorkExpTxt||''}}</div>
                  <div><label>求职状态 ：</label>{{Cuserform.JobStatusTxt||''}}</div>
                </div>
              </div>
              <div class="warp">
                <!-- v-show="GetUserAll.JobTypeNames.length!=0" -->
                <div class="icon">
                  <p class="tit">求职意向</p>
                </div>
                <div class="Basic_info Careerjob">
                  <div>
                    <label>期望岗位</label>
                    <div>
                      <div class="title" v-for="(item1,index1) in GetUserAll.JobTypeNames" :key="index1">
                        {{item1}}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="warp">
                <!-- v-show="GetUserAll.Tags.length!=0" -->
                <div class="icon">
                  <p class="tit">个性标签</p>
                </div>
                <div class="Basic_info ">
                  <text class="title" v-for="(item,index) in GetUserAll.Tags" :key="index">
                    {{item}}
                  </text>
                </div>
              </div>
              <div class="warp">
                <!-- v-show="GetUserAll.cCert.length!=0" -->
                <div>
                  <div class="icon">
                    <p class="tit">资格证书</p>
                  </div>
                  <div>
                    <div class="Basic_info certificate" v-for="(item2,index2) in GetUserAll.cCert" :key="index2">
                      <div style="display:flex;align-items: center;position: relative;">
                        <label>证书名称 </label>
                        <div class="title" v-if="item2.certType==1">健康证</div>
                        <div class="title" v-else-if="item2.certType==2">毕业证</div>
                        <div class="title" v-else-if="item2.certType==3">健身教练证</div>
                        <div class="title" v-else-if="item2.certType==4">厨师职业资格证</div>
                        <div class="title" v-else-if="item2.certType==5">会计从业资格证</div>
                        <div class="title" v-else-if="item2.certType==6">电工证</div>
                        <div class="title" v-else-if="item2.certType==7">技工证</div>
                        <div class="title" v-else-if="item2.certType==8">其他</div>
                      </div>
                      <div><label>有效时间 </label> {{item2.startDate.substring( 0,item2.startDate.indexOf(' ') )}} 至
                        {{item2.endDate.substring( 0,item2.endDate.indexOf(' ') )}}
                      </div>
                      <div>
                        <label>证书</label>
                        <img :src="item2.FileUrlRight" style="width: 100rpx;height: 80rpx;" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div slot="footer" class="dialog-footer">
              <el-button @click="CUserDialogin = false">关 闭</el-button>
            </div>
          </el-dialog>
        </el-table-column>
        <el-table-column label="性别" prop="Gender" width="50">
          <!-- <template slot-scope="scope">
            <span v-show="scope.row.Gender==1">女</span>
            <span v-show="scope.row.Gender==0">男</span>
          </template> -->
        </el-table-column>
        <el-table-column label="年龄" prop="Age" width="50"></el-table-column>
        <el-table-column label="班次" width="290">
          <template slot-scope="scope">
            <el-tag size="medium" v-if="scope.row.ClassesStartTime!=null">
              {{ scope.row.ClassesStartTime.replace(' ',':').replace(/\:/g,"-").split('-')[0]+"/"+scope.row.ClassesStartTime.replace(' ',':').replace(/\:/g,"-").split('-')[1]+"/"+scope.row.ClassesStartTime.replace(' ',':').replace(/\:/g,"-").split('-')[2]}}
              ­­­­－
              {{scope.row.ClassesEndTime.replace(' ',':').replace(/\:/g,"-").split('-')[0]+"/"+scope.row.ClassesEndTime.replace(' ',':').replace(/\:/g,"-").split('-')[1]+"/"+scope.row.ClassesEndTime.replace(' ',':').replace(/\:/g,"-").split('-')[2]}}
              ,
              {{ scope.row.ClassesStartTime.replace(' ',':').replace(/\:/g,"-").split('-')[3]+":"+scope.row.ClassesStartTime.replace(' ',':').replace(/\:/g,"-").split('-')[4]}}
              ­－­
              {{scope.row.ClassesEndTime.replace(' ',':').replace(/\:/g,"-").split('-')[3]+":"+scope.row.ClassesEndTime.replace(' ',':').replace(/\:/g,"-").split('-')[4]}}
            </el-tag>
            <span v-else></span>
          </template>
        </el-table-column>
        <el-table-column label="创建时间" prop="CreatedTime" width="140"></el-table-column>
        <el-table-column label="状态" prop="StatusTxt"></el-table-column>
        <el-table-column align="right" width="180">
          <template slot="header" slot-scope="scope">
            <label>操作</label>
          </template>
          <template slot-scope="scope">
            <el-button type="success" size="mini" @click="ChangeWorkClass(scope.row)">分配班次</el-button>
            <el-dialog title="分配班次" :visible.sync="ChangeWorkDialogin" center :append-to-body='true'
              :lock-scroll="false" width="40%">
              <el-checkbox-group v-model="checkedCities" :max="1">
                <el-checkbox v-for="city in cities" :label="city.Value" :key="city.Value">{{city.Name}}</el-checkbox>
              </el-checkbox-group>
              <div slot="footer" class="dialog-footer">
                <el-button @click="ChangeWorkDialogin = false">取 消</el-button>
                <el-button type="primary" @click="checkedChange">确 定</el-button>
              </div>
            </el-dialog>
            <el-button size="mini" type="danger" style="margin-left: 10px;" v-show="scope.row.ClassesStartTime!=null"
              @click="Export(scope.row.ID)">签出</el-button>
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页 -->
      <div style="width: 300px;margin: 0 auto;">
        <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="currentPage"
          :page-sizes="[10, 20, 30, 40]" :page-size="pagesize" layout="total, sizes, prev, pager, next, jumper"
          :total="TotalRecordQty">
        </el-pagination>
      </div>
    </el-card>
  </div>
</template>
<script>
  export default {
    name: 'Oddjobinformation',
    //组件创建
    created() {
      this.PersonList()
    },
    mounted() {},
    data() {
      return {
        Oddjob: 1, //审核状态
        options: [{
          value: 1,
          label: '签入'
        }, {
          value: 2,
          label: '签出'
        }],
        tableData: [],
        search: '',
        TotalRecordQty: 10, //总条数
        currentPage: 1, //初始页
        pagesize: 10, //每页的数据
        multipleSelection: [], //选中用户复选框
        disRese: false, //批量删除按钮
        reseId: [], //删除用户id

        ChangeWorkDialogin: false, //分配班次
        cities: [],
        checkedCities: [],
        ClassID: null,
        form: [],

        CUserDialogin: false, //个人简历
        GetUserAll: [],
        Cuserform: [], //基本信息

      }
    },
    methods: {
      //个人简历
      CUserInfo(id) {
        this.CUserDialogin = true
        let that = this
        let Token = localStorage.getItem('Token')
        this.$axios.defaults.headers.common["x-Token"] = Token
        this.$axios.post('/JobManage/JobApply/Cuser', {
          "Data": {
            "CuserId": id
          }
        }).then((res) => {
          if (res.data.Code == 0) {
            console.log(res.data)
            // that.$message({
            //   type: 'success',
            //   message: res.data.Message
            // })
            that.GetUserAll = res.data.Data
            that.Cuserform = res.data.Data.cUser
          } else {
            that.$message({
              type: 'info',
              message: res.data.Message
            })
          }
        })
      },
      //状态
      StatusValueChange(e) {
        console.log(e)
        this.Oddjob = e
      },
      //获取关键字
      NameChange(e) {
        // console.log(e)
        this.search = e
      },
      //搜索
      searchange() {
        this.PersonList()
      },
      // 序号
      indexMethod(index) {
        if (this.currentPage == 1) {
          return index * 1 + 1;
        } else {
          return (index + this.currentPage - 1) * 10 + 1
        }
      },
      // 初始页currentPage、初始每页数据数pagesize和数据data
      handleSizeChange: function(size) {
        this.pagesize = size;
        this.PersonList()
        // console.log(this.pagesize) //每页下拉显示数据
      },
      handleCurrentChange: function(currentPage, index) {
        this.currentPage = currentPage;
        this.PersonList()
        console.log(this.currentPage, index) //点击第几页
      },
      // 选中复选框
      handleSelectionChange(val) {
        this.multipleSelection = val;
        console.log(this.multipleSelection)
        console.log(this.multipleSelection.length)
        if (val.length > 0) {
          this.disRese = true
          return
        }
      },
      // 零工列表
      PersonList() {
        let that = this
        let Token = localStorage.getItem('Token')
        this.$axios.defaults.headers.common["x-Token"] = Token
        this.$axios.post('/JobManage/Person/List', {
          "Data": {
            "QST": this.search,
            "Status": this.Oddjob,
            "SortDirection": 1,
            "PageNO": this.currentPage,
            "PageSize": this.pagesize
          }
        }).then((res) => {
          if (res.data.Code == 0) {
            console.log(res.data)
            // that.$message({
            //   type: 'success',
            //   message: res.data.Message
            // })
            that.tableData = res.data.Data.Items
            that.TotalRecordQty = res.data.Data.TotalRecordQty
          } else {
            that.$message({
              type: 'info',
              message: res.data.Message
            })
          }
        })
      },
      //分配班次
      ChangeWorkClass(item) {
        this.ChangeWorkDialogin = true
        this.form = item
        if (item.ClassesID == null) {
          this.checkedCities = []
        } else {
          this.checkedCities = [item.ClassesID]
        }
        console.log(this.checkedCities)
        this.WorkClassAll()
      },
      //所有班次
      WorkClassAll() {
        let that = this
        let Token = localStorage.getItem('Token')
        this.$axios.defaults.headers.common["x-Token"] = Token
        this.$axios.post('/WorkAttendance/WorkClass/All', {
          "Data": {

          }
        }).then((res) => {
          if (res.data.Code == 0) {
            console.log(res.data)
            that.cities = res.data.Data
          } else {
            that.$message({
              type: 'info',
              message: res.data.Message
            })
          }
        })
      },
      //班次id
      checkedChange() {
        console.log(this.form)
        console.log(this.checkedCities)
        // JobManage.Person.ChangeWorkClass
        let that = this
        let Token = localStorage.getItem('Token')
        this.$axios.defaults.headers.common["x-Token"] = Token
        this.$axios.post('/JobManage/Person/ChangeWorkClass', {
          "Data": {
            "PersonID": this.form.ID,
            "CUserID": this.form.CUserID,
            "ClassID": this.checkedCities[0]
          }
        }).then((res) => {
          if (res.data.Code == 0) {
            console.log(res.data)
            that.ChangeWorkDialogin = false
            that.PersonList()
          } else {
            that.$message({
              type: 'info',
              message: res.data.Message
            })
          }
        })
      },
      //签出
      Export(ID) {
        let that = this
        let Token = localStorage.getItem('Token')
        this.$axios.defaults.headers.common["x-Token"] = Token
        this.$axios.post('/JobManage/Person/Export', {
          "Data": {
            "PersonID": ID
          }
        }).then((res) => {
          if (res.data.Code == 0) {
            console.log(res.data)
            that.PersonList()
          } else {
            that.$message({
              type: 'info',
              message: res.data.Message
            })
          }
        })
      }
    }
  }
</script>
<style>
  .el-table th>.cell {
    text-align: center;
  }

  .warp {
    /* background-color: #fff; */
    margin-bottom: 5px;
    padding: 10px 20px 20px;
  }

  .warp .icon {
    /* display: flex;
    align-items: center;
    justify-content: space-between; */
    margin-bottom: 10px;
  }

  .warp .icon .tit {
    color: #6699CC;
  }


  /* 基础信息 */
  .Information .img {
    text-align: center;
    margin-bottom: 30px;
  }

  .Information .img img {
    width: 120px;
    max-height: 120px;
    /* border-radius: 50%; */
  }

  .Basic_info {
    font-size: 14px;
    line-height: 2em;
  }

  div label {
    display: inline-block;
    width: 80px;
    height: 30px;
    line-height: 30px;
    vertical-align: middle;
    text-align: justify;
    text-align-last: justify;
  }

  .title {
    height: 30px;
    background-color: #F8F8F8;
    color: #7A7E83;
    text-align: center;
    display: inline-block;
    margin: 5px;
    border-radius: 6px;
    padding: 0px 20px;

  }

  .Careerjob,
  .certificate label {
    margin-right: 20px;
  }

  .certificate {
    border-bottom: 1px dashed #ccc;
    padding: 7px 0;
    height: 171rpx;
  }

  /* 分页 */
  .center {
    position: fixed;
    bottom: 4%;
    width: 100%;
    left: 44%;
  }

  .demo-table-expand {
    font-size: 0;
  }

  .demo-table-expand label {
    width: 90px;
    color: #99a9bf;
  }

  .demo-table-expand .el-form-item {
    margin-right: 0;
    margin-bottom: 0;
    width: 50%;
  }

  /* 头部标题 */
  .header {
    align-items: center;
    justify-content: space-between;
  }

  .demo-input-suffix {
    display: flex;
  }

  .el-breadcrumb__item {
    display: flex;
  }

  /* 搜索框 */
  .seachInpt {
    margin-right: 10px;
  }

  .el-checkbox {
    display: block;
    line-height: 3em;
  }
</style>
