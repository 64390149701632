<template>
	<div id="CorporateSector">
		<el-card>
			<div class="demo-input-suffix header">
				<el-breadcrumb separator="/">
					<el-breadcrumb-item><i class="el-icon-date"></i>系统管理</el-breadcrumb-item>
					<el-breadcrumb-item>企业部门</el-breadcrumb-item>
				</el-breadcrumb>
			</div>
			<div class="container">
				<el-tree :data="CorporateList" node-key="id" default-expand-all :expand-on-click-node="false">
					<span class="custom-tree-node" slot-scope="{ node, data }">
						<span>{{ node.label }}</span>
						<span>
							<el-button type="text" size="mini" @click="() => append(data)" icon="el-icon-plus">
								添加
							</el-button>
							<el-dialog title="新增部门" :visible.sync="dialogFormVisible" width="30%" center
								:append-to-body='true' :lock-scroll="false">
								<el-form :model="form" ref="form">
									<el-form-item label="部门" prop="addName" :rules="[
              				{ required: true, message: '请输入部门', trigger: 'blur' }
              			]">
										<el-input v-model="form.addName" autocomplete="off"></el-input>
									</el-form-item>
									<el-form-item label="备注" prop="addFlag">
										<el-input v-model="form.addFlag" autocomplete="off"></el-input>
									</el-form-item>
								</el-form>
								<div slot="footer" class="dialog-footer">
									<el-button @click="dialogFormVisible = false">取 消</el-button>
									<el-button type="primary" @click="AddDepart('form')">确 定</el-button>
								</div>
							</el-dialog>
							<el-button type="text" size="mini" @click="() => edit(node, data)" icon="el-icon-edit">
								编辑
							</el-button>
							<el-dialog title="修改部门" :visible.sync="EditdialogFormVisible" width="30%" center
								:append-to-body='true' :lock-scroll="false">
								<el-form :model="EditForm" ref="EditForm">
									<el-form-item label="部门" prop="EditName" :rules="[
              				{ required: true, message: '请输入部门', trigger: 'blur' }
              			]">
										<el-input v-model="EditForm.EditName" autocomplete="off"></el-input>
									</el-form-item>
									<el-form-item label="备注" prop="EditFlag">
										<el-input v-model="EditForm.EditFlag" autocomplete="off"></el-input>
									</el-form-item>
								</el-form>
								<div slot="footer" class="dialog-footer">
									<el-button @click="EditdialogFormVisible = false">取 消</el-button>
									<el-button type="primary" @click="EditDepart('EditForm')">确 定</el-button>
								</div>
							</el-dialog>
							<el-button v-if="data.children == null" type="text" size="mini"
								@click="() => remove(node, data,)" icon="el-icon-delete">
								删除
							</el-button>
						</span>
					</span>
				</el-tree>
			</div>
		</el-card>
	</div>
</template>

<script>
	let id = 1000;
	export default {
		name: 'CorporateSector',
		//组件创建
		created() {
			this.CorporateSectorList()
		},
		mounted() {},
		data() {
			return {
				CorporateList: [], //部门列表
				dialogFormVisible: false, //新增部门
				form: {
					addName: '', //部门名
					addFlag: '', //备注
				},
				ParentId: '', //添加的父级id
				EditdialogFormVisible: false, //修改部门
				EditForm: {
					EditName: '', //部门名
					EditFlag: '', //备注
				},
				EditId: '', //修改的id
				EditParentId: '', //修改的父级id
			}
		},
		methods: {
			// 获取企业部门
			CorporateSectorList() {
				let that = this
				let Token = localStorage.getItem('Token')
				this.$axios.defaults.headers.common["x-Token"] = Token
				this.$axios.post('/Dept/GetTree', {
					"Data": {
						"Delay": true, //是否延迟加载
						"ParentId": 0, //父级ID
						"IsIncludeSelf": false, //包含自身
						"OnlyLoadPrivilegeOrg": false //是否开启权限过滤
					}
				}).then((res) => {
					if (res.data.Code == 0) {
						// console.log(res.data)
						this.CorporateList = res.data.Data
					} else {
						that.$message({
							type: 'info',
							message: res.data.Message
						})
					}
				})
			},
			// 打开新增弹框
			append(data) {
				this.ParentId = data.id
				this.dialogFormVisible = true
			},
			//新增
			AddDepart(formName) {
				this.$refs[formName].validate((valid) => {
					if (valid) {
						// console.log(this.form)
						let that = this
						let Token = localStorage.getItem('Token')
						this.$axios.defaults.headers.common["x-Token"] = Token
						this.$axios.post('/Dept/Add', {
							"Data": {
								"ParentId": that.ParentId, //父级ID
								"Name": that.form.addName, //添加的部门
								"Remark": that.form.addFlag //添加的部门备注
							}
						}).then((res) => {
							if (res.data.Code == 0) {
								// console.log(res.data)
								that.dialogFormVisible = false
								that.$refs[formName].resetFields()
								that.CorporateSectorList()
							} else {
								if (res.data.Code == 202) {
									that.$router.push("/login");
								} else {
									that.$message({
										type: 'info',
										message: res.data.Message
									})
								}
								that.$refs[formName].resetFields()
							}
						})
					} else {
						// console.log('error submit!!');
						return false;
					}
				})
			},
			// 打开修改弹框
			edit(node, data) {
				// console.log(data)
				this.EditId = data.id
				this.EditParentId = data.parent_id
				this.EditdialogFormVisible = true
				this.EditForm.EditFlag = data.Remark
				this.EditForm.EditName = data.label
			},
			//修改
			EditDepart(formName) {
				this.$refs[formName].validate((valid) => {
					if (valid) {
						// console.log(this.EditForm)
						let that = this
						let Token = localStorage.getItem('Token')
						this.$axios.defaults.headers.common["x-Token"] = Token
						this.$axios.post('/Dept/Edit', {
							"Data": {
								"Id": that.EditId, //id
								"ParentId": that.EditParentId, //父级ID
								"Name": that.EditForm.EditName, //添加的部门
								"Remark": that.EditForm.EditFlag //添加的部门备注
							}
						}).then((res) => {
							if (res.data.Code == 0) {
								// console.log(res.data)
								that.EditdialogFormVisible = false
								that.$refs[formName].resetFields()
								that.CorporateSectorList()
							} else {
								if (res.data.Code == 202) {
									that.$router.push("/login");
								} else {
									that.$message({
										type: 'info',
										message: res.data.Message
									})
								}
								that.$refs[formName].resetFields()
							}
						})
					} else {
						this.$refs[formName].resetFields()
						// console.log('error submit!!');
						return false;
					}
				})
			},
			// 删除
			remove(node, data) {
				console.log(data)
				this.$confirm('此操作将永久删除该文件, 是否继续?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					let that = this
					let Token = localStorage.getItem('Token')
					this.$axios.defaults.headers.common["x-Token"] = Token
					this.$axios.post('/Dept/Delete', {
						"Data": {
							"Id": data.id,
							"ParentId": data.parent_id
						}
					}).then((res) => {
						if (res.data.Code == 0) {
							// console.log(res.data)
							that.CorporateSectorList()
						} else {
							that.$message({
								type: 'info',
								message: res.data.Message
							})
						}
					})
				}).catch(() => {
					this.$message({
						type: 'info',
						message: '已取消删除'
					});
				})
			},
		}
	}
</script>
<style lang="less" scoped>
	.header {
		display: flex;
		justify-content: space-between;
		align-items: center;
		align-content: center;
	}

	.el-input__inner {
		width: 350px;
	}

	.el-tag+.el-tag {
		margin-left: 10px;
	}

	.button-new-tag {
		margin-left: 10px;
		height: 32px;
		line-height: 30px;
		padding-top: 0;
		padding-bottom: 0;
	}

	.custom-tree-node {
		flex: 1;
		display: flex;
		align-items: center;
		justify-content: space-between;
		font-size: 14px;
		padding-right: 8px;
	}

	.el-button--text {
		padding: 5px;
		margin-left: 10px;
	}
</style>