<template>
  <div id="Salaryapplication">
    <el-card>

      <div class="demo-input-suffix header">
        <el-breadcrumb separator="/">
          <el-breadcrumb-item><i class="el-icon-date"></i>薪资管理</el-breadcrumb-item>
          <el-breadcrumb-item>薪资申请</el-breadcrumb-item>
        </el-breadcrumb>
      </div>
    </el-card>
  </div>
</template>
<script>
  export default {
    name: 'Salaryapplication',
    //组件创建
    created() {},
    mounted() {},
    data() {
      return {}
    },
    methods: {}
  }
</script>
<style lang="less" scoped>
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-content: center;
  }

  .el-input__inner {
    width: 350px;
  }

  .el-tag+.el-tag {
    margin-left: 10px;
  }

  .button-new-tag {
    margin-left: 10px;
    height: 32px;
    line-height: 30px;
    padding-top: 0;
    padding-bottom: 0;
  }

  .custom-tree-node {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 14px;
    padding-right: 8px;
  }

  .el-button--text {
    padding: 5px;
    margin-left: 10px;
  }
</style>
