<template>
	<div id="Bill">
		<el-card>
			<div class="demo-input-suffix header">
				<el-breadcrumb separator="/">
					<el-breadcrumb-item><i class="el-icon-date"></i>系统管理</el-breadcrumb-item>
					<el-breadcrumb-item>发票管理</el-breadcrumb-item>
				</el-breadcrumb>
				<div class="demo-input-suffix">
					<!-- <el-input class="seachInpt" placeholder="请输入关键字" v-model="QST" clearable @change='NameChange'>
           </el-input>
           <el-button size="mini" type="primary" @click='searchange'>
             <i class="el-icon-search"></i>
             搜索
           </el-button> -->
					<el-button size="mini" type="danger" @click="dialogTableVisible = true">
						<i class="el-icon-plus"></i>
						新增发票
					</el-button>
					<el-dialog title="新增发票" :visible.sync="dialogTableVisible" center :append-to-body='true'
						:lock-scroll="false" width="50%" :show-close="false" :close-on-click-modal="false">
						<el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px"
							class="demo-ruleForm">
							<el-form-item label="纳税人名称" prop="BillName" label-width="120px">
								<el-input v-model="ruleForm.BillName" placeholder="请填写纳税人名称"
									style="width: 80%;"></el-input>
							</el-form-item>
							<el-form-item label="发票类型" prop="BillType" label-width="120px">
								<el-select v-model="ruleForm.BillType" placeholder="请选择发票类型">
									<el-option label="增值税普通发票" value="增值税普通发票"></el-option>
									<el-option label="增值税专用发票" value="增值税专用发票"></el-option>
								</el-select>
							</el-form-item>
							<el-form-item label="纳税人识别号" prop="BillNo" label-width="120px">
								<el-input v-model="ruleForm.BillNo" placeholder="请填写纳税人识别号"
									style="width: 80%;"></el-input>
							</el-form-item>
							<el-form-item label="纳税人地址" prop="BillAddr" label-width="120px">
								<el-input v-model="ruleForm.BillAddr" placeholder="请填写纳税人地址"
									style="width: 80%;"></el-input>
							</el-form-item>
							<el-form-item label="纳税人电话" prop="BillTel" label-width="120px">
								<el-input v-model="ruleForm.BillTel" placeholder="请填写纳税人电话"
									style="width: 80%;"></el-input>
							</el-form-item>
							<el-form-item label="开户银行名称" prop="BillBank" label-width="120px">
								<el-input v-model="ruleForm.BillBank" placeholder="请填写开户银行名称"
									style="width: 80%;"></el-input>
							</el-form-item>
							<el-form-item label="开户银行账号" prop="BillBankNo" label-width="120px">
								<el-input v-model="ruleForm.BillBankNo" placeholder="请填写开户银行账号"
									style="width: 80%;"></el-input>
							</el-form-item>
							<el-form-item>
								<el-button @click="resetForm('ruleForm')">取消</el-button>
								<el-button type="primary" @click="submitForm('ruleForm')">立即创建</el-button>
							</el-form-item>
						</el-form>
					</el-dialog>
				</div>
			</div>
			<div class="container">
				<el-table style="width: 100%;margin-bottom:70px;" :data="BillList" ref="multipleTable"
					tooltip-effect="dark" :border="true">
					<el-table-column type="index">
					</el-table-column>
					<el-table-column label="发票类型" prop="BillType" width="200">
					</el-table-column>
					<el-table-column label="纳税人名称" prop="BillName" width="200">
						<template slot-scope="scope">
							<span>{{scope.row.BillName}}</span>
						</template>
					</el-table-column>
					<el-table-column label="纳税人识别号" prop="BillNo" width="200">
					</el-table-column>
					<el-table-column label="纳税人电话" prop="BillTel" width="200">
					</el-table-column>
					<el-table-column label="开户银行名称" prop="BillBank" width="200">
					</el-table-column>
					<el-table-column label="开户银行账号" prop="BillBankNo" width="200">
					</el-table-column>
					<el-table-column label="创建时间" prop="CreatedTime" width="200">
					</el-table-column>
					<el-table-column label="纳税人地址" prop="BillAddr" width="400">
					</el-table-column>
					<el-table-column align="right" width="180">
						<template slot-scope="scope">
							<el-button size="mini" @click="handleLook(scope.$index, scope.row.Id)"
								style="margin-right: 5px;">查看详情
							</el-button>
							<el-dialog title="查看详情" :visible.sync="LookdialogVisible" center :append-to-body='true'
								:lock-scroll="false" width="50%" :show-close="false" :close-on-click-modal="false">
								<el-form :model="Form" ref="Form" label-width="100px" class="demo-ruleForm">
									<el-form-item label="纳税人名称:" prop="BillName" label-width="120px">
										<span style="width: 80%;">{{Form.BillName}}</span>
									</el-form-item>
									<el-form-item label="发票类型:" prop="BillType" label-width="120px">
										<span style="width: 80%;">{{Form.BillType}}</span>
									</el-form-item>
									<el-form-item label="纳税人识别号:" prop="BillNo" label-width="120px">
										<span style="width: 80%;">{{Form.BillNo}}</span>
									</el-form-item>
									<el-form-item label="开户银行名称:" prop="BillBank" label-width="120px">
										<span style="width: 80%;">{{Form.BillBank}}</span>
									</el-form-item>
									<el-form-item label="开户银行账号:" prop="BillBankNo" label-width="120px">
										<span style="width: 80%;">{{Form.BillBankNo}}</span>
									</el-form-item>
									<el-form-item label="纳税人电话:" prop="BillTel" label-width="120px">
										<span style="width: 80%;">{{Form.BillTel}}</span>
									</el-form-item>
									<el-form-item label="纳税人地址:" prop="BillAddr" label-width="120px">
										<span style="width: 80%;">{{Form.BillAddr}}</span>
									</el-form-item>
									<el-form-item>
										<el-button @click="reset('Form')">返回</el-button>
									</el-form-item>
								</el-form>
							</el-dialog>
						</template>
					</el-table-column>
				</el-table>
				<!-- 分页 -->
				<div style="width: 300px;margin: 0 auto;">
					<el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
						:current-page="currentPage" :page-sizes="[10, 20, 30, 40]" :page-size="pagesize"
						layout="total, sizes, prev, pager, next, jumper" :total="TotalRecordQty">
					</el-pagination>
				</div>
			</div>
		</el-card>
	</div>
</template>

<script>
	export default {
		name: 'Bill',
		created() {
			this.Bill()
		},
		data() {
			return {
				QST: '',
				BillList: [], //发票列表
				TotalRecordQty: 10, //总条数
				currentPage: 1, //初始页
				pagesize: 10, //每页的数据

				dialogTableVisible: false, //新增发票

				ruleForm: {
					BillName: '',
					BillType: '',
					BillNo: '',
					BillAddr: '',
					BillTel: '',
					BillBank: '',
					BillBankNo: ''
				},
				rules: {
					BillName: [{
						required: true,
						message: '请输入纳税人名称',
						trigger: 'blur'
					}],
					BillType: [{
						required: true,
						message: '请选择发票类型',
						trigger: 'change'
					}],
					BillNo: [{
						required: true,
						message: '请输入纳税人识别号',
						trigger: 'blur'
					}],
					BillAddr: [{
						required: true,
						message: '请输入纳税人地址',
						trigger: 'blur'
					}],
					BillTel: [{
						required: true,
						message: '请输入纳税人电话',
						trigger: 'blur'
					}],
					BillBank: [{
						required: true,
						message: '请输入开户银行名称',
						trigger: 'blur'
					}],
					BillBankNo: [{
						required: true,
						message: '请输入开户银行账号',
						trigger: 'blur'
					}]
				},
				LookdialogVisible: false,
				Form: {
					BillName: '',
					BillType: '',
					BillNo: '',
					BillAddr: '',
					BillTel: '',
					BillBank: '',
					BillBankNo: ''
				},
			}
		},
		methods: {
			//获取角色
			NameChange(e) {
				// console.log(e)
				this.QST = e
			},
			//搜索
			searchange() {
				this.Bill()
			},
			// 初始页currentPage、初始每页数据数pagesize和数据data
			handleSizeChange: function(size) {
				this.pagesize = size;
				// console.log(this.pagesize) //每页下拉显示数据
				this.Bill()
			},
			handleCurrentChange: function(currentPage, index) {
				this.currentPage = currentPage;
				// console.log(this.currentPage, index) //点击第几页
				this.Bill()
			},
			//获取发票列表
			Bill() {
				let Token = localStorage.getItem('Token')
				let that = this
				this.$axios.defaults.headers.common["x-Token"] = Token
				this.$axios.post('/Bill/List', {
					"Data": {
						"PageNO": that.currentPage, //页索引
						"PageSize": that.pagesize, //每页数量
						"SortDirection": 1, //排序，1倒叙，0升序
						"QST": that.QST //按角色名模糊查询
					}
				}).then(function(res) {
					// console.log(res)
					// console.log(res.data)
					if (res.data.Code == 0) {
						that.BillList = res.data.Data.Items
						that.TotalRecordQty = res.data.Data.TotalRecordQty
					} else {
						if (res.data.Code == 202) {
							that.$router.push("/login");
						}
						// else {
						// 	that.$message({
						// 		type: 'info',
						// 		message: res.data.Message
						// 	})
						// }
					}
				})
			},
			//查看详情
			handleLook(index, ID) {
				let Token = localStorage.getItem('Token')
				let that = this
				this.$axios.defaults.headers.common["x-Token"] = Token
				this.$axios.post('/Bill/GetByID', {
					"Data": ID
				}).then(function(res) {
					// console.log(res)
					// console.log(res.data)
					if (res.data.Code == 0) {
						that.LookdialogVisible = true
						that.Form.BillName = res.data.Data.BillName
						that.Form.BillType = res.data.Data.BillType
						that.Form.BillNo = res.data.Data.BillNo
						that.Form.BillAddr = res.data.Data.BillAddr
						that.Form.BillTel = res.data.Data.BillTel
						that.Form.BillBank = res.data.Data.BillBank
						that.Form.BillBankNo = res.data.Data.BillBankNo
					}else{
						if (res.data.Code == 202) {
							that.$router.push("/login");
						} 
						// else {
						// 	that.$message({
						// 		type: 'info',
						// 		message: res.data.Message
						// 	})
						// }
					}
				})
			},
			//取消查看
			reset(formName) {
				this.$refs[formName].resetFields();
				this.LookdialogVisible = false
				this.BillList = []
				this.Bill()
			},
			//创建
			submitForm(formName) {
				this.$refs[formName].validate((valid) => {
					if (valid) {
						// alert('submit!');
						// console.log(this.ruleForm)
						let Token = localStorage.getItem('Token')
						let that = this
						this.$axios.defaults.headers.common["x-Token"] = Token
						this.$axios.post('/Bill/Add', {
							"Data": {
								"BillName": that.ruleForm.BillName,
								"BillType": that.ruleForm.BillType,
								"BillNo": that.ruleForm.BillNo,
								"BillAddr": that.ruleForm.BillAddr,
								"BillTel": that.ruleForm.BillTel,
								"BillBank": that.ruleForm.BillBank,
								"BillBankNo": that.ruleForm.BillBankNo
							}
						}).then(function(res) {
							console.log(res)
							if (res.data.Code == 0) {
								that.$message({
									type: 'success',
									message: res.data.Message
								})
							} else {
								if (res.data.Code == 202) {
									that.$router.push("/login");
								} else {
									that.$message({
										type: 'info',
										message: res.data.Message
									})
								}
							}
							that.$refs[formName].resetFields();
							that.dialogTableVisible = false
							that.BillList = []
							that.Bill()
						})
					} else {
						return false;
					}
				});
			},
			//取消创建
			resetForm(formName) {
				this.$refs[formName].resetFields();
				this.dialogTableVisible = false
				this.BillList = []
				this.Bill()
			}
		}
	}
</script>

<style lang="less" scoped>
	/* 头部标题 */
	.header {
		align-items: center;
		justify-content: space-between;
	}

	.demo-input-suffix {
		display: flex;
	}

	.el-breadcrumb__item {
		display: flex;
	}

	/* 搜索框 */
	.seachInpt {
		margin-right: 10px;
	}
</style>