<template>
  <div id="Mybusiness">
    <el-card>
      <div class="demo-input-suffix header">
        <el-breadcrumb separator="/">
          <el-breadcrumb-item><i class="el-icon-date"></i>系统管理</el-breadcrumb-item>
          <el-breadcrumb-item>我的企业</el-breadcrumb-item>
        </el-breadcrumb>
      </div>
      <div style="padding: 20px 0;">
        <div style="display: flex;align-items: center;font-size: 20pt;padding-bottom: 30px;">
          <el-image style="width: 180px;height: 95px;border-radius: 8px;" :src="CompanyInfo.LogoPic" fit="scale-down">
          </el-image>
          <!-- <img  /> -->
          <div style="margin: 0 10px;">
            <span style="font-weight: bolder;">{{CompanyInfo.CompanyName}}</span>
            <tr></tr>
            <span style="font-size: 14pt;"> {{CompanyInfo.CityTxt}} • {{CompanyInfo.AreaTxt}} </span>
          </div>
        </div>
        <div>
          <div class="company-syn">
            <h2 class="tit">
              <em class="line"></em>
              公司简介
            </h2>
            <div :class="showTotal?'total-introduce':'detailed-introduce'">
              <div class="intro-content" :title="introduce" ref="desc">
                <p class="merchant-desc" v-if="introduce" v-html="introduce">{{introduce}}</p>
                <div class="unfold" @click="showTotalIntro" v-if="showExchangeButton">
                  <p>{{exchangeButton?'...展开':'收起'}}<i
                      :class="exchangeButton?'el-icon-arrow-down':'el-icon-arrow-up'"></i></p>
                </div>
              </div>
            </div>
          </div>
          <div>
            <h2 class="tit">
              <em class="line"></em>
              公司图片
            </h2>
            <el-carousel :interval="4000" type="card" height="200px" style="text-align: center;">
              <el-carousel-item v-for="(item,index) in CompanyInfo.BCompanyPic" :key="index">
                <el-image :src="item.FileUrl" fit="fill" style="width:473px;height: 200px;">
                </el-image>
              </el-carousel-item>
            </el-carousel>
          </div>
          <div>
            <h2 class="tit">
              <em class="line"></em>
              联系信息
            </h2>
            <div style="margin: 20px;">
              <table class="lx">
                <tbody>
                  <tr>
                    <td class="tit"><i class="fa fa-user-o"></i> 联系人 : </td>
                    <td>
                      {{CompanyInfo.ContactPerson}}
                    </td>
                  </tr>
                  <tr>
                    <td class="tit"><i class="el-icon-mobile-phone"></i> 联系电话 : </td>
                    <td>
                      {{CompanyInfo.ContactPhone}}
                    </td>
                  </tr>
                  <tr>
                    <td class="tit"><i class="el-icon-message"></i> 邮箱 : </td>
                    <td>
                      {{CompanyInfo.ContactEMail}}
                    </td>
                  </tr>
                  <tr>
                    <td class="tit"><i class="el-icon-phone-outline"></i> 电话号 : </td>
                    <td>
                      {{CompanyInfo.ContactTel}}
                    </td>
                  </tr>
                  <tr>
                    <td class="tit"><i class="el-icon-location-outline"></i> 地址 : </td>
                    <td>
                      {{CompanyInfo.Address}}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </el-card>

  </div>
</template>

<script>
  export default {
    name: 'Mybusiness',
    //组件创建
    created() {
      this.Company()
    },
    mounted() {},
    data() {
      return {
        CompanyInfo: [],
        introduce: '', // 简介
        showTotal: true, // 简介全部显示
        exchangeButton: true, // 展开/收起文字改变
        showExchangeButton: false, // 是否显示 展开/收起 文字
      }
    },
    methods: {
      Company() {
        var that = this
        let Token = localStorage.getItem('Token')
        this.$axios.defaults.headers.common["x-Token"] = Token
        this.$axios.post('/User/Company').then((res) => {
          console.log(res)
          this.CompanyInfo = res.data.Data
          this.introduce = res.data.Data.Description
        })
      },
      showTotalIntro() {
        this.showTotal = !this.showTotal;
        this.exchangeButton = !this.exchangeButton;
      }
    },
    watch: {
      introduce: function() {
        this.$nextTick(() => {
          if (!this.$refs.desc) {
            return
          }
          const descHeight = window.getComputedStyle(this.$refs.desc).height.replace('px', '')
          if (parseInt(descHeight) * 2 > 120) {
            this.showExchangeButton = true
            this.exchangeButton = true
            this.showTotal = false
          } else {
            this.showExchangeButton = false
            this.showTotal = true
          }
        })
      }
    },
  }
</script>
<style lang="less" scoped>
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-content: center;
  }

  .el-input__inner {
    width: 350px;
  }

  .el-tag+.el-tag {
    margin-left: 10px;
  }

  .button-new-tag {
    margin-left: 10px;
    height: 32px;
    line-height: 30px;
    padding-top: 0;
    padding-bottom: 0;
  }

  .custom-tree-node {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 14px;
    padding-right: 8px;
  }

  .el-button--text {
    padding: 5px;
    margin-left: 10px;
  }

  .tit {
    font-weight: 700;
    color: #424a5e;
    display: flex;
    align-items: center;
    position: relative;
    margin: 20px 0;
  }

  .line {
    display: block;
    width: 10px;
    height: 19px;
    border-left: 3px solid #FF6600A1;
  }

  li a {
    text-decoration: none;
    color: #55aaff;
    margin-left: 10px;
  }

  .total-introduce {
    height: auto;
    overflow: hidden;
    // font-size: 26px;
    color: #666;
    margin-top: 30px;

    .intro-content {
      .merchant-desc {
        width: 100%;
        line-height: 40px;
        text-indent: 2em;
      }
    }

    .unfold {
      display: block;
      float: right;
      width: 80px;
      height: 40px;
      z-index: 8;
      text-align: center;

      p {
        margin: 0;
        line-height: 40px;
        color: #ff5800;
        font-size: 12pt;
      }
    }
  }

  .detailed-introduce {
    // font-size: 26px;
    color: #666;
    position: relative;
    overflow: hidden;
    margin-top: 30px;

    .intro-content {
      //最大高度设为3倍的行间距
      max-height: 130px;
      line-height: 40px;
      word-wrap: break-word;
      word-break: break-all;
      background-color: #fff;
      color: #fff;
      overflow: hidden;
      text-indent:2em;

      .merchant-desc {
        width: 100%;
        line-height: 40px;
      }

      &::after,
      &::before {
        content: attr(title);
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        color: #666;
      }

      &::before {
        display: block;
        overflow: hidden;
        z-index: 1;
        max-height: 80px;
        background: #FFF;
      }

      &::after {
        display: -webkit-box;
        -webkit-box-orient: vertical;
        height: 120px;
        // 截取行数
        -webkit-line-clamp: 3;
        text-overflow: ellipsis;
        box-sizing: border-box;
        // 行首缩进字符数，值为[(截取行数-1)*尾部留空字符数]
        text-indent: -6em;
        // 尾部留空字符数
        padding-right: 3em;

      }

      .unfold {
        z-index: 8;
        width: 80px;
        height: 40px;
        outline: 0;
        position: absolute;
        right: 0;
        bottom: -10px;
        text-align: center;

        p {
          margin: 0;
          color: #ff5800;
          font-size: 12pt;
        }
      }
    }
  }

  .lx tbody tr {
    line-height: 40px;

    .tit {
      width: 100px;
      display: block;
      margin: 0;
      font-weight: normal;
    }
  }
</style>
