<template>
  <div id="Jobapplication">
    <el-card>
      <div class="demo-input-suffix header">
        <el-breadcrumb separator="/">
          <el-breadcrumb-item><i class="el-icon-date"></i>招聘管理</el-breadcrumb-item>
          <el-breadcrumb-item>申请记录</el-breadcrumb-item>
        </el-breadcrumb>
        <div class="demo-input-suffix">
          <el-select v-model="Status" placeholder="请选择" style="margin-right: 10px;" @change="StatusValueChange">
            <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
          <el-input class="seachInpt" placeholder="请输入搜索关键字" v-model="jobName" clearable @change='NameChange'>
          </el-input>
          <el-button size="mini" type="primary" @click='searchange'>
            <i class="el-icon-search"></i>
            搜索
          </el-button>
        </div>
      </div>
      <div class="cantainer">
        <el-table style="width: 100%;margin-bottom: 70px;" :data="Joblist" ref="multipleTable" tooltip-effect="dark"
          :border="true">
          <!-- <el-table-column type="selection" width="55">
          </el-table-column> -->
          <el-table-column type="index" width="50">
            <!-- :index="indexMethod" -->
          </el-table-column>
          <el-table-column label="岗位名称" width="200">
            <template slot-scope="scope">
              <span class="jobname">{{scope.row.JobName}}</span>
            </template>
          </el-table-column>
          <el-table-column width="290">
            <template slot="header" slot-scope="scope">
              <label>工作周期</label>
            </template>
            <template slot-scope="scope">
              <el-tag size="medium">
                {{ scope.row.StartTime.replace(' ',':').replace(/\:/g,"-").split('-')[0]+"/"+scope.row.StartTime.replace(' ',':').replace(/\:/g,"-").split('-')[1]+"/"+scope.row.StartTime.replace(' ',':').replace(/\:/g,"-").split('-')[2]}}
                ­­­­－
                {{scope.row.EndTime.replace(' ',':').replace(/\:/g,"-").split('-')[0]+"/"+scope.row.EndTime.replace(' ',':').replace(/\:/g,"-").split('-')[1]+"/"+scope.row.EndTime.replace(' ',':').replace(/\:/g,"-").split('-')[2]}}
                ,
                {{ scope.row.StartTime.replace(' ',':').replace(/\:/g,"-").split('-')[3]+":"+scope.row.StartTime.replace(' ',':').replace(/\:/g,"-").split('-')[4]}}
                ­－­
                {{scope.row.EndTime.replace(' ',':').replace(/\:/g,"-").split('-')[3]+":"+scope.row.EndTime.replace(' ',':').replace(/\:/g,"-").split('-')[4]}}
              </el-tag>
            </template>
          </el-table-column>
          <el-table-column label="申请人" prop="CUserName" width='100'>
            <template slot-scope="scope">
              <el-tag size="medium" @click="CUserInfo(scope.row.CUserID)">{{ scope.row.CUserName }}</el-tag>
            </template>
          </el-table-column>
          <el-table-column label="申请状态" prop="StatusTxt" width='100'></el-table-column>
          <el-table-column label="申请时间" prop="CreatedTime" width="200"></el-table-column>
          <el-table-column label="是否黑名单" prop="IsBlacklistTxt" width="150"></el-table-column>
          <el-table-column align="right" width="400">
            <!-- <template slot="header" slot-scope="scope">
              <label>操作</label>
            </template> -->
            <template slot-scope="scope">
              <template>
                <div>
                  <el-button v-show="scope.row.IsBlacklist!=true" style="margin-right: 10px;" size="mini" type="primary"
                    plain @click="AddBlackList(scope.row.CUserID,scope.row.CUserName)">加入黑名单</el-button>
                  <el-dialog title="提示" :visible.sync="dialogVisible" width="50%"><!-- :before-close="handleClose" -->
                    <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="150px" class="demo-ruleForm">
                      <el-form-item label="加入黑名单原因" prop="desc" >
                        <el-input type="textarea" v-model="ruleForm.desc"></el-input>
                      </el-form-item>
                      <div style="text-align: initial;padding-left: 42px;color: #999;margin: 20px 0;">
                        <span>[例]：</span><br />
                        <span>1.用户形象不符合企业用工标准</span><br />
                        <span>2.用户工作态度不良好</span><br />
                        <span>3.用户考勤不按时打卡</span><br />
                        <span>4.其他原因</span><br />
                      </div>
                      <el-form-item>
                        <el-button type="primary" @click="submitForm('ruleForm')">立即创建</el-button>
                        <el-button @click="resetForm('ruleForm')">重置</el-button>
                      </el-form-item>
                    </el-form>
                  </el-dialog>
                  <el-button v-show="scope.row.Status==1" size="mini" type="success" plain
                    @click="Agree(scope.$index, scope.row)">同意录用</el-button>
                  <el-button v-show="scope.row.Status==1" size="mini" type="danger" plain
                    @click="Reject(scope.$index, scope.row)">拒绝录用</el-button>
                </div>
              </template>
            </template>
          </el-table-column>
        </el-table>
        <!-- 分页 -->
        <div style="width: 300px;margin: 0 auto;">
          <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
            :current-page="currentPage" :page-sizes="[10, 20, 30, 40]" :page-size="pagesize"
            layout="total, sizes, prev, pager, next, jumper" :total="TotalRecordQty">
          </el-pagination>
        </div>
      </div>
    </el-card>
  </div>
</template>
<script>
  export default {
    name: 'Jobapplication',
    //组件创建
    created() {
      this.Jobapplication()
    },
    mounted() {},
    data() {
      return {
        jobName: '', //搜索关键字
        Joblist: [], // 企业列表
        TotalRecordQty: 10, //总条数
        currentPage: 1, //初始页
        pagesize: 10, //每页的数据
        Status: 0,
        EmployeeImport: false, //审核
        AuditFrom: {},
        options: [{
            value: 0,
            label: '全部'
          }, {
            value: 1,
            label: '已申请'
          }, {
            value: 2,
            label: '已查看'
          }, {
            value: 3,
            label: '企业拒绝'
          }, {
            value: 4,
            label: '待接单'
          }, {
            value: 5,
            label: '已结束'
          },
          {
            value: 6,
            label: '已接单'
          },
          {
            value: 7,
            label: '已完成'
          }
        ],
        dialogTableVisible: false, //导入弹框
        visible: false,

        //黑名单
        dialogVisible: false,
        ruleForm: {
          desc: '',
          CUserName: '',
          CUserID: ''
        },
        rules: {
          desc: [{
            required: true,
            message: '请填写黑名单原因',
            trigger: 'blur'
          }]
        }
        // CUserDialogin: false, //个人简历
        // GetUserAll: [],
        // Cuserform: [], //基本信息
      }
    },
    methods: {
      submitForm(formName) {
        this.$refs[formName].validate((valid) => {
          if (valid) {
            let that = this
            let Token = localStorage.getItem('Token')
            this.$axios.defaults.headers.common["x-Token"] = Token
            this.$axios.post('/BlackListHandle/Add', {
              "Data": {
                "CUserID": that.ruleForm.CUserID,
                "CUserName": that.ruleForm.CUserName,
                "Remark": that.ruleForm.desc
              }
            }).then((res) => {
              if (res.data.Code == 0) {
                // console.log(res.data)
                that.$message({
                  type: 'success',
                  message: res.data.Message
                })
                that.dialogVisible = false
                that.Joblist = []
                that.Jobapplication()
              } else {
                if (res.data.Code == 202) {
                	that.$router.push("/login");
                } else {
                	that.$message({
                		type: 'info',
                		message: res.data.Message
                	})
                }
              }
              that.$refs[formName].resetFields();
            })
          } else {
            // console.log('error submit!!');
            return false;
          }
        });
      },
      resetForm(formName) {
        this.$refs[formName].resetFields();
        this.dialogVisible = false
      },
      // 新增黑名单
      AddBlackList(CUserID, CUserName) {
        this.ruleForm.CUserID = CUserID
        this.ruleForm.CUserName = CUserName
        this.dialogVisible = true
      },
      //个人简历
      CUserInfo(id) {
        this.$router.push(`/ResumePreview?userId=${id}`)
        //   this.CUserDialogin = true
        //   let that = this
        //   let Token = localStorage.getItem('Token')
        //   this.$axios.defaults.headers.common["x-Token"] = Token
        //   this.$axios.post('/JobManage/JobApply/Cuser', {
        //     "Data": {
        //       "CuserId": id
        //     }
        //   }).then((res) => {
        //     if (res.data.Code == 0) {
        //       console.log(res.data)
        //       // that.$message({
        //       //   type: 'success',
        //       //   message: res.data.Message
        //       // })
        //       that.GetUserAll = res.data.Data
        //       that.Cuserform = res.data.Data.cUser
        //     } else {
        //       that.$message({
        //         type: 'info',
        //         message: res.data.Message
        //       })
        //     }
        //   })
      },
      //获取角色
      NameChange(e) {
        // console.log(e)
        this.jobName = e
      },
      //状态
      StatusValueChange(e) {
        // console.log(e)
        this.Status = e
      },
      //搜索
      searchange() {
        // console.log(this.ComanyName)
        this.Joblist = []
        this.Jobapplication()
      },
      //获取岗位应聘列表
      Jobapplication() {
        // /JobManage/JobApply/JobApplyList
        let that = this
        let Token = localStorage.getItem('Token')
        this.$axios.defaults.headers.common["x-Token"] = Token
        this.$axios.post('/JobManage/JobApply/JobApplyList', {
          "Data": {
            "QST": this.jobName,
            "Status": this.Status,
            "PageNO": this.currentPage,
            "PageSize": this.pagesize
          }
        }).then((res) => {
          if (res.data.Code == 0) {
            // console.log(res.data)
            // that.$message({
            //   type: 'success',
            //   message: res.data.Message
            // })
            that.Joblist = res.data.Data.Items
            that.TotalRecordQty = res.data.Data.TotalRecordQty
          } else {
            if (res.data.Code == 202) {
            	that.$router.push("/login");
            } else {
            	that.$message({
            		type: 'info',
            		message: res.data.Message
            	})
            }
          }
        })
      },
      // 条数
      indexMethod(index) {
        if (this.currentPage == 1) {
          return index * 1 + 1;
        } else {
          return (index + this.currentPage - 1) * 10 + 1
        }
      },
      // 初始页currentPage、初始每页数据数pagesize和数据data
      handleSizeChange: function(size) {
        this.pagesize = size;
        // console.log(this.pagesize) //每页下拉显示数据
        // this.Joblist = []
        this.Jobapplication()
      },
      handleCurrentChange: function(currentPage, index) {
        this.currentPage = currentPage;
        // console.log(this.currentPage, index) //点击第几页
        // this.Joblist = []
        this.Jobapplication()
      },
      //同意
      Agree(index, item) {
        var id = item.Id
        // let that = this
        this.$prompt('请输入备注', '提示', {
          confirmButtonText: '确定',
          showCancelButton: false
        }).then(({
          value
        }) => {
          // this.$message({
          //   type: 'success',
          //   message: '你的邮箱是: ' + value
          // });
          let Token = localStorage.getItem('Token')
          this.$axios.defaults.headers.common["x-Token"] = Token
          this.$axios.post('/JobManage/JobApply/Hire', {
            "Data": {
              "Id": id,
              "Status": 1,
              "Remark": value
            }
          }).then((res) => {
            if (res.data.Code == 0) {
              // console.log(res.data)
              this.$message({
                type: 'success',
                message: res.data.Message
              })

            } else {
              if (res.data.Code == 202) {
              	this.$router.push("/login");
              } else {
              	this.$message({
              		type: 'info',
              		message: res.data.Message
              	})
              }
            }
            this.Joblist = []
            this.Jobapplication()

          })

        })
      },
      //拒绝
      Reject(index, item) {
        var id = item.Id
        this.$prompt('请输入备注', '提示', {
          confirmButtonText: '确定',
          showCancelButton: false
        }).then(({
          value
        }) => {
          // this.$message({
          //   type: 'success',
          //   message: '你的邮箱是: ' + value
          // });
          let Token = localStorage.getItem('Token')
          this.$axios.defaults.headers.common["x-Token"] = Token
          this.$axios.post('/JobManage/JobApply/Hire', {
            "Data": {
              "Id": id,
              "Status": 0,
              "Remark": value
            }
          }).then((res) => {
            if (res.data.Code == 0) {
              // console.log(res.data)
              this.$message({
                type: 'success',
                message: res.data.Message
              })
            } else {
              if (res.data.Code == 202) {
              	this.$router.push("/login");
              } else {
              	this.$message({
              		type: 'info',
              		message: res.data.Message
              	})
              }
            }
          })
          this.Joblist = []
          this.Jobapplication()
        })

      },
      //导入员工
      Import(item) {
        let Token = localStorage.getItem('Token')
        this.$axios.defaults.headers.common["x-Token"] = Token
        this.$axios.post('/Person/Import', {
          "Data": {
            "CUserID": item.CUserID,
            "JobID": item.JobID,
            "ApplyID": item.Id
          }
        }).then((res) => {
          if (res.data.Code == 0) {
            // console.log(res.data)
            this.$message({
              type: 'success',
              message: res.data.Message
            })
            this.Joblist = []
            this.Jobapplication()
          } else {
            if (res.data.Code == 202) {
            	this.$router.push("/login");
            } else {
            	this.$message({
            		type: 'info',
            		message: res.data.Message
            	})
            }
          }
        })
      }
    }
  }
</script>
<style lang="less" scoped>
  /* 头部标题 */
  .header {
    align-items: center;
    justify-content: space-between;
  }

  .demo-input-suffix {
    display: flex;
  }

  .el-breadcrumb__item {
    display: flex;
  }

  /* 搜索框 */
  .seachInpt {
    margin-right: 10px;
  }

  .jobname {
    width: 200px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;

  }
</style>
