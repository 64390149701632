<template>
  <div id="Oddjobinformation">
    <div class="elheader">
      <div class="headMain">
        <a href="/home" target="_blank">
          <img src="../assets/msh22.png" width="133" height="47" style="border: none;">
        </a>
        <div class="f_r">
          <a href="javascript:window.print()">[打印简历]</a>
          <a href="javascript://" onclick="window.history.go(-1)" style="color: rgb(255, 88, 0);">[关闭简历]</a>
        </div>
      </div>
    </div>
    <div class="main1">
      <div class="main2">
        <div style="line-height: 40px; font-size: 16px; font-weight: bold; text-align: center;"> 简 历 </div>
        <div class="box">
          <div class="boxTit1">基本信息</div>
          <div class="boxMain">
            <table width="100%" cellspacing="2" cellpadding="3" border="0" align="center">
              <tbody>
                <tr>
                  <td width="120" align="right"> 姓 名： </td>
                  <td width="181"> {{GetUserAll.cUser.UserName}} </td>
                  <td width="120" align="right"> 性 别： </td>
                  <td width="141"> {{GetUserAll.cUser.Gender}} </td>
                  <td rowspan="5">
                    <el-image id="image_user" alt="形象照片" border="0" :src="GetUserAll.cUser.Avatar" style="width: 120px;" fit="scale-down"></el-image>
                    <!-- <img id="image_user" alt="形象照片" border="0" style="height: 147px; width: 120px; border-width: 0px;"
                      :src="GetUserAll.cUser.Avatar"> -->
                  </td>
                </tr>
                <tr>
                  <td align="right"> 出生日期： </td>
                  <td> {{GetUserAll.cUser.Birth.split(' ')[0]}} </td>
                  <td align="right"> 工作状态： </td>
                  <td> {{GetUserAll.cUser.JobStatusTxt}} </td>
                </tr>
                <tr>
                  <td align="right"> 工作经验： </td>
                  <td> {{GetUserAll.cUser.WorkExpTxt}} </td>
                  <td align="right"> 学 历： </td>
                  <td> {{GetUserAll.cUser.EducationTxt}} </td>
                  <td></td>
                </tr>
                <tr>
                  <td align="right"></td>
                  <td colspan="4"></td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="boxBottom"></div>
        </div>
        <div class="CH10"></div>
        <div class="box">
          <div class="boxTit1"> 个性标签</div>
          <div class="boxMain">
            <span class="boxSpan" v-for="(item,index) in GetUserAll.Tags" :key="index">
              {{item}}
            </span>
          </div>
          <div class="boxBottom"></div>
        </div>
        <div class="CH10"></div>
        <div class="box">
          <div class="boxTit1"> 求职意向/期望岗位</div>
          <div class="boxMain">
            <span class="boxSpan" v-for="(item,index) in GetUserAll.JobTypeNames" :key="index">
              {{item}}
            </span>
          </div>
          <div class="boxBottom"></div>
        </div>
        <div class="CH10"></div>
        <div class="box">
          <div class="boxTit1">资格证书</div>
          <div class="boxMain">
            <table width="100%" align="center" border="0" cellpadding="3" cellspacing="2" v-for="(item,index) in GetUserAll.cCert" :key="index">
              <tbody>
                <tr>
                  <td width="120" align="right" valign="top">
                    证书：
                  </td>
                  <td>
                    <span style="color: #ff7400" v-if="item.CertType==1">健康证</span>
                    <span style="color: #ff7400" v-if="item.CertType==2">毕业证</span>
                    <span style="color: #ff7400" v-if="item.CertType==3">健身教练证</span>
                    <span style="color: #ff7400" v-if="item.CertType==4">厨师职业资格证</span>
                    <span style="color: #ff7400" v-if="item.CertType==5">会计从业资格证</span>
                    <span style="color: #ff7400" v-if="item.CertType==6">电工证</span>
                    <span style="color: #ff7400" v-if="item.CertType==7">技工证</span>
                    <span style="color: #ff7400" v-if="item.CertType==8">其他</span>
                  </td>
                </tr>
                <tr>
                  <td align="right" valign="top">有效期：</td>
                  <td>{{item.StartDate.split(' ')[0]}}-{{item.EndDate.split(' ')[0]}}</td>
                </tr>
                <tr>
                  <td align="right" valign="top">证书编码：</td>
                  <td>{{item.CertNo}}</td>
                </tr>
                <tr>
                  <td align="right" valign="top">发证机关：</td>
                  <td>{{item.CertOffice}}</td>
                </tr>
                <tr>
                  <td align="right" valign="top">证书图片：</td>
                  <td>
                    <el-image style="width: 300px;" :src="item.FileUrlRight" fit="scale-down">
                    </el-image>
                    <!-- <img :src="item.FileUrlRight" border="0" style="height: 200px; width: 200px; border-width: 0px;"> -->
                  </td>
                </tr>
                <tr>
                 <td style="border-top: 1px dotted #d6d6d6;" colspan="2"></td>
                </tr>
              </tbody>
            </table>
            <!-- <div style="border-top: 1px dotted #d6d6d6;margin: 10px 0;"></div> -->
            <!-- <table width="100%" align="center" border="0" cellpadding="3" cellspacing="2">
              <tbody>
                <tr>
                  <td width="120" align="right">
                    工作时间：
                  </td>
                  <td>
                    2022-02
                    -
                    2022-07
                    <span style="color: #ff7400">
                      呀呀呀</span>
                  </td>
                </tr>
              </tbody>
            </table> -->
          </div>
          <div class="boxBottom"></div>
        </div>
      </div>
      <table width="100%" cellspacing="2" cellpadding="3" border="0">
        <tbody>
          <tr>
            <td style="width: 50%;"> 谋生活(61msh.com) </td>
            <td align="right"> 注册时间：{{GetUserAll.cUser.CreatedTime}} 更新时间：{{GetUserAll.cUser.LastUpdatedTime}} </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'ResumePreview',
    //组件创建
    created() {
      console.log(window.location.href.split('=')[1])
      this.CuserId = window.location.href.split('=')[1]
      this.CuserInfo()
    },
    mounted() {},
    data() {
      return {
        CuserId: '',
        GetUserAll: [],
        Cuserform: []
      }
    },
    methods: {
      CuserInfo() {
        let that = this
        let Token = localStorage.getItem('Token')
        this.$axios.defaults.headers.common["x-Token"] = Token
        this.$axios.post('/JobManage/JobApply/Cuser', {
          "Data": {
            "CuserId": that.CuserId
          }
        }).then((res) => {
          if (res.data.Code == 0) {
            console.log(res.data)
            that.GetUserAll = res.data.Data
            // that.Cuserform = res.data.Data.cUser
          } else {
            that.$message({
              type: 'info',
              message: res.data.Message
            })
          }
        })
      }
    }
  }
</script>

<style lang="less" scoped>
  #Oddjobinformation {
    width: 100%;
    height: 100%;
    color: rgb(61, 61, 61);
    font-family: Sans-Serif, "宋体";
    font-size: 12px;
    line-height: 150%;
  }

  .elheader {
    background-color: #f3f4f4;
    color: #333;
    text-align: center;
    line-height: 64px;
    height: 64px;
  }

  .headMain {
    width: 840px;
    margin: 0 auto;
    padding-top: 6px;
    display: flex;
    justify-content: space-between;
    align-content: center;
  }

  a {
    text-decoration: none;
    color: #3d3d3d;
  }

  .main1 {
    width: 840px;
    margin: 10px auto;
    border: 1px solid #dedede;
    background-color: #f0f1f6;
  }

  .main2 {
    width: 828px;
    margin: 6px auto;
    background-color: #f8f9fb;
  }

  .CH10 {
    height: 10px;
    clear: both;
  }

  .boxTit1 {
    background: url(https://www.61hr.com/images/linq/viewTit1.gif) no-repeat;
    height: 33px;
    line-height: 33px;
    color: #ff7400;
    font-weight: bold;
    padding-left: 10px;
    font-size: 14px;
  }

  .boxMain {
    line-height: 20px;
    padding: 10px 10px 0 10px;
    border-left: 1px solid #efefef;
    border-right: 1px solid #efefef;
    background-color: #ffffff;
  }
  .boxMain table tbody tr{
    line-height: 40px;
  }
  .boxBottom {
    background: url(https://www.61hr.com/images/linq/viewBottom.gif) no-repeat;
    height: 11px;
  }
</style>
