<template>
	<div id="AttendanceRecord">
		<el-card>
			<div class="demo-input-suffix header">
				<el-breadcrumb separator="/">
					<el-breadcrumb-item><i class="el-icon-date"></i> 首页</el-breadcrumb-item>
					<el-breadcrumb-item>考勤对账单</el-breadcrumb-item>
				</el-breadcrumb>
				<div class="demo-input-suffix">
					<el-select v-model="CompanyAuditStatus" placeholder="请选择" style="margin-right: 10px;width:135px;"
						@change="StatusValueChange">
						<el-option v-for="item in CompanyAuditOptions" :key="item.value" :label="item.label"
							:value="item.value">
						</el-option>
					</el-select>
					<!-- <el-select v-model="FKStatus" placeholder="请选择" style="margin-right: 10px;width:100px;"
						@change="FKStatusValueChange">
						<el-option v-for="item in FKStatusOptions" :key="item.value" :label="item.label"
							:value="item.value">
						</el-option>
					</el-select> -->
					<el-date-picker style="margin-right: 2%;" v-model="DayValue" type="daterange" align="right"
						unlink-panels range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期"
						:picker-options="pickerOptions" format="yyyy 年 MM 月 dd 日" value-format="yyyy-MM-dd"
						@change="DateChange" :clearable="false">
					</el-date-picker>
					<el-button size="mini" type="primary" @click='searchange'>
						<i class="el-icon-search"></i>
						搜索
					</el-button>
				</div>
			</div>
			<div class="container">
				<el-table style="width: 100%;margin-top: 20px;margin-bottom: 5%;" stripe :data="AuditBilllists"
					ref="multipleTable" tooltip-effect="dark" border>
					<el-table-column type="index" width="80">
					</el-table-column>
					<el-table-column label="账单编号" prop="FinanceNo" width="240">
						<template slot-scope="scope">
							<span
								style="white-space: nowrap;text-overflow: ellipsis;overflow: hidden;">#{{scope.row.FinanceNo}}</span>
						</template>
					</el-table-column>
					<el-table-column label="账单名称" prop="FinanceName" width="240">
						<template slot-scope="scope">
							<span
								style="white-space: nowrap;text-overflow: ellipsis;overflow: hidden;">{{scope.row.FinanceName}}</span>
						</template>
					</el-table-column>
					<el-table-column label="企业名称" prop="CompanyName" width="240">
						<template slot-scope="scope">
							<span
								style="white-space: nowrap;text-overflow: ellipsis;overflow: hidden;">{{scope.row.CompanyName}}</span>
						</template>
					</el-table-column>
					<el-table-column label="对账单周期" width="240">
						<template slot-scope="scope">
							<span
								style="white-space: nowrap;text-overflow: ellipsis;overflow: hidden;">{{scope.row.OrderStart.split(' ')[0]}}
								~ {{scope.row.OrderEnd.split(' ')[0]}}</span>
						</template>
					</el-table-column>
					<el-table-column label="结算金额(/元)" prop="ContractAmount" width="150"></el-table-column>
					<el-table-column label="企业核对状态" width="150">
						<template slot-scope="scope">
							<el-tag type="danger" v-if="scope.row.CompanyAuditStatus==0">
								{{scope.row.CompanyAuditStatusTxt}}
							</el-tag>
							<el-tag v-else>
								{{scope.row.CompanyAuditStatusTxt}}
							</el-tag>
						</template>
					</el-table-column>
					<el-table-column label="对账单提交人" prop="CreatedUserName" width="150"></el-table-column>
					<el-table-column label="对账单提交时间" prop="CreatedTime" width="240"></el-table-column>
					<!-- <el-table-column label="中控平台核对状态" width="150">
						<template slot-scope="scope">
							<el-tag type="danger" v-if="scope.row.AuditStatus==0">
								{{scope.row.AuditStatusTxt}}
							</el-tag>
							<el-tag v-else>
								{{scope.row.AuditStatusTxt}}
							</el-tag>
						</template>
					</el-table-column>
					<el-table-column label="付款状态">
						<template slot-scope="scope">
							<el-tag type="danger" v-if="scope.row.Status==0">
								{{scope.row.StatusTxt}}
							</el-tag>
							<el-tag v-else>
								{{scope.row.StatusTxt}}
							</el-tag>
						</template>
					</el-table-column> -->
					<el-table-column label="对账单备注" prop="Remark" width="240"> </el-table-column>
					<el-table-column width='300' fixed="right">
						<template slot="header" slot-scope="scope">
							<label>操作</label>
						</template>
						<template slot-scope="scope">
							<el-button style="margin-bottom: 5px;margin-left: 5px;" size="mini" type="success" plain
								:loading="LookIndex==scope.$index" @click="LookChange(scope.$index,scope.row)">
								<i class="el-icon-upload"></i>查看考勤明细
							</el-button>
							<el-button style="margin-bottom: 5px;margin-left: 2%;" size="mini" type='warning'
								@click="EditChange(scope.$index,scope.row.Id,scope.row.CompanyAuditStatus)"
								v-show="scope.row.CompanyAuditStatus==0" plain :loading="EditIndex==scope.$index">
								<i class="el-icon-s-claim"></i>修改核对状态
							</el-button>
						</template>
					</el-table-column>
				</el-table>
				<div style="margin: 0 auto; text-align: center;">
					<el-pagination background @current-change="handleCurrentChange" :current-page.sync="PageNO"
						:page-size="PageSize" layout="total, prev, pager, next" :total="Total">
					</el-pagination>
				</div>
			</div>
		</el-card>
	</div>
</template>
<script>
	export default {
		name: 'AttendanceRecord',
		mounted() {
			this.Attendance()
		},
		data() {
			return {
				CompanyAuditStatus: -99, //审核状态
				CompanyAuditOptions: [{
					value: -99,
					label: '-审核状态-'
				}, {
					value: 0,
					label: '待审核'
				}, {
					value: 1,
					label: '已审核'
				}],
				// FKStatus: -99, //付款状态
				// FKStatusOptions: [{
				// 	value: -99,
				// 	label: '全部'
				// }, {
				// 	value: 0,
				// 	label: '未付款'
				// }, {
				// 	value: 1,
				// 	label: '已付款'
				// }],
				StartTime: '', //开始时间 2023-03-01 00:00:00
				EndTime: '', //结束时间 2023-08-14 23:59:59 
				DayValue: [], //日期组件 '2023-03-01 00:00:00', '2023-08-14 23:59:59'
				pickerOptions: {
					shortcuts: [{
							text: "本月",
							onClick(picker) {
								const end = new Date();
								const start = new Date();
								start.setDate(1);
								picker.$emit("pick", [start, end]);
							}
						},
						{
							text: "上月",
							onClick(picker) {
								const start = new Date();
								const end = new Date(start);
								end.setMonth(start.getMonth());
								start.setMonth(start.getMonth() - 1);
								end.setDate(0);
								start.setDate(1);
								picker.$emit("pick", [start, end]);
							}
						},
						{
							text: "上上月",
							onClick(picker) {
								const start = new Date();
								const end = new Date(start);
								end.setMonth(start.getMonth() - 1);
								start.setMonth(start.getMonth() - 2);
								end.setDate(0);
								start.setDate(1);
								picker.$emit("pick", [start, end]);
							}
						}
					]
				},
				AttendanceName: '',
				AuditBilllists: [], //列表数据
				PageNO: 1, //当前页数
				PageSize: 20, //每页条数
				Total: 1000, //总条数
				EditIndex: null, //修改按钮加载loading
				LookIndex: null, //查看按钮加载loading
			}
		},
		methods: {
			//核对状态
			StatusValueChange(e) {
				// console.log(e)
				this.CompanyAuditStatus = e
			},
			//付款状态
			FKStatusValueChange(e) {
				// console.log(e)
				this.FKStatus = e
			},
			// 日期发生改变后
			DateChange(e) {
				// console.log(e[0], e[1])
				this.StartTime = e[0] + ' 00:00:00'
				this.EndTime = e[1] + ' 23:59:59'
			},
			// 当前页发生改变时
			handleCurrentChange: function(currentPage, index) {
				this.PageNO = currentPage;
				// console.log(this.PageNO, index)
				this.Attendance()
			},
			// 搜索
			searchange() {
				this.Attendance()
			},
			// 考勤对账单
			Attendance() {
				let Token = localStorage.getItem('Token')
				let that = this
				this.$axios.defaults.headers.common["x-Token"] = Token
				this.$axios.post('/WorkAttendance/Attendance/FinanceOrderList', {
					"Data": {
						"WorkHourAudit": this.CompanyAuditStatus, //核对状态
						"PayStatus": -99, //付款状态 this.FKStatus
						"StartDt": this.StartTime, //开始时间
						"EndDT": this.EndTime, //结束时间
						"QST": "",
						"SortDirection": 1, //排序方向
						"PageNo": this.PageNO, //当前页数
						"PageSize": this.PageSize, //每页条数
					}
				}).then(function(res) {
					console.log(res.data)
					if (res.data.Code == 0) {
						that.Total = res.data.Data.TotalRecordQty
						if (that.Total == 0) {
							that.$message({
								type: 'info',
								message: res.data.Data.Msg
							})
						} else {
							that.AuditBilllists = res.data.Data.Items
						}
					} else {
						if (res.data.Code == 202) {
							that.$router.push("/login");
						} else {
							that.$message({
								type: 'info',
								message: res.data.Message
							})
						}
					}
				})
			},
			// 修改核对状态
			EditChange(index, Id, CompanyAuditStatus) {
				this.EditIndex = index
				this.$confirm('此操作为您已查看考勤明细并确定考勤信息无误，且无法撤回审核状态，是否继续?', '提示', {
					confirmButtonText: '继续',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					this.update(Id, CompanyAuditStatus)
				}).catch(() => {
					this.EditIndex = null
					this.$message({
						type: 'info',
						message: '已取消'
					});
				});
			},
			update(Id, CompanyAuditStatus) {
				var Status = null;
				// console.log(Id, CompanyAuditStatus)
				if (CompanyAuditStatus == 1) {
					Status = 0
				} else {
					Status = 1
				}
				let Token = localStorage.getItem('Token')
				let that = this
				this.$axios.defaults.headers.common["x-Token"] = Token
				this.$axios.post('/WorkAttendanceFinance/Update', {
					"Data": {
						"Status": Status, //审核状态
						"Id": Id,
					}
				}).then(function(res) {
					// console.log(res.data)
					if (res.data.Code == 0) {
						that.$message({
							type: 'success',
							message: '修改成功!'
						});
						that.EditIndex = null;
						that.AuditBilllists = [];
						that.Attendance();
					} else {
						if (res.data.Code == 202) {
							that.$router.push("/login");
						} else {
							that.$message({
								type: 'info',
								message: res.data.Message
							})
						}
					}
				})
			},
			// 打开明细弹窗
			LookChange(index, row) {
				this.LookIndex = index
				// console.log(row)
				// this.LookAuditForm.StartTime = row.OrderStart
				// this.LookAuditForm.EndTime = row.OrderEnd
				//跳转到发布岗位页面
				setTimeout(() => {
					this.$router.push({
						name: "LookAudit",
						params: {
							StartTime: row.OrderStart,
							EndTime: row.OrderEnd,
							EnterpriseID: row.CompanyID,
							EnterpriseName: row.CompanyName,
							FinanceNo: row.FinanceNo,
							FinanceName: row.FinanceName
						}
					})
				}, 1000)
				// 加入keepalive缓存
				this.$store.commit('addKeepAliveCache', 'LookAudit')
				var submenu = {
					Url: '/LookAudit',
					Code: 'LookAudit',
					Name: '对账单明细'
				}
				//修改选中菜单
				this.$store.commit('selectMenu', submenu)
				this.LookIndex = null

				// this.LookAuditBill(row.OrderStart, row.OrderEnd)
			},
		}
	}
</script>
<style lang="less" scoped>
	/* 头部标题 */
	.header {
		align-items: center;
		justify-content: space-between;
	}

	.demo-input-suffix {
		display: flex;
	}

	.el-breadcrumb__item {
		display: flex;
	}

	/* 搜索框 */
	.seachInpt {
		margin-right: 10px;
	}

	.jobname {
		width: 200px;
		white-space: nowrap;
		text-overflow: ellipsis;
		overflow: hidden;

	}

	.el-form-item__content {
		margin-left: 10px !important;
	}
</style>