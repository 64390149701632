<template>
	<div id="Authority">
		<el-card>
			<div class="demo-input-suffix header">
				<el-breadcrumb separator="/">
					<el-breadcrumb-item><i class="el-icon-date"></i>系统管理</el-breadcrumb-item>
					<el-breadcrumb-item>权限</el-breadcrumb-item>
				</el-breadcrumb>
				<div class="demo-input-suffix">
					<el-input class="seachInpt" placeholder="请输入关键字" v-model="RoleName" clearable @change='NameChange'>
					</el-input>
					<el-button size="mini" type="primary" @click='searchange'>
						<i class="el-icon-search"></i>
						搜索
					</el-button>
					<el-button size="mini" type="danger" @click="addRole()">
						<i class="el-icon-plus"></i>
						新增
					</el-button>
					<el-dialog title="新增角色" :visible.sync="dialogTableVisible" center :append-to-body='true'
						:lock-scroll="false" width="50%">
						<el-form label-position="right" label-width="80px" :model="form" status-icon ref="form">
							<el-form-item label="角色名" prop="Name" :rules="[
          			{ required: true, message: '请输入角色名', trigger: 'blur' }
          		]">
								<el-input type="text" status-icon v-model="form.Name" placeholder="请输入角色名"></el-input>
							</el-form-item>
							<el-form-item prop="Remark" label="备注" :rules="[
          			{ required: true, message: '请输入备注', trigger: 'blur' }
          		]">
								<el-input type="text" status-icon v-model="form.Remark" placeholder="请输入备注"></el-input>
							</el-form-item>
							<el-form-item label="权限分配" prop="audituser">
								<el-input type="text" placeholder="请选择权限" v-model="form.audituser"
									@click.native="openTree"></el-input>
								<!--选择权限 -->
								<el-dialog width="30%" title="请选择权限" :visible.sync="ischoosedept" append-to-body>
									<div class="choose-dialog-main" style="overflow:auto">
										<el-tree class="filter-tree" node-key="ID" default-expand-all
											:props="defaultProps" :data="deptlist" style="height:250px" show-checkbox
											:default-checked-keys="PermissionID" ref="tree"
											@check-change='clickchange'></el-tree>
									</div>
									<span slot="footer" class="dialog-footer">
										<div class="add-dialog-footer">
											<el-button type="default"
												style="width:150px;height:30px;line-height:5px;background-color:#71ae86;color:#fff"
												@click="AddAudituser">确认
											</el-button>
											<el-button type="default" style="width:150px;height:30px;line-height:5px;"
												@click="ischoosedept=false">取消</el-button>
										</div>
									</span>
								</el-dialog>
							</el-form-item>
						</el-form>
						<div slot="footer" class="dialog-footer">
							<el-button @click="dialogTableVisible = false">取 消</el-button>
							<el-button type="primary" @click="AddRoleFrom('form')">确定</el-button>
						</div>
					</el-dialog>
				</div>
			</div>
			<div class="container">
				<el-table style="width: 100%;margin-bottom:70px;" :data="RoleList" ref="multipleTable"
					tooltip-effect="dark" :border="true">
					<el-table-column type="index">
					</el-table-column>
					<el-table-column label="角色名" prop="Name">
					</el-table-column>
					<el-table-column label="备注" prop="Remark"></el-table-column>
					<el-table-column label="是否启用" prop="IsEnabled">
						<template slot-scope="scope">
							<el-switch v-model="scope.row.IsEnabled"
								@change="switchChange(scope.row.IsEnabled, scope.row.Id)">
							</el-switch>
						</template>
					</el-table-column>
					<el-table-column align="right" width="180">
						<template slot="header" slot-scope="scope">
							<label>操作</label>
						</template>
						<template slot-scope="scope">
							<el-button size="mini" @click="handleEdit(scope.$index, scope.row)"
								style="margin-right: 5px;">查看
							</el-button>
							<el-dialog title="查看角色" :visible.sync="updateTableVisible" center :append-to-body='true'
								:lock-scroll="false" width="50%">
								<el-form label-position="right" label-width="80px" :model="upDate" status-icon
									ref="upDate">
									<el-form-item label="角色名" prop="Name" :rules="[
                		{ required: true, message: '请输入角色名', trigger: 'blur' }
                	]">
										<el-input type="text" status-icon v-model="upDate.Name" :disabled="true"
											placeholder="请输入角色名"></el-input>
									</el-form-item>
									<el-form-item prop="Remark" label="备注" :rules="[
                		{ required: true, message: '请输入备注', trigger: 'blur' }
                	]">
										<el-input type="text" status-icon :disabled="true" v-model="upDate.Remark"
											placeholder="请输入备注"></el-input>
									</el-form-item>
									<el-form-item label="权限分配" prop="audituser">
										<el-input type="text" placeholder="请选择权限" v-model="upDate.audituser"
											@click.native="openTree">
										</el-input>
										<!--选择权限 -->
										<el-dialog width="30%" title="查看权限" :visible.sync="ischoosedept" append-to-body>
											<div class="choose-dialog-main" style="overflow:auto">
												<el-tree class="filter-tree" node-key="ID" :props="upDatedefaultProps"
													:data="upDatedeptlist" style="height:250px" show-checkbox
													default-expand-all :default-checked-keys="upDatePermissionID"
													ref="tree"></el-tree>
											</div>
											<span slot="footer" class="dialog-footer">
												<div class="add-dialog-footer">
													<el-button type="default"
														style="width:150px;height:30px;line-height:5px;background-color:#71ae86;color:#fff"
														@click="ischoosedept=false">确认
													</el-button>
													<!-- <el-button type="default" style="width:150px;height:30px;line-height:5px;"
                            @click="ischoosedept=false">取消</el-button> -->
												</div>
											</span>
										</el-dialog>
									</el-form-item>
								</el-form>
								<div slot="footer" class="dialog-footer">
									<!-- <el-button @click="updateTableVisible = false">取 消</el-button> -->
									<el-button type="primary" @click="updateTableVisible = false">确定</el-button>
								</div>
							</el-dialog>
							<el-button size="mini" type="danger" plain
								@click="handleDelete(scope.$index, scope.row.Id)">删除</el-button>
						</template>
					</el-table-column>
				</el-table>
				<!-- 分页 -->
				<div style="width: 300px;margin: 0 auto;">
					<el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
						:current-page="currentPage" :page-sizes="[10, 20, 30, 40]" :page-size="pagesize"
						layout="total, sizes, prev, pager, next, jumper" :total="TotalRecordQty">
					</el-pagination>
				</div>
			</div>
		</el-card>
	</div>
</template>

<script>
	export default {
		name: 'Authority',
		//组件创建
		created() {
			this.Authority()
		},
		mounted() {
			this.$nextTick(() => {
				this.loadperson(); //查询权限渲染
			})
		},
		data() {
			return {
				RoleName: '', //搜索关键字
				RoleList: [], //用户列表
				TotalRecordQty: 10, //总条数
				currentPage: 1, //初始页
				pagesize: 10, //每页的数据
				dialogTableVisible: false, //新增弹框
				form: {
					Name: '', //角色名
					Remark: '', //备注
					audituser: '', //权限
				},
				ischoosedept: false, //新增模态框
				deptlist: [], //展示数据
				defaultProps: { //配置选项
					children: 'Children',
					label: 'Name',
				},
				PermissionID: [], //角色拥有所有功能权限ID
				MenuIds: [],
				updateTableVisible: false, //查看模态框
				upDate: {
					Name: '', //角色名
					Remark: '', //备注
					audituser: '', //权限
				},
				upDatePermissionID: [], //角色拥有所有功能权限ID
				upDatedeptlist: [], //展示数据
				upDatedefaultProps: { //配置选项
					children: 'Children',
					label: 'Name',
					checkStrictly: true,
					disabled: () => true
				},
			}
		},
		methods: {
			// 条数
			indexMethod(index) {
				if (this.currentPage == 1) {
					return index * 1 + 1;
				} else {
					return (index + this.currentPage - 1) * 10 + 1
				}
			},
			//获取角色
			NameChange(e) {
				// console.log(e)
				this.RoleName = e
			},
			//搜索
			searchange() {
				this.Authority()
			},
			// 初始页currentPage、初始每页数据数pagesize和数据data
			handleSizeChange: function(size) {
				this.pagesize = size;
				// console.log(this.pagesize) //每页下拉显示数据
				this.Authority()
			},
			handleCurrentChange: function(currentPage, index) {
				this.currentPage = currentPage;
				// console.log(this.currentPage, index) //点击第几页
				this.Authority()
			},
			// 获取用户列表
			Authority() {
				let Token = localStorage.getItem('Token')
				let that = this
				this.$axios.defaults.headers.common["x-Token"] = Token
				this.$axios.post('/Role/List', {
					"Data": {
						"PageNO": that.currentPage, //页索引
						"PageSize": that.pagesize, //每页数量
						"SortDirection": 1, //排序，1倒叙，0升序
						"QST": that.RoleName //按角色名模糊查询
					}
				}).then(function(res) {
					console.log(res)
					console.log(res.data)
					if (res.data.Code == 0) {
						that.RoleList = res.data.Data.Items
						that.TotalRecordQty = res.data.Data.TotalRecordQty
					} else {
						if (res.data.Code == 202) {
							that.$router.push("/login");
						}
						// else {
						// 	that.$message({
						// 		type: 'info',
						// 		message: res.data.Message
						// 	})
						// }
					}
				})
			},
			//是否启用
			switchChange(IsEnabled, Id) {
				console.log(IsEnabled, Id)
				let Token = localStorage.getItem('Token')
				let that = this
				this.$axios.defaults.headers.common["x-Token"] = Token
				this.$axios.post('/Role/EnableOrDisable', {
					"Data": {
						"ID": Id, //角色ID
						"Enabled": IsEnabled, // 启用/停用
					}
				}).then(function(res) {
					console.log(res.data)
					if (res.data.Code == 0) {
						that.Authority()
					} else {
						if (res.data.Code == 202) {
							that.$router.push("/login");
						}
						// else {
						// 	that.$message({
						// 		type: 'info',
						// 		message: res.data.Message
						// 	})
						// }
					}
				})
			},
			//新增弹框
			addRole() {
				this.dialogTableVisible = true; //默认页面不显示为false,点击按钮将这个属性变成true
			},
			// 新增角色
			openTree() {
				this.ischoosedept = true //打开新增权限模态框
			},
			// 新增权限
			loadperson() {
				let Token = localStorage.getItem('Token')
				let that = this
				this.$axios.defaults.headers.common["x-Token"] = Token
				this.$axios.post('/Role/Permission/All', {
					"Data": ''
				}).then(function(res) {
					if (res.data.Code == 0) {
						// console.log(res.data)
						that.deptlist = res.data.Data.Menus
					} else {
						if (res.data.Code == 202) {
							that.$router.push("/login");
						}
						//  else {
						// 	that.$message({
						// 		type: 'info',
						// 		message: res.data.Message
						// 	})
						// }
					}
				})
			},
			//获取权限id
			clickchange() {
				let res = this.$refs.tree.getCheckedNodes()
				// console.log(res)
				let arr = res
					.map(item => {
						return item.ID
					})
				let PersonArr = res
					.map(item => {
						return item.ParentID
					})
				let a = arr.concat(PersonArr)
				let r = [...new Set(a)]
				for (let i in r) {
					if (r[i] == null) {
						r.splice(i, 1)
						i = i - 1
					}
					console.log(r[i])
				}
				// console.log(r)
				// console.log('PermissionID', arr)
				// console.log('ParentID', PersonArr)
				this.PermissionID = arr
				this.MenuIds = r
			},
			// 提交权限
			AddAudituser() {
				// console.log('已选中的权限id：', this.PermissionID)
				// console.log('已选中id：', this.MenuIds)
				if (this.PermissionID.length == 0) {
					this.form.audituser = ''
				} else {
					this.form.audituser = '......'
				}
				this.ischoosedept = false
			},
			//提交新增
			AddRoleFrom(formName) {
				this.$refs[formName].validate((valid) => {
					if (valid) {
						let that = this
						let Token = localStorage.getItem('Token')
						this.$axios.defaults.headers.common["x-Token"] = Token
						this.$axios.post('/Role/Add', {
							"Data": {
								"Name": that.form.Name,
								"Remark": that.form.Remark,
								"MenuIds": that.MenuIds
							}
						}).then((res) => {
							console.log(res.data)
							if (res.data.Code == 0) {
								that.$message({
									type: 'success',
									message: res.data.Message
								})
							} else {
								if (res.data.Code == 202) {
									that.$router.push("/login");
								} else {
									that.$message({
										type: 'info',
										message: res.data.Message
									})
								}
							}
							this.dialogTableVisible = false;
							this.$refs[formName].resetFields();
							that.Authority()
							//刷新当前页面
							this.reload();
						})
					} else {
						// console.log('error submit!!');
						return false;
					}
				});
			},
			//查看
			handleEdit(index, row) {
				// console.log(index, row);
				let Token = localStorage.getItem('Token')
				let that = this
				this.$axios.defaults.headers.common["x-Token"] = Token
				this.$axios.post('/Role/Permission/Get', { //获取待修改信息
					"Data": {
						"RoleId": row.Id
					}
				}).then(function(res) {
					if (res.data.Code == 0) {
						// console.log(res.data)
						that.updateTableVisible = true
						that.upDate.Name = row.Name

						if (row.Remark == null) {
							that.upDate.Remark = '暂无备注'
						} else {
							that.upDate.Remark = row.Remark
						}
						that.upDatePermissionID = res.data.Data.MenuPermissions
						that.upDatedeptlist = res.data.Data.Menus
						if (that.upDatePermissionID.length == 0) {
							that.upDate.audituser = '暂无权限'
						} else {
							that.upDate.audituser = '......'
						}
					} else {
						if (res.data.Code == 202) {
							that.$router.push("/login");
						}
						// else {
						// 	that.$message({
						// 		type: 'info',
						// 		message: res.data.Message
						// 	})
						// }
					}
				})
			},
			// 删除
			handleDelete(index, row) {
				// console.log(index, row);
				this.$confirm('此操作将永久删除该角色, 是否继续?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning',
					center: true
				}).then(() => {
					let that = this
					let Token = localStorage.getItem('Token')
					this.$axios.defaults.headers.common["x-Token"] = Token
					this.$axios.post('/Role/Delete', {
						"Data": row
					}).then((res) => {
						if (res.data.Code == 0) {
							that.$message({
								type: 'success',
								message: res.data.Message
							})
						} else {
							if (res.data.Code == 202) {
								that.$router.push("/login");
							} else {
								that.$message({
									type: 'info',
									message: res.data.Message
								})
							}
						}
						that.Authority()
					})
				})
			}
		}
	}
</script>
<style lang="less" scoped>
	/* 头部标题 */
	.header {
		align-items: center;
		justify-content: space-between;
	}

	.demo-input-suffix {
		display: flex;
	}

	.el-breadcrumb__item {
		display: flex;
	}

	/* 搜索框 */
	.seachInpt {
		margin-right: 10px;
	}
</style>