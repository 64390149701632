<template>
	<div id="AttendanceList">
		<el-card>
			<div class="demo-input-suffix header">
				<el-breadcrumb separator="/">
					<el-breadcrumb-item><i class="el-icon-date"></i> 首页</el-breadcrumb-item>
					<el-breadcrumb-item>考勤记录</el-breadcrumb-item>
				</el-breadcrumb>
				<div class="demo-input-suffix">
					<el-select v-model="WorkHourAudit" placeholder="请选择" style="margin-right: 10px;width:100px;"
						@change="StatusValueChange">
						<el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
						</el-option>
					</el-select>
					<div style="display: flex;align-items: center;margin-right: 10px;">
						<el-date-picker style="width:190px;" v-model="StartTime" type="date" placeholder="选择开始日期"
							format="yyyy 年 MM 月 dd 日" value-format="yyyy-MM-dd 00:00:00">
						</el-date-picker>
						<span style="display: inline-block;margin: 0 3px;">-</span>
						<el-date-picker style="width:190px;" v-model="EndTime" type="date" placeholder="选择结束日期"
							format="yyyy 年 MM 月 dd 日" value-format="yyyy-MM-dd 23:59:59">
						</el-date-picker>
					</div>
					<el-input style="width: 255px;" class="seachInpt" placeholder="请输入搜索关键字(用户名,岗位)"
						v-model="AttendanceName" clearable @change='NameChange'>
					</el-input>
					<el-button size="mini" type="primary" @click='searchange'>
						<i class="el-icon-search"></i>
						搜索
					</el-button>
				</div>
			</div>
			<div class="container">
				<el-table style="width: 100%;margin-bottom:70px;" :data="AttenList" ref="multipleTable"
					tooltip-effect="dark" :border="true">
					<el-table-column type="index">
					</el-table-column>
					<el-table-column label="岗位" prop="JobName" width="200">
						<template slot-scope="scope">
							<span class="jobname">{{scope.row.JobName}}</span>
						</template>
					</el-table-column>
					<el-table-column label="所属部门" prop="DeptName" width="180">
						<template slot-scope="scope">
							<span style="white-space: nowrap;">{{scope.row.DeptName}}</span>
						</template>
					</el-table-column>
					<el-table-column label="职位金额(元/小时)" prop="ContractSalary" width="140"></el-table-column>
					<el-table-column label="用户" width="80">
						<template slot-scope="scope">
							<el-tag size="medium" @click="CUserInfo(scope.row.CUserID)">
								{{scope.row.UserName}}
							</el-tag>
						</template>
					</el-table-column>
					<el-table-column label="上班时间" width="200">
						<template slot-scope="scope" v-if="scope.row.WorkTime!=null">
							{{ scope.row.WorkTime}}
						</template>
					</el-table-column>
					<el-table-column label="下班时间" width="200">
						<template slot-scope="scope" v-if="scope.row.OffWorkTime!=null">
							{{ scope.row.OffWorkTime}}
						</template>
					</el-table-column>
					<el-table-column label="当前状态" prop="StatusTxt"></el-table-column>
					<el-table-column label="系统工时(/小时)" prop="WorkHour" width="140"></el-table-column>
					<el-table-column label="确认工时(/小时)" prop="ConfirmWorkHour" width="140"></el-table-column>
					<el-table-column label="是否早退" prop="IsZhaoTuiTxt"></el-table-column>
					<el-table-column label="是否迟到" prop="IsLateTxt"></el-table-column>
					<el-table-column label="是否加班" prop="IsOverTimeTxt"></el-table-column>
					<el-table-column label="加班时长(/小时)" prop="OverTimeHour" width="140"></el-table-column>
					<el-table-column label="审核人" prop="Auditor" width="100"></el-table-column>
					<el-table-column label="审核状态" prop="WorkHourAuditTxt"></el-table-column>
					<el-table-column label="审核时间" prop="AuditTime" width="200"></el-table-column>
					<el-table-column label="审核备注" prop="Remark" width="240"></el-table-column>
				</el-table>
				<!-- 分页 -->
				<div style="width: 300px;margin: 0 auto;">
					<el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
						:current-page="currentPage" :page-sizes="[10, 20, 30, 40]" :page-size="pagesize"
						layout="total, sizes, prev, pager, next, jumper" :total="TotalRecordQty">
					</el-pagination>
				</div>
			</div>
		</el-card>
	</div>
</template>

<script>
	export default {
		name: 'AttendanceList',
		created() {
			var _date = new Date()
			var Y = _date.getFullYear()
			var m = _date.getMonth() + 1
			var d = _date.getDate()
			m = m < 10 ? '0' + m : m;
			d = d < 10 ? '0' + d : d;
			this.StartTime = Y + '-' + m + '-' + d + ' 00:00:00'
			this.EndTime = Y + '-' + m + '-' + d + ' 23:59:59'
			this.AllList()
		},
		data() {
			return {
				WorkHourAudit: 0, //审核状态
				options: [{
					value: 0,
					label: '全部'
				}, {
					value: 1,
					label: '待审核'
				}, {
					value: 2,
					label: '已审核'
				}],
				AttendanceName: '', //关键字
				AttenList: [], //列表
				StartTime: '', //开始时间
				EndTime: '', //结束时间
				TotalRecordQty: 0, //总条数
				currentPage: 1, //初始页
				pagesize: 10, //每页的数据
			}
		},
		mounted() {},
		methods: {
			//个人考勤记录
			CUserInfo(id) {
				this.$router.push(`/CuseAttendance?userId=${id}`)
				// // 加入keepalive缓存
				this.$store.commit('addKeepAliveCache', 'CuseAttendance')
				var submenu = {
					Url: '/CuseAttendance',
					Code: '/CuseAttendance',
					Name: '个人考勤记录'
				}
				//修改选中菜单
				this.$store.commit('selectMenu', submenu)
			},
			//状态
			StatusValueChange(e) {
				// console.log(e)
				this.WorkHourAudit = e
			},
			//获取关键字
			NameChange(e) {
				// console.log(e)
				this.AttendanceName = e
			},
			//搜索
			searchange() {
				this.AllList()
			},
			// 初始页currentPage、初始每页数据数pagesize和数据data
			handleSizeChange: function(size) {
				this.pagesize = size;
				// console.log(this.pagesize) //每页下拉显示数据
				this.AllList()
			},
			handleCurrentChange: function(currentPage, index) {
				this.currentPage = currentPage;
				// console.log(this.currentPage, index) //点击第几页
				this.AllList()
			},
			//所有考勤记录
			AllList() {
				let Token = localStorage.getItem('Token')
				let that = this
				this.$axios.defaults.headers.common["x-Token"] = Token
				this.$axios.post('/WorkAttendance/Attendance/AllList', {
					"Data": {
						"WorkHourAudit": this.WorkHourAudit, //审核状态
						"StartTime": this.StartTime, //开始时间
						"EndTime": this.EndTime, //结束时间
						"QST": this.AttendanceName,
						"SortDirection": 1,
						"PageNo": this.currentPage,
						"PageSize": this.pagesize
					}
				}).then(function(res) {
					console.log(res.data)
					if (res.data.Code == 0) {
						that.AttenList = res.data.Data.Items
						console.log(that.AttenList)
						that.TotalRecordQty = res.data.Data.TotalRecordQty
						if (that.TotalRecordQty == 0) {
							that.$message({
								type: 'info',
								message: res.data.Data.Msg
							})
						}
					} else {
						if (res.data.Code == 202) {
							that.$router.push("/login");
						} else {
							that.$message({
								type: 'info',
								message: res.data.Message
							})
						}
						
					}
				})
			}
		}
	}
</script>

<style lang="less" scoped>
	/* 头部标题 */
	.header {
		align-items: center;
		justify-content: space-between;
	}

	.demo-input-suffix {
		display: flex;
	}

	.el-breadcrumb__item {
		display: flex;
	}

	/* 搜索框 */
	.seachInpt {
		margin-right: 10px;
	}

	.jobname {
		width: 200px;
		white-space: nowrap;
		text-overflow: ellipsis;
		overflow: hidden;
	}
</style>