<template>

	<el-container class="home-container">

		<!-- 侧边栏 -->
		<el-aside :width="isCollapse ? '64px' : '230px'" style="padding-top: 10px;">
			<!-- 头部logo -->
			<template>
				<div class="header-title" v-if="!isCollapse">
					<img src="../assets/msh22.png" width="163px" height="50px" style="border-radius: 5px" />
					<!-- <img width="35px" height="35px" src="../assets/logo.png" style="border-radius: 5px">
          <span style="font-weight:bold">导航页切换示例</span> -->
				</div>
				<div class="header-title-hiddle" v-else>
					<img src="../assets/msh22.png" width="163px" height="50px" style="border-radius: 5px" />
				</div>
			</template>

			<!-- 菜单区域 --><!-- background-color="#282C34" text-color="#fff" -->
			<el-menu active-text-color="#fff" :collapse="isCollapse" :collapse-transition="false"
				:default-active="'/' + activePath" unique-opened router>
				<!-- 首页 -->
				<div v-for="item in menuList" :key="item.ID">
					<el-menu-item :index="item.Url" @click="selectMenu(item)" v-if="item.Children.length==0">
						<i :class="item.CssCls"></i>
						<span slot="title">{{item.Name}}</span>
					</el-menu-item>
					<el-submenu :index="item.Index" :key="item.Url" :id="item.ID" v-else>
						<template slot="title">
							<i :class="item.CssCls"></i>
							<span slot="title">{{item.Name}}</span>
							<!-- <el-badge is-dot class="item"></el-badge> --><!--红点 -->
						</template>
						<el-menu-item class="menuItem" :id='childVal.ID' v-for="childVal in item.Children"
							:key="childVal.ID" :index="childVal.Url" @click='selectMenu(childVal)'>
							<i :class="childVal.CssCls"></i>
							<span slot="title">{{childVal.Name}}</span>
							<!-- <el-badge :value="200" :max="99" class="item" style="float:right"></el-badge> --><!--数字标 -->
						</el-menu-item>
					</el-submenu>
				</div>
			</el-menu>
		</el-aside>

		<!-- 主体 -->
		<el-container>
			<!-- 头部 -->
			<el-header>

				<!-- 头部导航栏 -->
				<div class="header-row">
					<!-- 折叠 展开 和 名人名言 -->
					<div class="toggle-button" style="width:100%;">
						<!-- <div>
              <i :title="isCollapse ? '展开' : '收起'" class="fa fa-bars" @click="toggleCollapse"></i>
            </div> -->
						<div style="height:25px;width:100%;margin-left:10px">
							<div style="float:left;color:#E74405;font-size:16px;height:25px;line-height:25px;">
								<i class="fa fa-bullhorn"></i>
							</div>
							<el-carousel height="25px" direction="vertical" indicator-position="none" autoplay
								:interval="3000">
								<el-carousel-item v-for="item in mottoList" :key="item">
									<h3 class="medium">{{ item }}</h3>
								</el-carousel-item>
							</el-carousel>
						</div>
					</div>

					<!-- 头像下拉菜单 -->
					<div class="header-avatar">
						<div style="font-size: 12px;margin: 0 10px;color: #f56c6c;padding: 5px;">
							<strong>{{RemindStr}}</strong>
						</div>
						<div class="user">
							{{AdminName}}，您好！
						</div>
						<el-dropdown @command="handleCommand">
							<span class="el-dropdown-link">
								<img width="35" height="35" style="border-radius:50%;background:#dddddd"
									src="../assets/images/index/user3.png" alt="">
								<i class="el-icon-arrow-down el-icon--right"></i>
							</span>
							<el-dropdown-menu slot="dropdown">
								<el-dropdown-item command="update-password">修改密码</el-dropdown-item>
								<el-dropdown-item command="logout">退出登录</el-dropdown-item>
							</el-dropdown-menu>
						</el-dropdown>
					</div>
				</div>

				<!-- tab标签页区域 - 用于标签页切换 -->
				<div class="tabs-switch-page">
					<el-tag size="medium" v-for="(tab, index) in tabList" :key="tab.Code"
						@close="handleClose(tab, index)" @click="changeMenu(tab)" :closable="tab.Code !== 'home'"
						:effect="activePath === tab.Code ? 'dark' : 'plain'">
						{{tab.Name}}
					</el-tag>
				</div>
			</el-header>
			<!-- 内容区 -->
			<el-main style="">
				<!-- 路由占位符，用于展示内容区的内容 -->
				<div style="padding:15px;min-height: 500px;">
					<keep-alive :include="catch_components">
						<router-view v-if="isRouterAlive" />
					</keep-alive>
				</div>
			</el-main>
		</el-container>
	</el-container>
</template>

<script>
	import {
		mapState,
		mapMutations
	} from 'vuex';
	export default {
		components: {},
		//组件被创建
		created() {
			//待办
			this.Remind()
			//加载菜单
			this.loadMenu();
		},
		provide() {
			return {
				reload: this.reload
			}
		},
		computed: {
			...mapState({ // 从 state 中的到的计算属性
				activePath: state => state.activePath, // 已选中菜单
				tabList: state => state.tabList, // tags菜单列表
				catch_components: state => state.catch_components, // keepalive缓存
			})
		},
		data() {
			return {
				AdminName: '', //管理员名
				isRouterAlive: true,
				//菜单列表
				menuList: [],
				// 折叠-展开 默认false不折叠
				isCollapse: false,
				// 系统公告
				mottoList: [
					'【谋生活】灵活就业及企业用工灵活化服务SaaS服务平台',
					'【谋生活】帮助企业降低用工成本、提高用工效率、规避用工风险',
					'【谋生活】AI智能移动端考勤、工时全流程管控，人员实名制管理',
					'【谋生活】企业自主发单，个人随时接单，工资实时计算、费用在线核算',
					'【谋生活】平台操作简单，招工信息一键发布，工人信息智能匹配',
					'【谋生活】海量工人实名注册，身份信息实名认证，大数据精准撮合',
					'【谋生活】将雇佣数据透明化、数据化，给用户精准定位',
					'【谋生活】全程管控任务审核、任务结算，客服适时监控',
					'【谋生活】全局的数据化管控系统自动形成结算数据等可视化报表，有助于企业对结算数据的管理和分析'
				],
				RemindStr: ''
			}
		},
		methods: {
			reload() {
				this.isRouterAlive = false
				this.$nextTick(function() {
					this.isRouterAlive = true
				})
			},
			// 右上角下拉菜单点击事件
			handleCommand(command) {
				switch (command) {
					// 退出
					case 'logout':
						//消息提示
						this.$confirm("退出登录,是否继续?", "提示", {
							confirmButtonText: "确定",
							canceButtonText: "取消",
							type: "warning"
						}).then(() => {
							let Token = localStorage.getItem('Token')
							var that = this
							this.$axios.defaults.headers.common["x-Token"] = Token
							this.$axios.post('/User/LoginOut', {
								"Data": ''
							}).then(function(res) {
								if (res.data.Code == 0) {
									console.log(res.data)
									that.$message({
										showClose: true,
										message: res.data.Message,
										type: 'success'
									});
									//重置vuex中的数据
									that.$store.commit('clearVUEX')
									// 删除保存的token以及user
									window.localStorage.clear()
									//跳转到登录页
									that.$router.push("/login");
									// setTimeout(() => {

									// }, 1000)
								} else {
									if (res.data.Code == 202) {
										that.$router.push("/login");
									} else {
										that.$message({
											type: 'info',
											message: res.data.Message
										})
									}
								}
							})
						}).catch(() => {

						})

						break
						//修改密码
					case 'update-password':
						//消息提示
						this.$message({
							message: '修改密码',
							type: 'warning'
						});
						//重置vuex中的数据
						this.$store.commit('clearVUEX')
						// 跳转到修改密码
						this.$router.push("/forget");
						// setTimeout(() => {

						// }, 1000)

						break
				}
			},
			// 点击折叠 展开菜单
			toggleCollapse() {
				this.isCollapse = !this.isCollapse;
			},
			// 点击菜单 - 传入Code，添加到keepalive缓存页面
			selectMenu(item) {
				// 加入keepalive缓存
				this.$store.commit('addKeepAliveCache', item.Code)
				//添加tags标签
				//访问wellcome 就代表home
				var Code = item.Code === 'wellcome' ? 'home' : item.Code
				var submenu = {
					Url: item.Url,
					Code: Code,
					Name: item.Name
				}
				//修改选中菜单
				this.$store.commit('selectMenu', submenu)
			},
			// 关闭tab标签
			handleClose(tab, index) {
				// 历史选中菜单
				var oldActivePath = this.$store.state.activePath
				// 历史已选中菜单列表
				var oldTabList = this.$store.state.tabList
				// 计算标签个数
				let length = oldTabList.length - 1
				// 删除tabList中的该对象

				for (let i = 0; i < oldTabList.length; i++) {
					let item = oldTabList[i]

					if (item.Code === tab.Code) {
						oldTabList.splice(i, 1);
					}
				}
				// 删除keepAlive缓存
				this.$store.commit('removeKeepAliveCache', tab.Code)
				// 如果关闭的标签不是当前路由的话，就不跳转
				if (tab.Code !== oldActivePath) {
					return
				}
				// 如果length为1，必然只剩下首页标签，此时关闭后，更新到首页
				if (length === 1) {
					// 同时存储菜单
					this.$store.commit('closeTab', {
						activePath: 'home',
						tabList: oldTabList
					})
					// tab页向左跳转
					this.$router.push(oldTabList[index - 1].Url)
					// 不再向下执行
					return
				}
				// 关闭的标签是最右边的话，往左边跳转一个
				if (index === length) {
					// 同时更新路径
					oldActivePath = oldTabList[index - 1].Code
					// 同时存储菜单
					this.$store.commit('closeTab', {
						activePath: oldActivePath,
						tabList: oldTabList
					})
					// tab页向左跳转
					this.$router.push(oldTabList[index - 1].Url)
				} else {
					// 同时更新路径
					oldActivePath = oldTabList[index].Code
					// 同时存储菜单
					this.$store.commit('closeTab', {
						activePath: oldActivePath,
						tabList: oldTabList
					})
					// tab页向右跳转
					this.$router.push(oldTabList[index].Url)
				}
			},
			// 点击标签跳转路由
			changeMenu(item) {
				// 历史选中菜单
				var oldActivePath = this.$store.state.activePath
				// 首先判断点击的是否是自己，如果是自己则return
				if (oldActivePath === item.Code) {
					return
				}
				// 不是自己，存储菜单
				this.$store.commit('changeMenu', item.Code)
				// 页面跳转
				this.$router.push(item.Url)
			},
			//加载菜单
			loadMenu() {
				let userInfo = JSON.parse(localStorage.getItem('DateInfo'))
				// console.log(userInfo)
				this.AdminName = userInfo.User.UserName

				let Token = localStorage.getItem('Token')
				let that = this

				this.$axios.defaults.headers.common["x-Token"] = Token
				this.$axios.post('/Menu/Get', {
					"Data": {
						"RoleId": userInfo.User.RoleId,
						"UserType": userInfo.User.UserType
					}
				}).then(function(res) {
					if (res.data.Code == 0) {
						console.log(res.data)
						that.menuList = res.data.Data.Menus
						localStorage.setItem('menuList', JSON.stringify(that.menuList))
						// console.log(localStorage.getItem('menuList'))

					} else if (res.data.Code == 202) {
						// if(res.data.Message=='访问令牌已失效或不存在.'){
						that.$message({
							type: 'info',
							message: '访问令牌已失效,请重新登录'
						})
						localStorage.clear()
						that.$router.push("/login");
						// setTimeout(() => {
						//   this.$router.push("/login");
						// }, 1000)
					} else {
						that.$message({
							type: 'info',
							message: res.data.Message
						})
					}
				})
			},
			//待办
			Remind() {
				let Token = localStorage.getItem('Token')
				let that = this
				this.$axios.defaults.headers.common["x-Token"] = Token
				this.$axios.post('/Remind', {
					"Data": {}
				}).then(function(res) {
					// console.log(res)
					if (res.data.Code == 0) {
						that.RemindStr = res.data.Data.RemindStr
					}  else {
						if (res.data.Code == 202) {
							that.$router.push("/login");
						} else {
							that.$message({
								type: 'info',
								message: res.data.Message
							})
						}
					}
				})
			}
		}
	}
</script>
<style lang="less" scoped>
	.el-col-align-middle {
		line-height: 40px;
		text-align: left;
		font-size: 14px;
	}

	.home-container {
		height: 100%;
	}

	.el-header {
		color: rgb(0, 0, 0);
		font-size: 20px;
		border-bottom: 1px solid #dddddd;
		height: 103px !important;
		padding: 0;
		background: #fff;
	}

	.header-row {
		height: 60px;
		width: 100%;
		display: flex;
		flex-direction: row;
		justify-content: center;
		border-bottom: 1px solid #dddddd;
		overflow: hidden;
	}

	.header-avatar {
		float: right;
		width: 50%;
		display: flex;
		align-items: center;
		justify-content: flex-end;
		padding-right: 20px;

		.user {
			font-size: 14px;
			font-weight: bold;
			padding: 0 10px;
		}
	}

	.el-aside {
		// background-color: #282C34;

		.header-title {
			padding-left: 10px;
			height: 60px;
			font-weight: 300;
			display: flex;
			font-size: 20px;
			align-items: center;
			cursor: pointer;
			color: #ffffff;

			span {
				margin-left: 10px;
			}
		}

		.header-title-hiddle {
			width: 64px;
			height: 60px;
			display: table-cell;
			vertical-align: middle;
			text-align: center;
			color: #ffffff;
			cursor: pointer;
		}

		.el-menu {
			border-right: none;
		}
	}

	// 菜单选中背景色
	.el-menu-item.is-active {
		background-color: #1890FF !important;
	}

	// 菜单悬浮背景色
	.el-menu-item:hover {
		background-color: #1890FF !important;
	}

	// 走马灯
	.el-carousel__item h3 {
		color: #ee7c12;
		font-size: 14px;
		opacity: 0.75;
		line-height: 25px;
		margin: 0;
	}

	.el-main {
		background-color: #eaedf1;
		padding: 0;
	}

	.fa {
		margin-right: 10px;
	}

	// 点击展开/折叠按钮
	.toggle-button {
		width: 50%;
		font-size: 20px;
		line-height: 40px;
		color: #595959;
		text-align: left;
		display: flex;
		align-items: center;
		float: left;
		padding-left: 20px;

		i {
			cursor: pointer;
		}
	}

	// 面包屑导航
	.el-breadcrumb {
		margin-bottom: 0;
	}

	// tab页
	.tabs-switch-page {
		display: flex;
		align-items: center;
		height: 40px;
		background-color: #fff;
		overflow: hidden;
	}

	.el-tag {
		cursor: pointer;
		margin-left: 10px;
		border-radius: 2px;
		font-size: 12px;
		color: #1890FF;
		border-color: #1890FF;
	}

	.el-tag--dark {
		color: #fff;
		background-color: #1890FF;
	}

	.el-dropdown-link {
		cursor: pointer;
	}

	.el-icon-arrow-down {
		font-size: 12px;
	}

	.submit-row {
		display: flex;
		flex-direction: row;
		justify-content: flex-end;
		align-items: center;
	}
</style>