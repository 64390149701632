<template>
	<div id="Jobposting">
		<el-card>
			<div class="demo-input-suffix header">
				<el-breadcrumb separator="/">
					<el-breadcrumb-item><i class="el-icon-date"></i>招聘管理</el-breadcrumb-item>
					<el-breadcrumb-item>岗位发布</el-breadcrumb-item>
				</el-breadcrumb>
				<div class="demo-input-suffix">
					<el-select v-model="WorkHourAudit" placeholder="请选择" style="margin-right: 10px;width:200px;"
						@change="StatusValueChange">
						<el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
						</el-option>
					</el-select>
					<el-input class="seachInpt" placeholder="请输入搜索关键字" v-model="jobpostName" clearable
						@change='NameChange'>
					</el-input>
					<el-button size="mini" type="primary" @click='searchange'>
						<i class="el-icon-search"></i>
						搜索
					</el-button>
					<el-button size="mini" type="danger" @click="Addjob()">
						<i class="el-icon-plus"></i>
						发布岗位
					</el-button>
				</div>
			</div>
			<div class="cantainer">
				<el-table stripe style="width: 100%;margin-bottom: 70px;" :data="jobpostlist" ref="multipleTable"
					tooltip-effect="dark" :border="true">
					<!-- <el-table-column type="selection" width="55">@selection-change="handleSelectionChange"
          </el-table-column> -->
					<el-table-column type="expand">
						<template slot-scope="props">
							<el-form label-position="left" inline class="demo-table-expand">
								<el-form-item label="岗位名称">
									<span>{{ props.row.JobName }}</span>
								</el-form-item>
								<el-form-item label="性别要求">
									<span>{{ props.row.JobSexNeedTxt }}</span>
								</el-form-item>
								<el-form-item label="岗位福利">
									<span v-for="(item,index) in props.row.JobWelfare"
										style="padding: 4px 10px;background-color: #F8f8f8;border-radius: 8px;margin-right: 10px;">{{item}}</span>
									<!-- <span>{{ props.row.JobWelfare }}</span> -->
								</el-form-item>
								<el-form-item label="工作区域">
									<span>{{props.row.CityTxt}}•{{props.row.AreaTxt}}</span>
								</el-form-item>
								<el-form-item label="工作地址">
									<span>{{ props.row.Address }}</span>
								</el-form-item>
								<el-form-item label="休息时间">
									<span v-if="props.row.RestTime==null">无休息</span>
									<span v-else>{{ props.row.RestTime }}/小时</span>
								</el-form-item>
								<el-form-item label="所在商圈" style="width:100%">
									<span style="margin-right: 10px;">{{props.row.ShangQuanTxt}}</span>
								</el-form-item>
								<el-form-item label="地铁路线" style="width:100%">
									<span style="margin-right: 10px;">{{props.row.SubListTxt}}</span><br>
									<span style="margin-right: 10px;">{{props.row.StationListTxt}}</span>
								</el-form-item>
								<el-form-item label="休息备注" style="width:100%">
									<span>{{ props.row.Remark }}</span>
								</el-form-item>
								<el-form-item label="岗位详情" style="width:100%">
									<span v-html="props.row.JobNeedDes">{{ props.row.JobNeedDes }}</span>
								</el-form-item>
							</el-form>
						</template>
					</el-table-column>
					<el-table-column type="index">
					</el-table-column>
					<el-table-column label="岗位名称" prop="JobName" width="180">
						<template slot-scope="scope">
							<span style="white-space: nowrap;">{{scope.row.JobName}}</span>
						</template>
					</el-table-column>
					<el-table-column label="所属部门" prop="DeptName" width="180">
						<template slot-scope="scope">
							<span style="white-space: nowrap;">{{scope.row.DeptName}}</span>
						</template>
					</el-table-column>
					<el-table-column label="岗位类型" prop="JobTypeName" width="100">
						<template slot-scope="scope">
							<span style="white-space: nowrap;">{{scope.row.JobTypeName}}</span>
						</template>
					</el-table-column>
					<el-table-column label="金额(元/小时)" prop="ContractSalary" width="120"></el-table-column>
					<el-table-column label="工作周期" width="290">
						<template slot-scope="scope">
							<el-tag size="medium">
								{{ scope.row.StartTime.replace(' ',':').replace(/\:/g,"-").split('-')[0]+"/"+scope.row.StartTime.replace(' ',':').replace(/\:/g,"-").split('-')[1]+"/"+scope.row.StartTime.replace(' ',':').replace(/\:/g,"-").split('-')[2]}}
								­­­­－
								{{scope.row.EndTime.replace(' ',':').replace(/\:/g,"-").split('-')[0]+"/"+scope.row.EndTime.replace(' ',':').replace(/\:/g,"-").split('-')[1]+"/"+scope.row.EndTime.replace(' ',':').replace(/\:/g,"-").split('-')[2]}}
								,
								{{ scope.row.StartTime.replace(' ',':').replace(/\:/g,"-").split('-')[3]+":"+scope.row.StartTime.replace(' ',':').replace(/\:/g,"-").split('-')[4]}}
								­－­
								{{scope.row.EndTime.replace(' ',':').replace(/\:/g,"-").split('-')[3]+":"+scope.row.EndTime.replace(' ',':').replace(/\:/g,"-").split('-')[4]}}
							</el-tag>
						</template>
					</el-table-column>
					<el-table-column label="发布日期" prop="LastUpdatedTime" width="180"></el-table-column>
					<el-table-column label="岗位状态">
						<template slot-scope="scope">
							<el-tag size="medium" v-if="scope.row.IsOverdueTxt!='已过期'">
								{{ scope.row.IsOverdueTxt}}
							</el-tag>
							<el-tag size="medium" type="danger" v-else>
								{{ scope.row.IsOverdueTxt}}
							</el-tag>
						</template>
					</el-table-column>
					<el-table-column label="发布状态" width="100" >
						<template slot-scope="scope">
							<el-tag size="medium" type="success" v-if="scope.row.IsEnabled==true">
								发布中
							</el-tag>
							<el-tag size="medium" type="danger" v-else>
								停止招聘
							</el-tag>
						</template>
					</el-table-column>
					<el-table-column label="审核状态" prop="AuditStatusTxt"></el-table-column>
					<el-table-column label="申请人数" prop="JobApplyCount"></el-table-column>
					<el-table-column align="right" width="260">
						<!-- <template slot="header" slot-scope="scope">
              <label>操作</label>
            </template> -->
						<template slot-scope="scope">
							<el-button type="warning" plain size="mini"
								v-show="scope.row.JobApplyCount==0&scope.row.IsOverdueTxt!='已过期'"
								@click='EditJob(scope.row.JobId)'>修改岗位
							</el-button>
							<el-button type="primary" plain size="mini" v-show="scope.row.IsOverdueTxt=='已过期'"
								@click='ActedEditJob(scope.row.JobId)'>重新发布
							</el-button>
							<el-button type="danger" plain size="mini" v-show="scope.row.IsEnabled==true"
								@click='IsEnabledJob(scope.row.JobId,scope.row.IsEnabled)'>停止招聘
							</el-button>
							<el-button type="success" plain size="mini" v-show="scope.row.IsEnabled==false"
								@click='IsEnabledJob(scope.row.JobId,scope.row.IsEnabled)'>发布
							</el-button>
						</template>
					</el-table-column>
				</el-table>
				<!-- 分页 -->
				<div style="width: 300px;margin: 0 auto;">
					<el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
						:current-page="currentPage" :page-sizes="[10, 20, 30, 40]" :page-size="pagesize"
						layout="total, sizes, prev, pager, next, jumper" :total="TotalRecordQty">
					</el-pagination>
				</div>
			</div>
		</el-card>
	</div>
</template>
<script>
	export default {
		name: 'Jobposting',
		//组件创建
		created() {
			// location.reload()
			this.Jobposting()
			// this.reload()
		},
		activated() {
			this.Jobposting()
		},
		mounted() {},
		data() {
			return {
				WorkHourAudit: 0, //岗位状态
				options: [{
					value: 0,
					label: '全部岗位'
				}, {
					value: 1,
					label: '已过期'
				}, {
					value: 2,
					label: '招聘中'
				}],
				jobpostName: '', //搜索关键字
				jobpostlist: [], // 企业列表
				TotalRecordQty: 10, //总条数
				currentPage: 1, //初始页
				pagesize: 10, //每页的数据
			}
		},
		methods: {
			//状态
			StatusValueChange(e) {
				console.log(e)
				this.WorkHourAudit = e
			},
			//获取角色
			NameChange(e) {
				// console.log(e)
				this.jobpostName = e
			},
			//搜索
			searchange() {
				// console.log(this.ComanyName)
				this.Jobposting()
			},
			//获取岗位应聘列表
			Jobposting() {
				let Token = localStorage.getItem('Token')
				let userInfo = JSON.parse(localStorage.getItem('DateInfo'));
				let that = this
				this.$axios.defaults.headers.common["x-Token"] = Token
				this.$axios.post('/JobManage/Job/List', {
					"Data": {
						"SortDirection": 1, //排序，1倒叙，0升序
						"QST": that.jobpostName, //模糊查询
						"CompanyID": userInfo.CompanyInfo.Id,
						"PageNO": this.currentPage,
						"PageSize": this.pagesize,
						"IsOverdue": this.WorkHourAudit
					}
				}).then(function(res) {
					console.log(res.data)
					if (res.data.Code == 0) {
						that.jobpostlist = res.data.Data.Items
						that.TotalRecordQty = res.data.Data.TotalRecordQty
					}
				})
			},
			// 条数
			indexMethod(index) {
				if (this.currentPage == 1) {
					return index * 1 + 1;
				} else {
					return (index + this.currentPage - 1) * 10 + 1
				}
			},
			// 初始页currentPage、初始每页数据数pagesize和数据data
			handleSizeChange: function(size) {
				this.pagesize = size;
				// console.log(this.pagesize) //每页下拉显示数据
				this.Jobposting()
			},
			handleCurrentChange: function(currentPage, index) {
				this.currentPage = currentPage;
				// console.log(this.currentPage, index) //点击第几页
				this.Jobposting()
			},
			//新增岗位
			Addjob() {
				//跳转到发布岗位页面
				setTimeout(() => {
					this.$router.push("/JobPostAdd");
				}, 1000)
				// 加入keepalive缓存
				this.$store.commit('addKeepAliveCache', 'JobPostAdd')
				var submenu = {
					Url: '/JobPostAdd',
					Code: 'JobPostAdd',
					Name: '新增岗位'
				}
				//修改选中菜单
				this.$store.commit('selectMenu', submenu)
			},
			// 选中复选框
			handleSelectionChange(val) {
				console.log(val)
			},
			EditJob(Id) {
				this.$router.push(`/JobPostEdit?JobId=${Id}`)
				// 加入keepalive缓存
				// this.$store.commit('addKeepAliveCache', 'JobPostEdit')
				// var submenu = {
				//   Url: '/JobPostEdit',
				//   Code: 'JobPostEdit',
				//   Name: '修改岗位'
				// }
				// //修改选中菜单
				// this.$store.commit('selectMenu', submenu)
			},
			ActedEditJob(Id) {
				this.$router.push(`/ActedEditJob?JobId=${Id}`)
			},
			//启用/停用
			IsEnabledJob(Id, IsEnabled) {
				console.log(IsEnabled, Id)
				let Token = localStorage.getItem('Token')
				let that = this
				this.$axios.defaults.headers.common["x-Token"] = Token
				if (IsEnabled==true) {
					var title = '是否停用该岗位?'
				} else{
					var title = '是否启用该岗位?'
				}
				
				this.$confirm(title, '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					let that = this
					let Token = localStorage.getItem('Token')
					this.$axios.defaults.headers.common["x-Token"] = Token
					this.$axios.post('/Job/StopOrOn', {
						"Data": {
							"Id": Id, //岗位ID
							"IsEnabled": !IsEnabled, // 启用/停用
						}
					}).then(function(res) {
						console.log(res.data)
						if (res.data.Code == 0) {
							that.Jobposting()
							that.$message({
								type: 'success',
								message: res.data.Message
							})
						} else {
							that.$message({
								type: 'error',
								message: res.Message
							})
						}
					})
				}).catch(() => {
					this.$message({
						type: 'info',
						message: '已取消'
					});
				})
			},
		}
	}
</script>
<style lang="less" scoped>
	/* 头部标题 */
	.header {
		align-items: center;
		justify-content: space-between;
	}

	// //表头样式
	// th{
	//   padding:1px 0;
	//   overflow: hidden;
	//   color: white !important;
	//   background-color:rgb(85, 170, 255) !important

	// }
	// th .cell{
	//   height:34px;
	//   line-height:34px;

	// }
	//  th .cell span{
	//   height:34px;
	// }
	.demo-input-suffix {
		display: flex;
	}

	.el-breadcrumb__item {
		display: flex;
	}

	/* 搜索框 */
	.seachInpt {
		margin-right: 10px;
	}

	.number .el-form-item__content {
		display: flex;
		align-items: center;
	}
</style>
